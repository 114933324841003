import { Code } from 'components'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Metric, Metrics, ReportTechStack } from 'types'
import { MetricId } from 'types'

import { ReportLabel } from '@/pages/report/components/ReportLabel'
import { formatTechStack } from '@/pages/report/utils/formatTechStack'

import { OBFUSCATED_CLASS_NAMES } from '../constants'

const getCodeSnippets = (metric: Metric<MetricId>, techStack: ReportTechStack[]) => {
  if (!metric.solution?.codeSnippets) {
    return []
  }

  const codeSnippets: Array<{
    codeSnippet: string
    techStack?: ReportTechStack
  }> = []

  if (metric.solution?.codeSnippets?.generic) {
    codeSnippets.push({
      codeSnippet: metric.solution?.codeSnippets?.generic,
    })
  }

  techStack.forEach(tech => {
    const solutionCode = metric.solution?.codeSnippets?.[tech as keyof typeof metric.solution.codeSnippets]

    if (solutionCode) {
      codeSnippets.push({ codeSnippet: solutionCode, techStack: tech })
    }
  })

  if (metric.solution?.codeSnippets?.shell) {
    codeSnippets.push({ codeSnippet: metric.solution.codeSnippets.shell })
  }

  return codeSnippets
}

type Props = {
  metricId: MetricId
  techStack: ReportTechStack[]
  isObfuscated: boolean
}

export const ReportMetricDetailsDialogCode = ({ metricId, techStack, isObfuscated }: Props) => {
  const metric = Metrics[metricId]
  const codeSnippets = getCodeSnippets(metric, techStack ?? [])
  const obfuscatedClassNames = isObfuscated ? OBFUSCATED_CLASS_NAMES : ''

  return (
    <div className="space-y-8">
      {codeSnippets.map((snippet, index) => {
        const formattedTechStack = snippet.techStack && formatTechStack(snippet.techStack)

        return (
          <div key={index} className="space-y-4">
            <Code
              key={index}
              code={snippet.codeSnippet}
              className={twMerge('mockup-code relative shrink-0', obfuscatedClassNames)}
            >
              {snippet.techStack && <ReportLabel>{formattedTechStack}</ReportLabel>}
            </Code>
          </div>
        )
      })}
    </div>
  )
}
