import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricId, Metrics } from 'types'

type Props = ComponentProps<'div'> & {
  metricId: MetricId
}

export const ReportMetricTitle = ({ className = '', metricId, ...props }: Props) => {
  return (
    <div className={twMerge('', className)} {...props}>
      {Metrics[metricId].title}
    </div>
  )
}
