import { Snackbar } from 'components'
import React from 'react'

import { AppProvider } from '@/components/appProvider/AppProvider'
import { Router } from '@/router/Router'

export const App = () => {
  return (
    <AppProvider>
      <Router />

      <Snackbar />
    </AppProvider>
  )
}
