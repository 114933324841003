import { z } from 'zod'

import { MetricId } from '../metrics'
import { BaseAuditSchema } from './schema'

const requestSchema = z.object({
  url: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  responseReceivedTime: z.number(),
  transferSize: z.number(),
})

const childSchema: z.ZodType<Child> = z.lazy(() =>
  z.object({
    request: requestSchema,
    children: z.record(z.lazy(() => childSchema)).optional(),
  }),
)

const chainsSchema = z.record(
  z.object({
    request: requestSchema,
    children: z.record(childSchema).optional(),
  }),
)

const longestChainSchema = z.object({
  duration: z.number(),
  length: z.number(),
  transferSize: z.number(),
})

export const CriticalRequestChainsAuditSchema = BaseAuditSchema.merge(
  z.object({
    id: z.literal(MetricId.CRITICAL_REQUEST_CHAINS),
    details: z.object({
      type: z.literal('criticalrequestchain'),
      chains: chainsSchema,
      longestChain: longestChainSchema,
    }),
  }),
)

type Request = z.infer<typeof requestSchema>
type Child = {
  request: Request
  children?: Record<string, Child>
}
