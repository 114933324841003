import { ParagraphText } from 'components'
import { config } from 'config'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { useReleaseNotesOpen } from '@/hooks/useReleaseNotesOpen'
import { useReport } from '@/reports/hooks/useReport'
import { routes } from '@/router/routes'

import { CustomAnchorText } from '../customAnchorText/CustomAnchorText'

const YEAR = new Date().getFullYear()

type Props = ComponentProps<'footer'>

export const Footer = ({ className = '', ...props }: Props) => {
  const { data } = useReport()

  const report = data?.data.report
  const isPaid = report ? Boolean(report.isPaid) : null
  const defaultAnalyticsData = {
    reportId: report?.id || null,
    metricCategoryId: null,
    metricId: null,
    isPaid,
  }

  const [_, setReleaseNotesOpen] = useReleaseNotesOpen()

  return (
    <footer className={twMerge('bg-base-200 px-8 py-12', className)} {...props}>
      <div className="mx-auto flex w-full max-w-4xl flex-wrap items-center justify-center gap-4 sm:gap-8">
        <ParagraphText className="whitespace-nowrap">
          © {YEAR} {config.name}
        </ParagraphText>

        <CustomAnchorText
          href={`mailto:${config.emails.support}`}
          analytics={{
            name: 'footer_support',
            ...defaultAnalyticsData,
          }}
        >
          Support
        </CustomAnchorText>

        <CustomAnchorText
          analytics={{
            name: 'footer_release_notes',
            ...defaultAnalyticsData,
          }}
          onClick={() => setReleaseNotesOpen(true)}
        >
          Release Notes
        </CustomAnchorText>

        <CustomAnchorText
          href={routes.terms}
          analytics={{
            name: 'footer_terms',
            ...defaultAnalyticsData,
          }}
        >
          Terms
        </CustomAnchorText>

        <CustomAnchorText
          href={routes.privacy}
          analytics={{
            name: 'footer_privacy',
            ...defaultAnalyticsData,
          }}
        >
          Privacy
        </CustomAnchorText>
      </div>
    </footer>
  )
}
