import { AnchorText, ParagraphText, TitleText } from 'components'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { twMerge } from 'tailwind-merge'

type Props = {
  children: string
}

export const Markdown = ({ children }: Props) => {
  return (
    <ReactMarkdown
      components={{
        h1: TitleText,
        h2: TitleText,
        h3: ({ className, children, ...props }) => (
          <ParagraphText className={twMerge('', className)} {...props}>
            <b>{children}</b>
          </ParagraphText>
        ),
        a: ({ className, children, ...props }) => (
          <AnchorText className={twMerge('', className)} variant="primary" {...props}>
            {children}
          </AnchorText>
        ),
        li: ({ className, children, ...props }) => (
          <li className={twMerge('flex gap-x-4', className)} {...props}>
            <div className="mt-2 h-1.5 w-1.5 shrink-0 rounded-full bg-base-content" />

            <ParagraphText>{children}</ParagraphText>
          </li>
        ),
        code: ({ className, children, ...props }) => (
          <span className={twMerge('rounded bg-base-200 px-1 font-mono', className)} {...props}>
            {children}
          </span>
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  )
}
