import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { getReportScoreClassNames } from 'utils'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'
import { getMetricScoreRating } from '../utils/getMetricScoreRating'

type Props = ComponentProps<'div'> & {
  score: number | null
  isObfuscated?: boolean
}

export const ReportMetricRating = ({ className = '', score, isObfuscated, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'font-medium',
        getReportScoreClassNames(score),
        isObfuscated && OBFUSCATED_CLASS_NAMES,
        className,
      )}
      {...props}
    >
      {getMetricScoreRating(score)}
    </div>
  )
}
