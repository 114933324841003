import { List, ListItem } from 'components'
import { config } from 'config'
import React from 'react'
import { formatDate } from 'utils'

import { ReportSupportLink } from '@/pages/report/components/ReportSupportLink'
import { formatTechStack } from '@/pages/report/utils/formatTechStack'
import { useReport } from '@/reports/hooks/useReport'

import { PageSection } from '../../../../../components/pageSection/PageSection'
import { ReportUrl } from '../../../components/ReportUrl'

const Skeleton = () => <span className="skeleton h-7 w-full" />

// FIXME: Optimise rendering
export const ReportOverviewOverview = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <PageSection variant="neutral" title="Overview">
      <List className="w-full">
        <ListItem>
          <b>📅 Date</b> {isLoading ? <Skeleton /> : <span>{formatDate(report?.createdAt, true)}</span>}
        </ListItem>

        <ListItem>
          <b>🌐 URL</b>{' '}
          {isLoading ? (
            <Skeleton />
          ) : report?.url ? (
            <ReportUrl
              url={report.url}
              analytics={{
                name: 'overview_overview_url',
                reportId: report?.id || null,
                metricCategoryId: null,
                metricId: null,
                isPaid,
              }}
            />
          ) : null}
        </ListItem>

        <ListItem>
          <b>🏷️ Title</b> {isLoading ? <Skeleton /> : <span>{report?.title}</span>}
        </ListItem>

        <ListItem>
          <b>📝 Description</b> {isLoading ? <Skeleton /> : <span>{report?.description}</span>}
        </ListItem>

        <ListItem>
          <b>📂 Category</b>{' '}
          {isLoading ? (
            <Skeleton />
          ) : (
            <span>
              {report?.subcategory} ({report?.category})
            </span>
          )}
        </ListItem>

        <ListItem>
          <b>💻 Tech Stack</b>{' '}
          {isLoading ? <Skeleton /> : <span>{report?.techStack.map(formatTechStack).join(', ')}</span>}
        </ListItem>
      </List>

      {isLoading ? (
        <div className="skeleton h-7 w-64" />
      ) : (
        <ReportSupportLink
          href={`mailto:${config.emails.support}?subject=Report%20issue%20for%20${report?.title}`}
          analytics={{
            name: 'overview_overview_support',
            reportId: report?.id || '',
            metricCategoryId: null,
            metricId: null,
            isPaid,
          }}
        >
          Incorrect details? Let us know!
        </ReportSupportLink>
      )}
    </PageSection>
  )
}
