import { validateDocumentId } from 'utils'

import { createCallableFunction } from '@/firebase/utils/createCallableFunction'
import { SUCCESS_QUERY_PARAM } from '@/router/routes'

const cashierFunction = createCallableFunction('cashier')

export const createCheckoutSession = async ({
  url,
  reportId,
  priceId,
}: {
  url: string
  reportId: string
  priceId: string
}) => {
  if (!reportId) {
    throw new Error('Report ID is required')
  }

  validateDocumentId(reportId)

  if (!priceId) {
    throw new Error('Price ID is required')
  }

  const successUrl = `${window.location.href}?${SUCCESS_QUERY_PARAM}=true`
  const cancelUrl = `${window.location.href}`

  const response = await cashierFunction({ url, reportId, priceId, successUrl, cancelUrl })
  const checkoutUrl = response.data.url

  if (!checkoutUrl) {
    throw new Error('Checkout session URL is required')
  }

  // Redirect to the checkout session URL
  window.location.href = checkoutUrl
}
