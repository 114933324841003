import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/solid'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { getReportVsCompetitorScoresClassNames } from 'utils'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'
import { getVsCompetitorsScoreDifference } from '../utils/getVsCompetitorsScoreDifference'

const formatVsCompetitorsScoreDifference = (vsCompetitorsScoreDifference: number) => {
  if (vsCompetitorsScoreDifference === 0) {
    return '~'
  }

  return `${Math.abs(vsCompetitorsScoreDifference)}%`
}

type Props = ComponentProps<'div'> & {
  score: number | null
  avgCompetitorsScore: number | null | undefined
  isObfuscated: boolean
}

export const ReportMetricVsCompetitors = ({
  className = '',
  score,
  avgCompetitorsScore,
  isObfuscated,
  ...props
}: Props) => {
  const vsCompetitorsScoreDifference = getVsCompetitorsScoreDifference({ score, avgCompetitorsScore })

  return (
    <div
      className={twMerge(
        'flex items-center gap-x-1 font-medium',
        getReportVsCompetitorScoresClassNames(vsCompetitorsScoreDifference),
        isObfuscated && OBFUSCATED_CLASS_NAMES,
        className,
      )}
      {...props}
    >
      {vsCompetitorsScoreDifference === null ? (
        'N/A'
      ) : (
        <>
          {vsCompetitorsScoreDifference === 0 ? null : vsCompetitorsScoreDifference < 0 ? (
            <ArrowDownCircleIcon className="h-5 w-5" />
          ) : (
            <ArrowUpCircleIcon className="h-5 w-5" />
          )}

          {formatVsCompetitorsScoreDifference(vsCompetitorsScoreDifference)}
        </>
      )}
    </div>
  )
}
