import { HeadingText, ParagraphText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
  image: string
  imageAlt: string
  title: string
  description?: string
}

export const BlankState = ({ className = '', image, imageAlt, title, description, ...props }: Props) => {
  return (
    <div className={twMerge('flex flex-col items-center gap-y-8', className)} {...props}>
      <img className="h-64 w-64 rounded-xl" src={image} alt={imageAlt} />

      <div className="flex flex-col gap-y-4">
        <HeadingText className="text-center">{title}</HeadingText>

        {description && <ParagraphText className="max-w-xl text-center">{description}</ParagraphText>}
      </div>
    </div>
  )
}
