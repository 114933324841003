import { ParagraphText, SmallText } from 'components'
import { config } from 'config'
import React from 'react'
import { MetricCategoryId, MetricId, Metrics, ReportMetric, SCORE_THRESHOLDS } from 'types'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'
import { Markdown } from '@/components/markdown/Markdown'
import { ReportHighlight } from '@/pages/report/components/ReportHighlight'
import { ReportJuiceMeter } from '@/pages/report/components/ReportJuiceMeter'
import { ReportMetricAvgCompetitorsScore } from '@/pages/report/components/ReportMetricAvgCompetitorsScore'
import { ReportMetricVsCompetitors } from '@/pages/report/components/ReportMetricVsCompetitors'
import { ReportSupportLink } from '@/pages/report/components/ReportSupportLink'
import { ReportUpgradeButton } from '@/pages/report/components/ReportUpgradeButton'
import { getMetricSavings } from '@/pages/report/utils/getMetricSavings'
import { getMetricValue } from '@/pages/report/utils/getMetricValue'

import { ReportMetricDetailsDialogLinks } from '../components/ReportMetricDetailsDialogLinks'
import { OBFUSCATED_CLASS_NAMES } from '../constants'

const getScoreMessage = (score: number | null | undefined): string => {
  if (score === null || score === undefined) {
    return 'Not applicable.'
  }

  if (score >= SCORE_THRESHOLDS.SWEET) {
    return 'You’re good to go!'
  }

  if (score >= SCORE_THRESHOLDS.MEH) {
    return 'Needs work.'
  }

  return 'Not quite there yet.'
}

type Props = {
  reportId: string
  metricCategoryId: MetricCategoryId | null
  metricId: MetricId
  reportMetric?: ReportMetric<MetricId>
  reportTitle: string
  avgCompetitorsScore: number | null | undefined
  isLoading: boolean
  isObfuscated: boolean
  isPaid: boolean
}

export const ReportMetricDetailsDialogSummaryTab = ({
  reportId,
  metricCategoryId,
  metricId,
  reportMetric,
  reportTitle,
  avgCompetitorsScore,
  isLoading,
  isObfuscated,
  isPaid,
}: Props) => {
  const metric = Metrics[metricId]
  const obfuscatedClassNames = isObfuscated ? OBFUSCATED_CLASS_NAMES : ''
  const reportMetricScore = reportMetric?.score
  const isNotApplicable = reportMetricScore === null && reportMetric?.scoreDisplayMode === 'notApplicable'

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className={obfuscatedClassNames}>
          <Markdown>{metric.description}</Markdown>
        </div>

        {metric.links && (
          <ReportMetricDetailsDialogLinks
            reportId={reportId}
            metricCategoryId={metricCategoryId}
            metricId={metricId}
            links={metric.links}
            isObfuscated={isObfuscated}
            isPaid={isPaid}
          />
        )}
      </div>

      <div className="flex flex-col items-center gap-y-4">
        <div className="flex w-full flex-col items-center gap-y-2">
          <ReportJuiceMeter
            score={reportMetricScore ?? 0}
            size="sm"
            animate={false}
            isLoading={isLoading}
            isObfuscated={isObfuscated}
          />

          <div className="relative">
            {isLoading ? (
              <div className="skeleton h-7 w-36" />
            ) : (
              <ParagraphText className={obfuscatedClassNames}>{getScoreMessage(reportMetricScore)}</ParagraphText>
            )}

            {isObfuscated && (
              <ReportUpgradeButton
                analytics={{
                  name: 'metric_details_summary_upgrade',
                  reportId,
                  metricCategoryId,
                  metricId,
                  isPaid,
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-y-4">
        <div className="flex w-full flex-wrap justify-center gap-4">
          {/* FIXME: SS I'm not such a fan of using the formatted value to decide if we should show it or not - it should be raw logic to avoid mixing concerns */}
          {reportMetric
            ? getMetricValue(reportMetric) !== '-' && (
                <ReportHighlight icon="⚖️" label="Value" isLoading={isLoading}>
                  {getMetricValue(reportMetric)}
                </ReportHighlight>
              )
            : null}

          {reportMetric
            ? getMetricSavings(reportMetric) !== '-' && (
                <ReportHighlight icon="💰" label="Savings" isLoading={isLoading}>
                  {getMetricSavings(reportMetric)}
                </ReportHighlight>
              )
            : null}

          <ReportHighlight
            icon="📊"
            label={isNotApplicable ? 'Competitor Avg.' : 'Vs Competitors'}
            isLoading={isLoading}
          >
            {isNotApplicable ? (
              <ReportMetricAvgCompetitorsScore avgCompetitorsScore={avgCompetitorsScore} isObfuscated={isObfuscated} />
            ) : (
              <ReportMetricVsCompetitors
                score={reportMetricScore ?? 0}
                avgCompetitorsScore={avgCompetitorsScore}
                isObfuscated={isObfuscated}
              />
            )}
          </ReportHighlight>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-y-4">
        <ReportSupportLink
          href={`mailto:${config.emails.support}?subject=Report%20issue%20for%20${reportTitle}%20and%20metric%20${metricId}`}
          analytics={{
            name: 'metric_details_summary_support',
            reportId,
            metricCategoryId,
            metricId,
            isPaid,
          }}
        >
          Spotted an issue? Report it here!
        </ReportSupportLink>

        <SmallText>
          Powered by{' '}
          <CustomAnchorText
            href="https://developers.google.com/web/tools/lighthouse"
            analytics={{
              name: `metric_details_summary_lighthouse`,
              reportId,
              metricCategoryId,
              metricId,
              isPaid,
            }}
          >
            Lighthouse
          </CustomAnchorText>
          ,{' '}
          <CustomAnchorText
            href="https://www.deque.com/axe/"
            analytics={{
              name: `metric_details_summary_axe`,
              reportId,
              metricCategoryId,
              metricId,
              isPaid,
            }}
          >
            AXE
          </CustomAnchorText>{' '}
          and our own secret <s>sauce</s> juice 🍊
        </SmallText>
      </div>
    </div>
  )
}
