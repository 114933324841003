import React from 'react'

import { useReport } from '@/reports/hooks/useReport'

import { ReportTabLayout } from '../../components/ReportTabLayout'
import { ReportOverviewCompetitorComparison } from './sections/ReportOverviewCompetitorComparison'
import { ReportOverviewCta } from './sections/ReportOverviewCta'
import { ReportOverviewKeyHighlights } from './sections/ReportOverviewKeyHighlights'
import { ReportOverviewOpportunities } from './sections/ReportOverviewOpportunities'
import { ReportOverviewOverview } from './sections/ReportOverviewOverview'
import { ReportOverviewSummary } from './sections/ReportOverviewSummary'
import { ReportOverviewTestimonials } from './sections/ReportOverviewTestimonials'

export const ReportOverviewTab = () => {
  const { data } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportTabLayout>
      <ReportOverviewSummary />

      <ReportOverviewOverview />

      <ReportOverviewKeyHighlights />

      <ReportOverviewCompetitorComparison />

      <ReportOverviewOpportunities />

      {!isPaid && (
        <>
          <ReportOverviewTestimonials />

          <ReportOverviewCta />
        </>
      )}
    </ReportTabLayout>
  )
}
