import { useIsRestoring, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { QUERY_KEYS } from '@/utils/queryKeys'

import { getReport } from '../api/getReport'

export const useReport = () => {
  const { reportId = '' } = useParams()
  const isRestoring = useIsRestoring()

  const query = useQuery({
    queryKey: [QUERY_KEYS.Report(reportId)],
    queryFn: () => getReport({ reportId }),
    enabled: Boolean(reportId),
  })

  return {
    ...query,
    isLoading:
      // Otherwise isLoading is true even when the query is not enabled
      query.isFetching ||
      // We want to show the loading state when restoring from localStorage
      isRestoring,
  }
}
