import { z } from 'zod'

export const ProductDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  stripeProductId: z.string(),
  name: z.string(),
  description: z.string(),
  active: z.boolean(),
  image: z.string(),
  metadata: z.record(z.string(), z.string()).optional(),
})

export type ProductDocument = z.infer<typeof ProductDocumentSchema>
