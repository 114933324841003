import React from 'react'

import { useReport } from '@/reports/hooks/useReport'

import { ReportTabLayout } from '../../components/ReportTabLayout'
import { ReportConfig } from './sections/ReportConfig'
import { ReportDiagnosticsCta } from './sections/ReportDiagnosticsCta'
import { ReportDiagnosticsKeyHighlights } from './sections/ReportDiagnosticsKeyHighlights'
import { ReportDiagnosticsTestimonials } from './sections/ReportDiagnosticsTestimonials'

export const ReportDiagnosticsTab = () => {
  const { data } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportTabLayout>
      <ReportDiagnosticsKeyHighlights />

      <ReportConfig />

      {!isPaid && (
        <>
          <ReportDiagnosticsTestimonials />

          <ReportDiagnosticsCta />
        </>
      )}
    </ReportTabLayout>
  )
}
