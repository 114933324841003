export const config = {
  name: 'Juicy Stats',
  shortName: 'Juicy Stats',
  description:
    "Juicy Stats delivers in-depth, actionable insights to optimize your landing page's performance, SEO, UX, and conversions. Get personalized fixes and competitor comparisons to take your page to the next level.",
  shortDescription: 'The juiciest landing page insights.',
  urls: {
    app: {
      development: 'http://localhost:5173',
      staging: 'https://app.staging.juicystats.com',
      production: 'https://app.juicystats.com',
    },
  },
  emails: {
    support: 'support@juicystats.com',
    fromName: 'Shaun',
    transactional: {
      development: 'hello@mail.development.juicystats.com',
      staging: 'hello@mail.staging.juicystats.com',
      production: 'hello@mail.juicystats.com',
    },
    unsubscribe: {
      development: 'https://skink-sound-precisely.ngrok-free.app/juicy-stats-staging/us-central1/unsubscribe',
      staging: 'https://app.staging.juicystats.com/unsubscribe',
      production: 'https://app.juicystats.com/unsubscribe',
    },
  },
  links: {
    twitter: '',
    github: '',
    linkedIn: '',
  },
  features: {
    customEmails: false,
    requireVerifiedEmails: false,
    subscriptions: false,
  },
}
