import { useSearchParams } from 'react-router-dom'
import { MetricIdSchema } from 'types'

import { METRIC_ID_QUERY_PARAM } from '@/router/routes'

export const useReportMetricIdSearchParam = () => {
  const [searchParams] = useSearchParams()
  const metricId = searchParams.get(METRIC_ID_QUERY_PARAM)

  if (!metricId) {
    return null
  }

  return MetricIdSchema.parse(metricId)
}
