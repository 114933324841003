import { ParagraphText } from 'components'
import { config } from 'config'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { PageSection } from '../../../components/pageSection/PageSection'
import { ReportCard } from './ReportCard'
import { ReportTestimonialStars } from './ReportTestimonialStars'

type Props = ComponentProps<typeof PageSection> & {
  testimonials: Array<{
    name: string
    image: string
    quote: string
  }>
  customerCount: number
  isLoading: boolean
}

export const ReportTestimonialsAvatars = ({
  className = '',
  testimonials,
  customerCount,
  isLoading,
  ...props
}: Props) => {
  if (!isLoading && testimonials.length === 0) {
    return null
  }

  return (
    <>
      <PageSection className={twMerge('', className)} {...props}>
        <ReportCard className="w-full gap-y-8">
          <div className="flex w-full max-w-lg flex-col items-center gap-y-4">
            <div className="rlt:space-x-reverse avatar-group -space-x-6">
              {isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} className="skeleton avatar h-16 w-16 rounded-full" />
                  ))
                : testimonials.map((testimonial, index) => (
                    <img key={index} className="avatar h-16 w-16" src={testimonial.image} alt={testimonial.name} />
                  ))}
            </div>

            <ReportTestimonialStars isLoading={isLoading} />

            {isLoading ? (
              <div className="skeleton h-6 w-full" />
            ) : (
              <ParagraphText className="text-center text-lg">
                {customerCount >= 100
                  ? `Loved by ${customerCount}+ customers maximizing their landing pages with ${config.name} 🍊`
                  : `Loved by customers maximizing their landing pages with ${config.name} 🍊`}
              </ParagraphText>
            )}
          </div>
        </ReportCard>
      </PageSection>
    </>
  )
}
