import { Headerbar } from 'components'
import React from 'react'

import { useReport } from '@/reports/hooks/useReport'

import { ReportUrl } from './ReportUrl'

export const ReportHeader = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <Headerbar>
      <div className="flex flex-wrap justify-end gap-x-4 gap-y-2">
        <b className="hidden sm:inline">🌐 URL:</b>{' '}
        {isLoading ? (
          <div className="skeleton h-7 w-48" />
        ) : report ? (
          <ReportUrl
            url={report.url}
            analytics={{
              name: 'header_report_url',
              reportId: report.id,
              metricCategoryId: null,
              metricId: null,
              isPaid,
            }}
          />
        ) : null}
      </div>
    </Headerbar>
  )
}
