import { ComponentPropsWithoutRef, Ref } from 'react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { Footer } from '../footer/Footer'

type Props = ComponentPropsWithoutRef<'div'> & {
  forwardRef?: Ref<HTMLDivElement>
}

export const PageLayout = ({ forwardRef, className = '', children, ...props }: Props) => {
  return (
    <div ref={forwardRef} className={twMerge('flex h-full flex-col overflow-y-auto bg-base-100', className)} {...props}>
      <div className="flex flex-1 flex-col gap-y-16 py-16 sm:gap-y-24">{children}</div>

      <Footer />
    </div>
  )
}
