import { z } from 'zod'

import { MetricCategoryId } from '../metricCategories'
import { MetricIdSchema } from '../metrics'

export enum Category {
  ECommerce = 'E-commerce',
  SaaS = 'SaaS',
  Marketing = 'Marketing',
  Design = 'Design',
  Education = 'Education',
  Healthcare = 'Healthcare',
  Finance = 'Finance',
  Entertainment = 'Entertainment',
  TravelAndHospitality = 'Travel & Hospitality',
  Technology = 'Technology',
}

export enum Subcategory {
  Fashion = 'Fashion',
  Electronics = 'Electronics',
  HealthAndBeauty = 'Health & Beauty',
  HomeAndGarden = 'Home & Garden',
  SportsAndOutdoors = 'Sports & Outdoors',
  ProjectManagement = 'Project Management',
  CRM = 'CRM',
  MarketingAutomation = 'Marketing Automation',
  AITools = 'AI Tools',
  CollaborationTools = 'Collaboration Tools',
  DigitalMarketing = 'Digital Marketing',
  ContentMarketing = 'Content Marketing',
  SEO = 'SEO',
  SocialMediaMarketing = 'Social Media Marketing',
  AffiliateMarketing = 'Affiliate Marketing',
  WebDesign = 'Web Design',
  GraphicDesign = 'Graphic Design',
  UIUXDesign = 'UI UX Design',
  Branding = 'Branding',
  OnlineCourses = 'Online Courses',
  ELearningPlatforms = 'E-Learning Platforms',
  TutoringServices = 'Tutoring Services',
  CertificationPrograms = 'Certification Programs',
  Telemedicine = 'Telemedicine',
  HealthTech = 'Health Tech',
  FitnessAndWellness = 'Fitness & Wellness',
  MedicalServices = 'Medical Services',
  PersonalFinance = 'Personal Finance',
  Fintech = 'Fintech',
  InvestmentPlatforms = 'Investment Platforms',
  PaymentSolutions = 'Payment Solutions',
  StreamingServices = 'Streaming Services',
  Gaming = 'Gaming',
  Music = 'Music',
  MoviesAndTVShows = 'Movies & TV Shows',
  HotelsAndAccommodation = 'Hotels & Accommodation',
  FlightBooking = 'Flight Booking',
  TravelAgencies = 'Travel Agencies',
  AdventureAndTours = 'Adventure & Tours',
  AIMachineLearning = 'AI & Machine Learning',
  CloudComputing = 'Cloud Computing',
  Cybersecurity = 'Cybersecurity',
  DevOpsTools = 'DevOps Tools',
  DeveloperTools = 'Developer Tools',
  GiftsAndSpecialityItems = 'Gifts & Speciality Items',
}

export const categoryToSubcategories: Record<Category, Subcategory[]> = {
  [Category.ECommerce]: [
    Subcategory.Fashion,
    Subcategory.Electronics,
    Subcategory.HealthAndBeauty,
    Subcategory.HomeAndGarden,
    Subcategory.SportsAndOutdoors,
    Subcategory.GiftsAndSpecialityItems,
  ],
  [Category.SaaS]: [
    Subcategory.ProjectManagement,
    Subcategory.CRM,
    Subcategory.MarketingAutomation,
    Subcategory.AITools,
    Subcategory.CollaborationTools,
  ],
  [Category.Marketing]: [
    Subcategory.DigitalMarketing,
    Subcategory.ContentMarketing,
    Subcategory.SEO,
    Subcategory.SocialMediaMarketing,
    Subcategory.AffiliateMarketing,
  ],
  [Category.Design]: [Subcategory.WebDesign, Subcategory.GraphicDesign, Subcategory.UIUXDesign, Subcategory.Branding],
  [Category.Education]: [
    Subcategory.OnlineCourses,
    Subcategory.ELearningPlatforms,
    Subcategory.TutoringServices,
    Subcategory.CertificationPrograms,
  ],
  [Category.Healthcare]: [
    Subcategory.Telemedicine,
    Subcategory.HealthTech,
    Subcategory.FitnessAndWellness,
    Subcategory.MedicalServices,
  ],
  [Category.Finance]: [
    Subcategory.PersonalFinance,
    Subcategory.Fintech,
    Subcategory.InvestmentPlatforms,
    Subcategory.PaymentSolutions,
  ],
  [Category.Entertainment]: [
    Subcategory.StreamingServices,
    Subcategory.Gaming,
    Subcategory.Music,
    Subcategory.MoviesAndTVShows,
  ],
  [Category.TravelAndHospitality]: [
    Subcategory.HotelsAndAccommodation,
    Subcategory.FlightBooking,
    Subcategory.TravelAgencies,
    Subcategory.AdventureAndTours,
  ],
  [Category.Technology]: [
    Subcategory.AIMachineLearning,
    Subcategory.CloudComputing,
    Subcategory.Cybersecurity,
    Subcategory.DevOpsTools,
    Subcategory.DeveloperTools,
  ],
}

export const CategoryIdSchema = z.nativeEnum(Category)
export const SubcategoryIdSchema = z.nativeEnum(Subcategory)

const AverageScoreSchema = z.object({
  average: z.number(),
  count: z.number(),
})

export const CategoryDocumentSchema = z.object({
  id: z.union([CategoryIdSchema, SubcategoryIdSchema]),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  title: z.string(),
  parentId: CategoryIdSchema.nullable(),
  metrics: z.record(MetricIdSchema, AverageScoreSchema),
  // It's important that metricCategories is an object with all the metric categories defined as opposed to partial metrics record above
  metricCategories: z.object({
    [MetricCategoryId.PERFORMANCE]: AverageScoreSchema,
    [MetricCategoryId.ACCESSIBILITY]: AverageScoreSchema,
    [MetricCategoryId.SEO]: AverageScoreSchema,
    [MetricCategoryId.BEST_PRACTICES]: AverageScoreSchema,
    [MetricCategoryId.CONVERSION]: AverageScoreSchema,
  }),
  overallScore: AverageScoreSchema,
})

export type CategoryDocument = z.infer<typeof CategoryDocumentSchema>
