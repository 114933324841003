import React, { ComponentProps } from 'react'

import { useCreateCheckoutSession } from '@/billing/hooks/useCreateCheckoutSession'
import { CustomButton } from '@/components/customButton/CustomButton'

type Props = ComponentProps<'div'> & Pick<ComponentProps<typeof CustomButton>, 'analytics'>

export const ReportUpgradeButton = ({ analytics, ...props }: Props) => {
  const { mutate: createCheckoutSession, isLoading } = useCreateCheckoutSession()

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black/5 dark:bg-white/5" {...props}>
      <CustomButton
        variant="primary"
        size="sm"
        analytics={analytics}
        disabled={isLoading}
        loading={isLoading}
        onClick={() => createCheckoutSession()}
      >
        🔓 Upgrade to unlock
      </CustomButton>
    </div>
  )
}
