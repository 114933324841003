import { SCORE_THRESHOLDS } from 'types'

export const getMetricScoreRating = (score: number | null) => {
  if (score === null) {
    return 'N/A'
  }

  if (score >= SCORE_THRESHOLDS.SWEET) {
    return 'Sweet'
  }

  if (score >= SCORE_THRESHOLDS.MEH) {
    return 'Meh'
  }

  return 'Sour'
}
