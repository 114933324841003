import React, { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import { MetricCategoryId } from 'types'

import { ReportCategoryNavbar } from '../../components/ReportCategoryNavbar'
import { ReportTabLayout } from '../../components/ReportTabLayout'

type Props = {
  metricCategoryId: MetricCategoryId
  children?: ReactNode
}

export const ReportCategoryTab = ({ metricCategoryId }: Props) => {
  return (
    <>
      <div className="flex w-full shrink-0 justify-center overflow-x-auto p-4 pb-0">
        <ReportCategoryNavbar metricCategoryId={metricCategoryId} />
      </div>

      <ReportTabLayout>
        <Outlet />
      </ReportTabLayout>
    </>
  )
}
