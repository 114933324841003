import React, { useMemo } from 'react'
import { MetricCategoryId, Metrics, SCORE_THRESHOLDS } from 'types'

import { ReportKeyHighlights } from '@/pages/report/components/ReportKeyHighlights'
import { useReport } from '@/reports/hooks/useReport'

import { useReportCategoryMetrics } from '../hooks/useReportCategoryMetrics'

type Props = {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryKeyHighlights = ({ metricCategoryId }: Props) => {
  const { data, isLoading: isReportLoading } = useReport()

  const report = data?.data.report

  const { metrics: reportCategoryMetrics, isLoading: isReportCategoryFailedMetricsLoading } =
    useReportCategoryMetrics(metricCategoryId)

  // NOTE: useReportCategoryMetrics already takes care of hidden metrics
  const reportCategoryFailedMetrics = useMemo(
    () => reportCategoryMetrics.filter(metric => metric.score !== null && metric.score < SCORE_THRESHOLDS.SWEET),
    [reportCategoryMetrics],
  )

  const lowHangingFruits = useMemo(
    () =>
      reportCategoryFailedMetrics.filter(reportMetric => Metrics[reportMetric.id].solution?.fixability === 'easy')
        .length,
    [reportCategoryFailedMetrics],
  )

  const issuesFound = reportCategoryFailedMetrics.length
  const vsCompetitorScoreDifference =
    (report?.metricCategoryScores[metricCategoryId] || 0) -
    (report?.competitors.avgMetricCategoryScores[metricCategoryId] || 0)

  return (
    <ReportKeyHighlights
      issuesFound={issuesFound}
      vsCompetitorScoreDifference={vsCompetitorScoreDifference}
      lowHangingFruits={lowHangingFruits}
      isLoading={isReportLoading || isReportCategoryFailedMetricsLoading}
    />
  )
}
