import { AnchorText } from 'components'
import React, { ComponentProps } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'

type Props = ComponentProps<typeof AnchorText> & {
  analytics: {
    name: string
    reportId: string | null
    metricCategoryId: string | null
    metricId: string | null
    isPaid: boolean | null
    label?: string
  }
}

// A wrapper around AnchorText that let's us track clicks
export const CustomAnchorText = ({ analytics, onClick, ...props }: Props) => {
  return (
    <AnchorText
      {...props}
      onClick={event => {
        trackAnalyticsEvent('element_clicked', {
          type: 'link',
          name: analytics.name,
          report_id: analytics.reportId,
          metric_category_id: analytics.metricCategoryId,
          metric_id: analytics.metricId,
          label: analytics.label,
          is_paid: analytics.isPaid,
        })
        onClick?.(event)
      }}
    />
  )
}
