import { z } from 'zod'

export const JuicerQueueDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  status: z.enum(['pending', 'completed', 'failed']),
  urlId: z.string(),
})

export type JuicerQueueDocument = z.infer<typeof JuicerQueueDocumentSchema>
