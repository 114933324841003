import { Button, Dialog, DialogActions, DialogHeader } from 'components'
import { config } from 'config'
import React, { ComponentProps, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'

import { useReport } from '@/reports/hooks/useReport'

type Props = ComponentProps<typeof Dialog>

export const ReportPaymentSuccessDialog = ({ className = '', onClose, ...props }: Props) => {
  const { isLoading } = useReport()

  const onViewFullReportClick = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <Dialog className={twMerge('', className)} onClose={onClose} {...props}>
      <DialogHeader
        onClose={onClose}
        title="Your Payment was Successful 🎉"
        description={
          isLoading
            ? 'Loading your full report...'
            : `Your full ${config.name} report is now unlocked and ready to view! ✅`
        }
      />

      <DialogActions>
        <Button disabled={isLoading} onClick={onViewFullReportClick}>
          View Full Report
        </Button>
      </DialogActions>
    </Dialog>
  )
}
