import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricId, Metrics } from 'types'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'

type Props = ComponentProps<'div'> & {
  metricId: MetricId
  isObfuscated?: boolean
}

export const ReportMetricSolutionTitle = ({ className = '', metricId, isObfuscated, ...props }: Props) => {
  return (
    <div className={twMerge('', isObfuscated && OBFUSCATED_CLASS_NAMES, className)} {...props}>
      {Metrics[metricId].solution?.title || ''}
    </div>
  )
}
