import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'button'> & {
  variant?: 'primary' | 'secondary' | 'ghost' | 'neutral'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  loading?: boolean
}

export const Button = ({ className = '', variant = 'primary', size = 'md', loading, children, ...props }: Props) => {
  return (
    <button
      className={twMerge(
        'btn flex h-auto animate-none select-auto flex-nowrap items-center gap-x-2 border-none uppercase leading-tight',
        variant === 'primary' &&
          'bg-gradient-to-r from-primary to-secondary text-base-100 transition-colors hover:to-primary disabled:text-neutral-content disabled:opacity-50 dark:text-neutral-content',
        variant === 'secondary' && 'btn-secondary',
        variant === 'ghost' && 'btn-ghost',
        variant === 'neutral' && 'btn-neutral transition-opacity hover:opacity-80',
        size === 'xs' && 'btn-xs',
        size === 'sm' && 'btn-sm',
        size === 'md' && 'btn-md',
        size === 'lg' && 'btn-lg py-2',
        size === 'xl' && 'btn-xl',
        className,
      )}
      {...props}
    >
      {loading ? <span className="loading loading-spinner" /> : children}
    </button>
  )
}
