import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { MetricCategories, MetricCategoryId, MetricId, SCORE_THRESHOLDS } from 'types'

import { ReportSummary } from '@/pages/report/components/ReportSummary'
import { useReport } from '@/reports/hooks/useReport'

import { ReportCategoryScoreBreakdownDialog } from '../dialogs/reportCategoryScoreBreakdownDialog/ReportCategoryScoreBreakdownDialog'

const getScoreMessage = (metricCategoryId: MetricCategoryId, score: number | null): string => {
  if (score === null) return ''

  if (metricCategoryId === MetricCategoryId.PERFORMANCE) {
    if (score >= SCORE_THRESHOLDS.SWEET) {
      return 'Speed demon! 🚀'
    }

    if (score >= SCORE_THRESHOLDS.MEH) {
      return 'Could be better 🧐'
    }

    return 'Running on fumes! 🛑'
  }

  if (metricCategoryId === MetricCategoryId.CONVERSION) {
    if (score >= SCORE_THRESHOLDS.SWEET) {
      return 'Conversion machine! 💰'
    }

    if (score >= SCORE_THRESHOLDS.MEH) {
      return 'Needs more urgency ⏳'
    }

    return 'Losing potential customers left and right! 🏃‍♂️'
  }

  if (metricCategoryId === MetricCategoryId.SEO) {
    if (score >= SCORE_THRESHOLDS.SWEET) {
      return "You're topping the charts! 📈"
    }

    if (score >= SCORE_THRESHOLDS.MEH) {
      return 'Needs more juice to rank 🍊'
    }

    return 'Invisible to search engines! 🕵️‍♂️'
  }

  if (metricCategoryId === MetricCategoryId.ACCESSIBILITY) {
    if (score >= SCORE_THRESHOLDS.SWEET) {
      return 'Open and accessible to all! 💡'
    }

    if (score >= SCORE_THRESHOLDS.MEH) {
      return 'Not too bad, but room for improvement 👓'
    }

    return 'Hard to navigate for everyone! ❗️'
  }

  if (metricCategoryId === MetricCategoryId.BEST_PRACTICES) {
    if (score >= SCORE_THRESHOLDS.SWEET) {
      return "You're nailing it! 🔨"
    }

    if (score >= SCORE_THRESHOLDS.MEH) {
      return 'Not quite up to standard 🛠️'
    }

    return 'Riddled with mistakes! 🚧'
  }

  return 'Unknown category! 🤷‍♂️'
}

type Props = {
  metricCategoryId: MetricCategoryId
  children?: ReactNode
}

export const ReportCategorySummary = ({ metricCategoryId, children }: Props) => {
  const { data, isLoading } = useReport()

  const [showCategoryScoreBreakdownDialog, setShowCategoryScoreBreakdownDialog] = useState(false)

  const report = data?.data.report
  const overallScore = report?.metricCategoryScores[metricCategoryId] || null
  const isPaid = Boolean(report?.isPaid)

  const metricCount = useMemo(() => {
    if (!report) {
      return 0
    }

    // Collect all the metrics for the category
    const metricCategoryIds = MetricCategories[metricCategoryId].metrics
      .filter(metric => metric.weight) // Only include metrics that have a weight
      .map(metric => metric.id)

    return Object.keys(report.metrics).filter(metricId => metricCategoryIds.includes(metricId as MetricId)).length
  }, [metricCategoryId, report])

  const onOpenCategoryScoreBreakdownDialog = useCallback(() => {
    setShowCategoryScoreBreakdownDialog(true)
  }, [])

  const onCloseCategoryScoreBreakdownDialog = useCallback(() => {
    setShowCategoryScoreBreakdownDialog(false)
  }, [])

  return (
    <>
      <ReportSummary
        reportId={report?.id || ''}
        metricCategoryId={metricCategoryId}
        score={overallScore}
        heading={getScoreMessage(metricCategoryId, overallScore)}
        description={`Category score based on ${metricCount} metrics.`}
        animateOnceId={metricCategoryId}
        isLoading={isLoading}
        isPaid={isPaid}
        onScoreBreakdownClick={onOpenCategoryScoreBreakdownDialog}
      >
        {children}
      </ReportSummary>

      {showCategoryScoreBreakdownDialog && (
        <ReportCategoryScoreBreakdownDialog
          open
          metricCategoryId={metricCategoryId}
          onClose={onCloseCategoryScoreBreakdownDialog}
        />
      )}
    </>
  )
}
