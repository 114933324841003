import React, { ComponentProps } from 'react'
import { MetricCategoryId } from 'types'

import { ReportTestimonialSingle } from '@/pages/report/components/ReportTestimonialSingle'
import { useReport } from '@/reports/hooks/useReport'
import { ReportTabId } from '@/router/routes'

import { TESTIMONIAL_INDICES } from '../constants'
import { ReportTestimonialsAvatars } from './ReportTestimonialsAvatars'

type Props = Omit<ComponentProps<typeof ReportTestimonialSingle>, 'testimonial' | 'isLoading' | 'analytics'> & {
  metricCategoryId: MetricCategoryId
}

export const ReportCategorySummaryTestimonials = ({ metricCategoryId, ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const testimonials = data?.data.testimonials
  const isPerformanceCategory = metricCategoryId === MetricCategoryId.PERFORMANCE

  if (isPerformanceCategory) {
    const performanceSummaryTestimonials: Array<{
      name: string
      url: string
      quote: string
      image: string
    }> = []

    TESTIMONIAL_INDICES[ReportTabId.PERFORMANCE].summary.forEach(index => {
      if (testimonials?.items[index]) {
        performanceSummaryTestimonials.push(testimonials.items[index])
      }
    })

    return (
      <ReportTestimonialsAvatars
        testimonials={performanceSummaryTestimonials}
        customerCount={testimonials?.items.length || 0}
        isLoading={isLoading}
      />
    )
  }

  // Grab the next testimonial for the category using the index of the metricCategoryId
  const index = TESTIMONIAL_INDICES[metricCategoryId].summary[0]
  const testimonial = testimonials?.items[index] || null
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportTestimonialSingle
      testimonial={testimonial}
      analytics={{
        name: 'category_summary_testimonials_url',
        reportId: report?.id || null,
        metricCategoryId,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
      {...props}
    />
  )
}
