import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'tr'> & {
  hoverable?: boolean
  selected?: boolean
}

export const TableRow = ({ className = '', hoverable = false, selected = false, ...props }: Props) => {
  return (
    <tr
      className={twMerge(
        hoverable ? 'hover cursor-pointer focus-visible:outline-base-content' : '',
        selected && 'bg-base-200',
        className,
      )}
      {...props}
    />
  )
}
