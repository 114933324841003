import React, { ComponentProps, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategories, MetricCategoryId, MetricId } from 'types'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'

const determineImpact = (relativeWeight: number | null): 'high' | 'medium' | 'low' | null => {
  if (relativeWeight === null) return null

  if (relativeWeight >= 10) return 'high'

  if (relativeWeight >= 5) return 'medium'

  return 'low'
}

const formatImpact = (impact: 'high' | 'medium' | 'low' | null) => {
  switch (impact) {
    case 'high':
      return 'High'
    case 'medium':
      return 'Medium'
    case 'low':
      return 'Low'
    default:
      return 'Unknown'
  }
}

export const getImpactClassNames = (impact: 'high' | 'medium' | 'low' | null) => {
  if (impact === null) {
    return ''
  }

  if (impact === 'high') {
    return 'text-success'
  }

  if (impact === 'medium') {
    return 'text-warning'
  }

  return 'text-error'
}

type Props = ComponentProps<'div'> & {
  metricId: MetricId
  isObfuscated?: boolean
}

// FIXME: SS It would be great if impact could come from the data provider, e.g. useReportTopOpportunityMetrics instead of calculating it here
export const ReportMetricImpact = ({ className = '', metricId, isObfuscated, ...props }: Props) => {
  const impact = useMemo(() => {
    let metricCategoryId: MetricCategoryId | undefined

    // Find the metric category id
    Object.values(MetricCategories).forEach(category => {
      const metric = category.metrics.find(metric => metric.id === metricId)

      if (metric) {
        metricCategoryId = category.id
      }
    })

    const categoryMetrics = metricCategoryId ? MetricCategories[metricCategoryId].metrics : []
    const totalCategoryWeight = categoryMetrics
      .filter(metric => metric.weight > 0) // Only consider metrics with a weight
      .reduce((acc, categoryMetric) => acc + categoryMetric.weight, 0)
    const categoryMetric = categoryMetrics.find(metric => metric.id === metricId)
    const relativeWeightOfMetric = categoryMetric ? (categoryMetric.weight / totalCategoryWeight) * 100 : null

    return determineImpact(relativeWeightOfMetric)
  }, [metricId])

  return (
    <div
      className={twMerge('font-medium', isObfuscated && OBFUSCATED_CLASS_NAMES, getImpactClassNames(impact), className)}
      {...props}
    >
      {formatImpact(impact)}
    </div>
  )
}
