import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { Logo } from '../logo/Logo'

type Props = ComponentPropsWithoutRef<'header'>

export const Headerbar = ({ className = '', children, ...props }: Props) => {
  return (
    <header className={twMerge('navbar border-b border-base-300 bg-base-100 px-4 lg:px-8', className)} {...props}>
      <div className="flex w-full justify-between gap-x-4">
        <Logo />

        {children}
      </div>
    </header>
  )
}
