import { Dialog, DialogContent, DialogHeader } from 'components'
import React, { ComponentProps } from 'react'

import changelog from '../../../../../CHANGELOG.md?raw'
import { Markdown } from '../markdown/Markdown'

type Props = ComponentProps<typeof Dialog>

export const ReleaseNotesDialog = ({ onClose, ...props }: Props) => {
  return (
    <Dialog onClose={onClose} {...props}>
      <DialogHeader title="Release Notes" onClose={onClose} />

      <DialogContent className="pb-8">
        <Markdown>{changelog}</Markdown>
      </DialogContent>
    </Dialog>
  )
}
