import { z } from 'zod'

export const PickerQueueDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  status: z.enum(['pending', 'completed', 'failed']),
  reportId: z.string(),
})

export type PickerQueueDocument = z.infer<typeof PickerQueueDocumentSchema>
