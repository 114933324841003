import { StarIcon } from '@heroicons/react/24/solid'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
  isLoading?: boolean
}

export const ReportTestimonialStars = ({ className = '', isLoading, ...props }: Props) => {
  return (
    <div className={twMerge('flex w-full justify-center', className)} {...props}>
      {isLoading ? (
        <div className="skeleton h-6 w-32" />
      ) : (
        Array.from({ length: 5 }, (_, index) => (
          <StarIcon
            key={index}
            className="h-6 w-6 fill-yellow-400 stroke-yellow-500 dark:fill-yellow-500 dark:stroke-yellow-600"
          />
        ))
      )}
    </div>
  )
}
