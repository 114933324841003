import { z } from 'zod'

export const CustomerDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  stripeCustomerId: z.string(),
  name: z.string(),
  phone: z.string(),
  email: z.string(),
  billingAddress: z.object({
    city: z.string(),
    line1: z.string(),
    line2: z.string().optional(),
    state: z.string(),
    country: z.string(),
    postalCode: z.string(),
  }),
})

export type CustomerDocument = z.infer<typeof CustomerDocumentSchema>
