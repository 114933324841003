import { EyeSlashIcon } from '@heroicons/react/24/solid'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  ParagraphText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from 'components'
import { config } from 'config'
import React, { ComponentProps, useCallback, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategories, MetricCategoryId, MetricId, Metrics } from 'types'
import { formatPercentage } from 'utils'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'
import { useReport } from '@/reports/hooks/useReport'

import { ReportSupportLink } from '../../components/ReportSupportLink'
import { ReportTableCellSkeleton } from '../../components/ReportTableCellSkeleton'
import { ReportUpgradeButton } from '../../components/ReportUpgradeButton'
import { useReportNavigateToMetricDetails } from '../../hooks/useReportNavigateToMetricDetails'
import { OBFUSCATED_CLASS_NAMES } from '../reportMetricDetailsDialog/constants'

type Props = ComponentProps<typeof Dialog> & {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryScoreBreakdownDialog = ({ className = '', metricCategoryId, onClose, ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const obfuscatedMetricIds = data?.data.obfuscatedMetricIds
  const isPaid = Boolean(report?.isPaid)
  const defaultAnalyticsData = {
    reportId: report?.id || '',
    metricCategoryId,
    metricId: null,
    isPaid,
  }

  const categoryTitle = MetricCategories[metricCategoryId].title
  const overallScore = report?.overallScore || 0
  const formattedOverallScore = formatPercentage(overallScore)

  // Sort the category metrics
  // Place those with weight at the top
  // Hidden at bottom
  // Sort the rest alphabetically
  const categoryMetrics = useMemo(() => {
    return [...MetricCategories[metricCategoryId].metrics].sort((a, b) => {
      const metricA = Metrics[a.id]
      const metricB = Metrics[b.id]

      // Place hidden metrics at the bottom
      if (metricA.hidden !== metricB.hidden) {
        return metricA.hidden ? 1 : -1
      }

      // Place metrics with weight at the top
      if (a.weight > 0 && b.weight === 0) {
        return -1
      }

      if (a.weight === 0 && b.weight > 0) {
        return 1
      }

      // Sort by name if weights are equal
      if (metricA.title < metricB.title) {
        return -1
      }

      if (metricA.title > metricB.title) {
        return 1
      }

      return 0
    })
  }, [metricCategoryId])

  const totalWeight = useMemo(
    () => categoryMetrics.reduce((acc, categoryMetric) => acc + categoryMetric.weight, 0),
    [categoryMetrics],
  )

  const navigateToMetricDetails = useReportNavigateToMetricDetails()

  const onMetricTitleClick = useCallback(
    (metricId: MetricId) => navigateToMetricDetails(metricId),
    [navigateToMetricDetails],
  )

  return (
    <Dialog className={twMerge('', className)} onClose={onClose} {...props}>
      <DialogHeader onClose={onClose} title={`${categoryTitle} Score Breakdown`} />

      <DialogContent>
        <ParagraphText>
          The {categoryTitle} score is a weighted average of each metric score in this category. Here&apos;s how the
          score is calculated:
        </ParagraphText>

        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Metric</TableHeaderCell>

              <TableHeaderCell>Score</TableHeaderCell>

              <TableHeaderCell>Weight</TableHeaderCell>

              <TableHeaderCell>Weighted Score</TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {categoryMetrics.map(categoryMetric => {
              const metricId = categoryMetric.id
              const metric = Metrics[metricId]
              const reportMetric = report?.metrics[metricId]
              const score = reportMetric?.score
              const formattedScore = score === null || score === undefined ? 'N/A' : formatPercentage(score)
              const weightedScore =
                score === null || score === undefined
                  ? 'N/A'
                  : formatPercentage((score * categoryMetric.weight) / totalWeight)

              const hasNoWeight = categoryMetric.weight === 0
              const isObfuscated = Boolean(obfuscatedMetricIds?.includes(metricId))
              const obfuscatedClassNames = isObfuscated ? OBFUSCATED_CLASS_NAMES : ''

              return (
                <TableRow key={metricId} className={twMerge('relative', hasNoWeight && !isObfuscated && 'opacity-40')}>
                  <TableCell className={isObfuscated ? 'opacity-40' : ''}>
                    <CustomAnchorText
                      analytics={{
                        name: 'category_score_breakdown_metric_title',
                        reportId: report?.id || '',
                        metricCategoryId: metricCategoryId,
                        metricId,
                        isPaid,
                      }}
                      onClick={() => onMetricTitleClick(metricId)}
                    >
                      {metric.hidden && <EyeSlashIcon className="h-4 w-4" />}
                      {metric.title}
                    </CustomAnchorText>
                  </TableCell>

                  <TableCell className={obfuscatedClassNames}>
                    {isLoading ? <ReportTableCellSkeleton /> : formattedScore}
                  </TableCell>

                  <TableCell className={obfuscatedClassNames}>{categoryMetric.weight}</TableCell>

                  <TableCell className={obfuscatedClassNames}>
                    {isLoading ? <ReportTableCellSkeleton /> : weightedScore}
                  </TableCell>

                  {isObfuscated && (
                    <TableCell colSpan={4}>
                      <ReportUpgradeButton
                        analytics={{
                          name: 'category_score_breakdown_upgrade',
                          ...defaultAnalyticsData,
                          metricId,
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell>
                <b>{categoryTitle} Score</b>
              </TableCell>

              <TableCell />

              <TableCell />

              <TableCell>{isLoading ? <ReportTableCellSkeleton /> : <b>{formattedOverallScore}</b>}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <ReportSupportLink
          className="flex justify-center"
          href={`mailto:${config.emails.support}?subject=Report%20question%20for%20${report?.title}`}
          analytics={{
            name: 'category_score_breakdown_support',
            ...defaultAnalyticsData,
          }}
        >
          Still have questions? Feel free to reach out!
        </ReportSupportLink>
      </DialogContent>
    </Dialog>
  )
}
