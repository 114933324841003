import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from 'components'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { MetricId } from 'types'

import JuicyCoolDudeImage from '@/assets/juicy-cool-dude.webp'
import { BlankState } from '@/components/blankState/BlankState'
import { Link } from '@/components/link/Link'
import { ReportMetricImpact } from '@/pages/report/components/ReportMetricImpact'
import { ReportMetricRating } from '@/pages/report/components/ReportMetricRating'
import { ReportMetricSolutionFixability } from '@/pages/report/components/ReportMetricSolutionFixability'
import { ReportMetricSolutionTitle } from '@/pages/report/components/ReportMetricSolutionTitle'
import { ReportMetricTitle } from '@/pages/report/components/ReportMetricTitle'
import { ReportTableCellSkeleton } from '@/pages/report/components/ReportTableCellSkeleton'
import { useReportNavigateToMetricDetails } from '@/pages/report/hooks/useReportNavigateToMetricDetails'
import { useReportTopOpportunityMetrics } from '@/pages/report/hooks/useReportTopOpportunityMetrics'
import { REPORT_ID_PARAM, routes } from '@/router/routes'

import { PageSection } from '../../../../../components/pageSection/PageSection'

export const ReportOverviewOpportunities = () => {
  const { reportId = '' } = useParams()

  const { data: metrics, isLoading } = useReportTopOpportunityMetrics()

  const navigateToMetricDetails = useReportNavigateToMetricDetails()

  const onTableRowClick = useCallback(
    (metricId: MetricId) => {
      navigateToMetricDetails(metricId)
    },
    [navigateToMetricDetails],
  )

  const onTableRowKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, metricId: MetricId) => {
      if (event.key === 'Enter') {
        onTableRowClick(metricId)
      }
    },
    [onTableRowClick],
  )

  return (
    <>
      <PageSection>
        <BlankState
          image={JuicyCoolDudeImage}
          imageAlt="Juicy Orange Slice"
          title="Let's Get Juicy!"
          description="Low hanging fruits coming in hot!"
        />

        <div className="mt-12" style={{ transform: 'rotate(145deg)' }}>
          <svg className="h-24 w-24 stroke-primary" viewBox="0 0 77 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.33755 84.3973C0.297616 62.7119 2.93494 39.8181 19.4192 23.8736C28.2211 15.3599 42.4944 12.5723 47.6281 26.2359C51.1245 35.5419 51.542 51.9945 41.0605 57.0865C29.486 62.7095 40.2945 35.2221 41.9942 32.4952C49.9497 19.7313 59.7772 11.6122 72.2699 3.78281C76.9496 0.849879 73.7108 0.477284 70.0947 1.13476C66.9572 1.7052 63.4035 2.43717 60.5291 3.81975C59.6524 4.24143 65.7349 2.73236 66.6827 2.44768C70.7471 1.22705 75.4874 -0.0219285 75.9527 5.60812C76.0274 6.5127 75.9956 14.9844 74.7481 15.2963C74.099 15.4586 71.0438 10.27 70.4642 9.65288C66.6996 5.64506 63.5835 4.42393 58.2726 5.11792"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </PageSection>

      <PageSection title={`Top${metrics.length ? ` ${metrics.length}` : ''} Opportunities`}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Metric</TableHeaderCell>

              <TableHeaderCell>Rating</TableHeaderCell>

              <TableHeaderCell>Solution</TableHeaderCell>

              <TableHeaderCell>Impact</TableHeaderCell>

              <TableHeaderCell>Fixability</TableHeaderCell>

              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from({ length: 6 }).map((__, index) => (
                      <TableCell key={index}>
                        <ReportTableCellSkeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : metrics.map((reportMetric, index) => {
                  return (
                    <TableRow
                      key={index}
                      // Make it focusable
                      tabIndex={0}
                      hoverable
                      onClick={() => onTableRowClick(reportMetric.id)}
                      onKeyDown={event => onTableRowKeyDown(event, reportMetric.id)}
                    >
                      <TableCell>
                        <ReportMetricTitle metricId={reportMetric.id} />
                      </TableCell>

                      <TableCell>
                        <ReportMetricRating score={reportMetric.score} />
                      </TableCell>

                      <TableCell>
                        <ReportMetricSolutionTitle metricId={reportMetric.id} />
                      </TableCell>

                      <TableCell>
                        <ReportMetricImpact metricId={reportMetric.id} />
                      </TableCell>

                      <TableCell>
                        <ReportMetricSolutionFixability metricId={reportMetric.id} />
                      </TableCell>

                      <TableCell>
                        <ArrowRightIcon className="h-4 w-4" />
                      </TableCell>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>

        <Link variant="primary" to={routes.reportPerformanceMetrics.replace(REPORT_ID_PARAM, reportId)}>
          See More Opportunities <ArrowRightIcon className="h-4 w-4" />
        </Link>
      </PageSection>
    </>
  )
}
