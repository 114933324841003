import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useCallback } from 'react'
import { MetricCategoryId } from 'types'

export type ReportCategoryMetricsSortBy =
  | 'metricTitle'
  | 'metricScoreRating'
  | 'metricScore'
  | 'metricImpact'
  | 'solutionFixability'
  | 'metricVsCompetitorsScore'
  | 'metricObfuscated' // used internally to sort obfuscated metrics last

export type ReportCategoryMetricsSortDirection = 'asc' | 'desc'

const useReportCategoryMetricsSortingAtom = atomWithStorage<
  Record<
    MetricCategoryId,
    {
      sortBy: ReportCategoryMetricsSortBy
      sortDirection: ReportCategoryMetricsSortDirection
    }
  >
>('useReportCategoryMetricsSorting', {
  [MetricCategoryId.PERFORMANCE]: {
    sortBy: 'metricScore',
    sortDirection: 'asc',
  },
  [MetricCategoryId.CONVERSION]: {
    sortBy: 'metricScore',
    sortDirection: 'asc',
  },
  [MetricCategoryId.SEO]: {
    sortBy: 'metricScore',
    sortDirection: 'asc',
  },
  [MetricCategoryId.ACCESSIBILITY]: {
    sortBy: 'metricScore',
    sortDirection: 'asc',
  },
  [MetricCategoryId.BEST_PRACTICES]: {
    sortBy: 'metricScore',
    sortDirection: 'asc',
  },
})

export const useReportCategoryMetricsSorting = (metricCategoryId: MetricCategoryId) => {
  const [sorting, setSorting] = useAtom(useReportCategoryMetricsSortingAtom)

  const setCategorySorting = useCallback(
    (sortBy: ReportCategoryMetricsSortBy, sortDirection: ReportCategoryMetricsSortDirection) => {
      setSorting({ ...sorting, [metricCategoryId]: { sortBy, sortDirection } })
    },
    [metricCategoryId, sorting, setSorting],
  )

  const categorySorting = sorting[metricCategoryId]

  return [categorySorting, setCategorySorting] as const
}
