import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ParagraphText } from '../paragraphText/ParagraphText'

type ListItemProps = ComponentPropsWithoutRef<'li'>

export const ListItem = ({ className = '', children }: ListItemProps) => {
  return (
    <li className={twMerge('py-2', className)}>
      <ParagraphText className="grid grid-cols-2 gap-x-4 sm:grid-cols-[1fr_2fr]">{children}</ParagraphText>
    </li>
  )
}

type Props = ComponentPropsWithoutRef<'ul'>

export const List = ({ className = '', children }: Props) => {
  return <ul className={twMerge('divide-y divide-dashed divide-base-300', className)}>{children}</ul>
}
