import { Alert, Collapsible, HeadingText } from 'components'
import { config } from 'config'
import React, { useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategoryId, Metrics, ReportMetric, ReportTechStack } from 'types'
import { MetricId } from 'types'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'
import { Markdown } from '@/components/markdown/Markdown'
import { ReportSupportLink } from '@/pages/report/components/ReportSupportLink'
import { ReportUpgradeButton } from '@/pages/report/components/ReportUpgradeButton'

import { ReportMetricDetailsDialogCode } from '../components/ReportMetricDetailsDialogCode'
import { ReportMetricDetailsDialogLinks } from '../components/ReportMetricDetailsDialogLinks'
import { ReportMetricDetailsDialogResources } from '../components/ReportMetricDetailsDialogResources'
import { OBFUSCATED_CLASS_NAMES } from '../constants'

type Props = {
  reportId: string
  metricCategoryId: MetricCategoryId | null // Can be null in the Overview tab
  metricId: MetricId
  reportMetric?: ReportMetric<MetricId>
  reportTechStack: ReportTechStack[]
  reportScreenshotUrl?: string
  reportTitle: string
  isLoading: boolean
  isObfuscated: boolean
  isPaid: boolean
}

export const ReportMetricDetailsDialogSolutionTab = ({
  reportId,
  metricCategoryId,
  metricId,
  reportMetric,
  reportTechStack,
  reportScreenshotUrl,
  reportTitle,
  isLoading,
  isObfuscated,
  isPaid,
}: Props) => {
  const metric = Metrics[metricId]
  const isPassing = reportMetric?.score === 1
  const isNotApplicable = reportMetric?.score === null && reportMetric?.scoreDisplayMode === 'notApplicable'
  const obfuscatedClassNames = isObfuscated ? OBFUSCATED_CLASS_NAMES : ''
  const hasResources = Boolean(
    reportMetric &&
      metric.tableConfig &&
      'details' in reportMetric &&
      reportMetric.details &&
      'items' in reportMetric.details &&
      reportMetric.details.items.length,
  )
  const defaultAnalyticsData = {
    reportId,
    metricCategoryId,
    metricId,
    isPaid,
  }

  const onToggleCollapsible = useCallback(
    (label: string) => {
      trackAnalyticsEvent('element_clicked', {
        type: 'collapsible',
        name: 'metric_details_solution_collapsible',
        report_id: reportId,
        metric_category_id: metricCategoryId,
        metric_id: metricId,
        label,
        is_paid: isPaid,
      })
    },
    [isPaid, metricCategoryId, metricId, reportId],
  )

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <HeadingText>Solution</HeadingText>

        {isLoading ? (
          <div className="skeleton h-10 w-full" />
        ) : (
          !isObfuscated && (
            <Alert variant={isPassing || isNotApplicable ? 'success' : 'warning'}>
              {isPassing || isNotApplicable
                ? 'No immediate action needed, but we left these here for future reference.'
                : 'Needs work.'}
            </Alert>
          )
        )}

        <div className="relative">
          <div className={obfuscatedClassNames}>
            <Markdown>{metric.solution?.description || ''}</Markdown>
          </div>

          {isObfuscated && (
            <ReportUpgradeButton analytics={{ name: 'metric_details_solution_upgrade', ...defaultAnalyticsData }} />
          )}
        </div>

        {metric.solution?.links && (
          <ReportMetricDetailsDialogLinks
            reportId={reportId}
            metricCategoryId={metricCategoryId}
            metricId={metricId}
            links={metric.solution.links}
            isObfuscated={isObfuscated}
            isPaid={isPaid}
          />
        )}
      </div>

      <div className="space-y-4">
        {hasResources && (
          <Collapsible
            title="Affected Resources"
            disabled={isObfuscated}
            onClick={() => onToggleCollapsible('Affected Resources')}
          >
            <ReportMetricDetailsDialogResources
              reportId={reportId}
              metricCategoryId={metricCategoryId}
              metricId={metricId}
              reportMetric={reportMetric}
              reportScreenshotUrl={reportScreenshotUrl}
              isLoading={isLoading}
              isPaid={isPaid}
            />
          </Collapsible>
        )}

        {metric.solution?.additionalTips?.length && (
          <Collapsible
            title="Additional Tips"
            disabled={isObfuscated}
            onClick={() => onToggleCollapsible('Additional Tips')}
          >
            <ul className="list-disc space-y-2">
              {metric.solution.additionalTips.map(tip => (
                <li key={tip} className={twMerge('ml-3', obfuscatedClassNames)}>
                  <Markdown>{tip}</Markdown>
                </li>
              ))}
            </ul>
          </Collapsible>
        )}

        {metric.solution?.codeSnippets && (
          <Collapsible
            title="Code Snippets"
            disabled={isObfuscated}
            onClick={() => onToggleCollapsible('Code Snippets')}
          >
            <ReportMetricDetailsDialogCode
              metricId={metricId}
              techStack={reportTechStack}
              isObfuscated={isObfuscated}
            />
          </Collapsible>
        )}
      </div>

      <div className="flex justify-center">
        <ReportSupportLink
          href={`mailto:${config.emails.support}?subject=Report%20issue%20for%20${reportTitle}%20and%20metric%20${metricId}`}
          analytics={{
            name: 'metric_details_solution_support',
            ...defaultAnalyticsData,
          }}
        >
          Know any other tricks? Let us know!
        </ReportSupportLink>
      </div>
    </div>
  )
}
