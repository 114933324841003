import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { Dialog, DialogContent, DialogHeader, Navbar, NavbarItem } from 'components'
import React, { ComponentProps, useCallback, useEffect, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { MetricCategories, MetricCategoryId, MetricId, Metrics } from 'types'

import { CustomButton } from '@/components/customButton/CustomButton'
import { useReport } from '@/reports/hooks/useReport'
import { METRIC_DETAILS_TAB_ID_QUERY_PARAM, METRIC_ID_QUERY_PARAM, ReportCategoryTabId } from '@/router/routes'

import { useReportCategoryMetrics } from '../../hooks/useReportCategoryMetrics'
import { useReportNavigateToMetricDetails } from '../../hooks/useReportNavigateToMetricDetails'
import { ReportMetricDetailsDialogSolutionTab } from './tabs/ReportMetricDetailsDialogSolutionTab'
import { ReportMetricDetailsDialogSummaryTab } from './tabs/ReportMetricDetailsDialogSummaryTab'

const useNextPreviousMetrics = (metricId: MetricId) => {
  // Get the metricCategoryId from the CategoryMetrics
  const metricCategoryId = useMemo(
    () => Object.values(MetricCategories).find(c => c.metrics.some(m => m.id === metricId))?.id,
    [metricId],
  ) as MetricCategoryId

  if (!metricCategoryId) {
    throw new Error(`CategoryId for metric ${metricId} not found`)
  }

  const { metrics } = useReportCategoryMetrics(metricCategoryId)

  const previousMetricId = useMemo(
    () => metrics[metrics.findIndex(m => m.id === metricId) - 1]?.id,
    [metrics, metricId],
  )
  const nextMetricId = useMemo(() => metrics[metrics.findIndex(m => m.id === metricId) + 1]?.id, [metrics, metricId])

  return { previousMetricId, nextMetricId }
}

type TabId = 'summary' | 'solution'

const TABS: Array<{
  id: TabId
  label: string
}> = [
  {
    id: 'summary',
    label: 'Summary',
  },
  {
    id: 'solution',
    label: 'Solution',
  },
]

type Props = ComponentProps<typeof Dialog> & {
  metricId: MetricId
}

// FIXME: SS We can improve performance on non-category pages by extracting the next/previous buttons to a separate component and move the hook there
export const ReportMetricDetailsDialog = ({ className = '', metricId, ...props }: Props) => {
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data, isLoading } = useReport()

  const report = data?.data.report
  const obfuscatedMetricIds = data?.data.obfuscatedMetricIds
  const isPaid = Boolean(report?.isPaid)
  const { previousMetricId, nextMetricId } = useNextPreviousMetrics(metricId)

  const activeTab = searchParams.get(METRIC_DETAILS_TAB_ID_QUERY_PARAM) as TabId

  const metric = Metrics[metricId]

  const reportMetrics = report?.metrics
  const reportMetric = reportMetrics?.[metricId as keyof typeof reportMetrics]

  // If it's the category metrics page, only then do we want  we don't want to show the next/previous buttons
  const isCategoryMetricsPage = pathname.includes(ReportCategoryTabId.METRICS)

  // Analytics metricCategoryId
  const metricCategoryId = useMemo(
    () =>
      (isCategoryMetricsPage
        ? Object.keys(MetricCategories).find(key =>
            MetricCategories[key as MetricCategoryId].metrics.some(m => m.id === metricId),
          ) || null
        : null) as MetricCategoryId | null,
    [isCategoryMetricsPage, metricId],
  )

  const isObfuscated = useMemo(() => Boolean(obfuscatedMetricIds?.includes(metricId)), [metricId, obfuscatedMetricIds])

  const defaultAnalyticsData = {
    reportId: report?.id || '',
    metricCategoryId,
    metricId,
    isPaid,
  }

  const navigateToMetricDetails = useReportNavigateToMetricDetails()

  // If the active tab is not set, set it to 'summary'
  useEffect(() => {
    if (!activeTab) {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams.set(METRIC_DETAILS_TAB_ID_QUERY_PARAM, 'summary')

      setSearchParams(newSearchParams)
    }
  }, [activeTab, searchParams, setSearchParams])

  // On close of dialog, remove search params from url
  const onClose = useCallback(() => {
    searchParams.delete(METRIC_ID_QUERY_PARAM)
    searchParams.delete(METRIC_DETAILS_TAB_ID_QUERY_PARAM)

    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  // If the metric doesn't exist, close the dialog
  useEffect(() => {
    if (!metric) {
      onClose()
    }
  }, [metric, onClose])

  const onTabClick = useCallback(
    (tabId: TabId) => {
      const newSearchParams = new URLSearchParams(searchParams)

      newSearchParams.set(METRIC_DETAILS_TAB_ID_QUERY_PARAM, tabId)

      setSearchParams(newSearchParams)
    },
    [setSearchParams, searchParams],
  )

  const onPrevious = useCallback(() => {
    navigateToMetricDetails(previousMetricId)
  }, [navigateToMetricDetails, previousMetricId])

  const onNext = useCallback(() => {
    navigateToMetricDetails(nextMetricId)
  }, [navigateToMetricDetails, nextMetricId])

  return (
    <Dialog className={twMerge('', className)} onClose={onClose} {...props}>
      <DialogHeader onClose={onClose} title={metric?.title || ''} />

      <div
        className={twMerge(
          'mb-8 flex items-center px-4 sm:px-8',
          isCategoryMetricsPage ? 'justify-between' : 'justify-center',
        )}
      >
        {isCategoryMetricsPage && (
          <div className="flex-1">
            <CustomButton
              className="-ml-3 select-none sm:ml-0"
              variant="ghost"
              size="sm"
              disabled={!previousMetricId}
              analytics={{
                name: 'metric_details_dialog_previous',
                ...defaultAnalyticsData,
              }}
              onClick={onPrevious}
            >
              <ArrowLeftIcon className="h-4 w-4" />
              <span className="hidden sm:inline">Previous</span>
            </CustomButton>
          </div>
        )}

        <Navbar className={isCategoryMetricsPage ? 'flex-2' : ''} variant="boxed">
          {TABS.map(tab => (
            <NavbarItem key={tab.id} active={activeTab === tab.id} onClick={() => onTabClick(tab.id)}>
              {tab.label}
            </NavbarItem>
          ))}
        </Navbar>

        {isCategoryMetricsPage && (
          <div className="flex flex-1 justify-end">
            <CustomButton
              className="-mr-3 select-none sm:mr-0"
              variant="ghost"
              size="sm"
              disabled={!nextMetricId}
              analytics={{
                name: 'metric_details_dialog_next',
                ...defaultAnalyticsData,
              }}
              onClick={onNext}
            >
              <ArrowRightIcon className="h-4 w-4" />
              <span className="hidden sm:inline">Next</span>
            </CustomButton>
          </div>
        )}
      </div>

      <DialogContent>
        {activeTab === 'summary' ? (
          <ReportMetricDetailsDialogSummaryTab
            reportId={report?.id || ''} // It should be defined by this stage
            metricCategoryId={metricCategoryId}
            metricId={metricId}
            reportMetric={reportMetric}
            reportTitle={report?.title || ''}
            avgCompetitorsScore={report?.competitors.avgMetricScores[metricId]}
            isLoading={isLoading}
            isObfuscated={isObfuscated}
            isPaid={isPaid}
          />
        ) : (
          <ReportMetricDetailsDialogSolutionTab
            reportId={report?.id || ''} // It should be defined by this stage
            metricCategoryId={metricCategoryId}
            metricId={metricId}
            reportMetric={reportMetric}
            reportTechStack={report?.techStack || []}
            reportTitle={report?.title || ''}
            isLoading={isLoading}
            isObfuscated={isObfuscated}
            isPaid={isPaid}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
