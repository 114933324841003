import React from 'react'

import { ReportTestimonialsTriple } from '@/pages/report/components/ReportTestimonialsTriple'
import { useReport } from '@/reports/hooks/useReport'

export const ReportOverviewTestimonials = () => {
  const { data, isLoading } = useReport()

  const testimonials = data?.data?.testimonials
  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportTestimonialsTriple
      testimonials={testimonials?.items.slice(0, 3) || []}
      analytics={{
        name: 'overview_testimonials_url',
        reportId: report?.id || '',
        metricCategoryId: null,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
    />
  )
}
