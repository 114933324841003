import React, { ComponentProps } from 'react'
import { MetricCategoryId } from 'types'

import { ReportTestimonialSingle } from '@/pages/report/components/ReportTestimonialSingle'
import { useReport } from '@/reports/hooks/useReport'

import { TESTIMONIAL_INDICES } from '../constants'

type Props = Omit<ComponentProps<typeof ReportTestimonialSingle>, 'testimonial' | 'analytics' | 'isLoading'> & {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryMetricsTestimonials = ({ metricCategoryId, ...props }: Props) => {
  const { data, isLoading } = useReport()

  const testimonials = data?.data?.testimonials
  const report = data?.data.report
  const isPaid = report ? Boolean(report.isPaid) : null
  const index = TESTIMONIAL_INDICES[metricCategoryId].metrics[0]
  const testimonial = testimonials?.items[index] || null

  return (
    <ReportTestimonialSingle
      {...props}
      testimonial={testimonial}
      analytics={{
        name: 'category_metrics_testimonials_url',
        reportId: report?.id || null,
        metricCategoryId,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
    />
  )
}
