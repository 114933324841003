import React, { ComponentProps } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<typeof RouterLink> & {
  variant?: 'primary' | 'primary-button' | 'neutral'
  disabled?: boolean
}

export const Link = ({ className = '', variant = 'neutral', disabled, ...props }: Props) => {
  return (
    <RouterLink
      className={twMerge(
        'link flex items-center gap-x-2 transition-colors hover:text-primary',
        variant === 'primary'
          ? 'text-primary transition-opacity hover:opacity-80'
          : variant === 'primary-button'
          ? 'bg-gradient-to-r from-primary to-secondary text-base-100 transition-opacity hover:opacity-80 dark:text-base-content'
          : 'transition-colors hover:text-primary',
        disabled && 'pointer-events-none opacity-50',
        className,
      )}
      {...props}
    />
  )
}
