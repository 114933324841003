import { z } from 'zod'

export enum PriceType {
  OneTime = 'oneTime',
  Recurring = 'recurring',
}

export enum PriceInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const PriceDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  stripePriceId: z.string(),
  productId: z.string(),
  active: z.boolean(),
  currency: z.string(),
  type: z.nativeEnum(PriceType),
  unitAmount: z.number(),
  interval: z.nativeEnum(PriceInterval).nullable(), // Only applicable to subscriptions
  intervalCount: z.number().nullable(), // Only applicable to subscriptions
  trialPeriodDays: z.number().nullable(), // Only applicable to subscriptions
  metadata: z.record(z.string(), z.string()),
})

export type PriceDocument = z.infer<typeof PriceDocumentSchema>
