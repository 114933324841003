import React, { ComponentProps } from 'react'
import { MetricCategoryId } from 'types'

import { ReportCta } from '@/pages/report/components/ReportCta'
import { useReport } from '@/reports/hooks/useReport'

import { CTAS } from '../constants'

type Props = Omit<ComponentProps<typeof ReportCta>, 'title' | 'description' | 'buttonText' | 'analytics'> & {
  metricCategoryId: MetricCategoryId
}

export const ReportCategorySummaryCta = ({ metricCategoryId, ...props }: Props) => {
  const { data } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportCta
      variant="primary"
      title={CTAS[metricCategoryId].summary.title}
      description={CTAS[metricCategoryId].summary.description}
      buttonText={CTAS[metricCategoryId].summary.button(29)}
      analytics={{
        name: 'report_category_summary_cta',
        reportId: report?.id || null,
        metricCategoryId,
        metricId: null,
        isPaid,
      }}
      {...props}
    />
  )
}
