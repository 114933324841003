import { List, ListItem } from 'components'
import React, { ComponentProps } from 'react'

import { useReport } from '@/reports/hooks/useReport'

import { PageSection } from '../../../../../components/pageSection/PageSection'

const Skeleton = () => <span className="skeleton h-7 w-full" />

type Props = ComponentProps<typeof PageSection>

// FIXME: SS Optimise rendering
export const ReportConfig = ({ ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report

  return (
    <PageSection variant="neutral" title="Analysis Config" {...props}>
      <List className="w-full">
        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>🍊 Juicy Stats</b> {isLoading ? <Skeleton /> : report ? `v${report.config.appVersion}` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>💡️ Lighthouse</b> {isLoading ? <Skeleton /> : report ? `v${report.config.lighthouseVersion}` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>🛠️ AXE</b> {isLoading ? <Skeleton /> : report ? `v${report.config.axeVersion}` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>🌐 Network</b>
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Round Trip Time</span>{' '}
          {isLoading ? <Skeleton /> : report ? `${report.config.throttling.rttMs} ms` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Download Speed</span>{' '}
          {isLoading ? <Skeleton /> : report ? `${report.config.throttling.downloadThroughputKbps} kbps` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Upload Speed</span>{' '}
          {isLoading ? <Skeleton /> : report ? `${report.config.throttling.uploadThroughputKbps} kbps` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>🚦 CPU</b>{' '}
          {isLoading ? <Skeleton /> : report ? `${report.config.throttling.cpuSlowdownMultiplier}x slowdown` : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <b>📱 Screen</b>
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Mobile</span>{' '}
          {isLoading ? <Skeleton /> : report ? report.config.screenEmulation.mobile ? 'Yes' : 'No' : ''}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Resolution (px)</span>{' '}
          {isLoading ? (
            <Skeleton />
          ) : report ? (
            `${report.config.screenEmulation.width}x${report.config.screenEmulation.height}`
          ) : (
            ''
          )}
        </ListItem>

        <ListItem className="sm:grid-cols-[1fr_1fr]">
          <span className="sm:pl-5">Device Scale</span>{' '}
          {isLoading ? <Skeleton /> : report ? `${report.config.screenEmulation.deviceScaleFactor}x` : ''}
        </ListItem>
      </List>
    </PageSection>
  )
}
