import { z } from 'zod'

export const EmailForagerQueueDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  status: z.enum(['pending', 'completed', 'failed']),
  urlId: z.string(),
})

export type EmailForagerQueueDocument = z.infer<typeof EmailForagerQueueDocumentSchema>
