import React, { useMemo } from 'react'
import { Metrics, SCORE_THRESHOLDS } from 'types'

import { ReportKeyHighlights } from '@/pages/report/components/ReportKeyHighlights'
import { useReport } from '@/reports/hooks/useReport'

export const ReportOverviewKeyHighlights = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report

  // Get all the failed metrics
  const allFailedMetrics = useMemo(() => {
    return Object.values(report?.metrics ?? {}).filter(reportMetric => {
      const metric = Metrics[reportMetric.id]

      if (!metric) {
        return
      }

      if (metric.hidden) {
        return
      }

      const isNotApplicable = reportMetric.score === null && reportMetric.scoreDisplayMode === 'notApplicable'

      if (isNotApplicable) {
        return
      }

      // All non-sweet metrics are opportunities
      // NOTE: This includes obfuscated metrics because scores will be -1 if they are failed and 1 if they passed
      if (reportMetric.score !== null && reportMetric.score < SCORE_THRESHOLDS.SWEET) {
        return true
      }

      return false
    })
  }, [report?.metrics])

  // Count the number of failed metrics that are easy to fix
  const lowHangingFruits = useMemo(() => {
    return allFailedMetrics.filter(reportMetric => Metrics[reportMetric.id].solution?.fixability === 'easy')
  }, [allFailedMetrics])

  const vsCompetitorScoreDifference = (report?.overallScore || 0) - (report?.competitors.avgOverallScore || 0)

  return (
    <ReportKeyHighlights
      issuesFound={allFailedMetrics.length}
      vsCompetitorScoreDifference={vsCompetitorScoreDifference}
      lowHangingFruits={lowHangingFruits.length}
      isLoading={isLoading}
    />
  )
}
