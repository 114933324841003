import { HeadingText, ParagraphText } from 'components'
import React, { ComponentProps, useEffect } from 'react'
import { MetricCategoryId } from 'types'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'

import { PageSection } from '../../../components/pageSection/PageSection'
import { ANIMATIONS_ENABLED, JUICE_METER_ANIMATION_DURATION } from '../constants'
import { useReportJuiceMeterAnimateOnce } from '../hooks/useReportJuiceMeterAnimateOnce'
import { ReportJuiceMeter } from './ReportJuiceMeter'

type Props = ComponentProps<typeof PageSection> & {
  reportId: string
  metricCategoryId?: MetricCategoryId | null
  title?: string
  score: number | null
  heading: string
  description: string
  animateOnceId?: string // If set, the animation will only play once for this id
  isLoading: boolean
  isPaid: boolean
  onScoreBreakdownClick: () => void
}

export const ReportSummary = ({
  reportId,
  metricCategoryId = null,
  title,
  score,
  heading,
  description,
  animateOnceId,
  isLoading,
  isPaid,
  children,
  onScoreBreakdownClick,
  ...props
}: Props) => {
  const [animateOnceMap, setAnimateOnceMap] = useReportJuiceMeterAnimateOnce()

  const hasAnimated = animateOnceId ? animateOnceMap[animateOnceId] : false
  const animate = ANIMATIONS_ENABLED && !hasAnimated && score !== null

  useEffect(() => {
    if (!animateOnceId || hasAnimated || !animate) return

    const timeout = setTimeout(() => {
      setAnimateOnceMap(animateOnceMap => ({ ...animateOnceMap, [animateOnceId]: true }))
    }, JUICE_METER_ANIMATION_DURATION)

    return () => clearTimeout(timeout)
  }, [animate, animateOnceId, hasAnimated, setAnimateOnceMap])

  return (
    <PageSection title={title} isLoading={isLoading} {...props}>
      <ReportJuiceMeter score={score} animate={animate} isLoading={isLoading} />

      <div className="flex flex-col items-center gap-y-2 text-center">
        {isLoading ? <div className="skeleton h-8 w-48" /> : <HeadingText>{heading}</HeadingText>}

        {isLoading ? <div className="skeleton h-6 w-64" /> : <ParagraphText>{description}</ParagraphText>}
      </div>

      <CustomAnchorText
        variant="primary"
        analytics={{
          name: 'report_summary_score_breakdown',
          reportId,
          metricCategoryId,
          metricId: null,
          isPaid,
        }}
        onClick={onScoreBreakdownClick}
      >
        How is this score calculated?
      </CustomAnchorText>

      {children}
    </PageSection>
  )
}
