import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from 'components'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategories, MetricCategoryId } from 'types'

import { Link } from '@/components/link/Link'
import { ReportMetricScore } from '@/pages/report/components/ReportMetricScore'
import { ReportTableCellSkeleton } from '@/pages/report/components/ReportTableCellSkeleton'
import { ReportUpgradeButton } from '@/pages/report/components/ReportUpgradeButton'
import { OBFUSCATED_CLASS_NAMES } from '@/pages/report/dialogs/reportMetricDetailsDialog/constants'
import { useReport } from '@/reports/hooks/useReport'
import { REPORT_ID_PARAM, routes } from '@/router/routes'

import { PageSection } from '../../../../../components/pageSection/PageSection'
import { ReportUrl } from '../../../components/ReportUrl'

const METRIC_CATEGORY_IDS = [
  MetricCategoryId.PERFORMANCE,
  MetricCategoryId.CONVERSION,
  MetricCategoryId.SEO,
  MetricCategoryId.ACCESSIBILITY,
  MetricCategoryId.BEST_PRACTICES,
]

export const ReportOverviewCompetitorComparison = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const competitors = report?.competitors
  const isPaid = Boolean(report?.isPaid)
  const defaultAnalyticsData = {
    reportId: report?.id || '',
    metricCategoryId: null,
    metricId: null,
    isPaid,
  }

  return (
    <PageSection title="Competitor Comparison">
      {/* Overflow-hidden to prevent horizontal scrollbar on desktop */}
      <Table className="overflow-hidden">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Rank</TableHeaderCell>

            <TableHeaderCell>Url</TableHeaderCell>

            <TableHeaderCell>Overall Score</TableHeaderCell>

            {METRIC_CATEGORY_IDS.map(metricCategoryId => (
              <TableHeaderCell key={metricCategoryId}>
                {MetricCategories[metricCategoryId].emoji} {MetricCategories[metricCategoryId].title}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  {Array.from({ length: METRIC_CATEGORY_IDS.length + 3 }).map((_, index) => (
                    <TableCell key={index}>
                      <ReportTableCellSkeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : competitors?.items.map(competitor => {
                const isCompetitorObsfucated = Boolean(competitor.isObfuscated)

                return (
                  <TableRow key={competitor.rank} className="relative" selected={report?.url === competitor.url}>
                    <TableCell>{competitor.rank}</TableCell>

                    <TableCell className={twMerge('relative', isCompetitorObsfucated && OBFUSCATED_CLASS_NAMES)}>
                      <ReportUrl
                        url={competitor.url || 'https://youcannotgethis.borat'}
                        disabled={isCompetitorObsfucated}
                        analytics={{
                          name: 'overview_competitor_comparison_url',
                          ...defaultAnalyticsData,
                        }}
                      />
                    </TableCell>

                    {[
                      competitor.overallScore,
                      competitor.metricCategoryScores.performance,
                      competitor.metricCategoryScores.conversion,
                      competitor.metricCategoryScores.seo,
                      competitor.metricCategoryScores.accessibility,
                      competitor.metricCategoryScores['best-practices'],
                    ].map((score, index) => (
                      <TableCell key={index}>
                        <ReportMetricScore score={score} isObfuscated={isCompetitorObsfucated} />
                      </TableCell>
                    ))}

                    {isCompetitorObsfucated && (
                      <TableCell className="p-0">
                        <ReportUpgradeButton
                          analytics={{
                            name: 'overview_competitor_comparison_upgrade',
                            ...defaultAnalyticsData,
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>

      <Link
        variant="primary"
        to={report?.id ? routes.reportPerformanceMetrics.replace(REPORT_ID_PARAM, report.id) : ''}
        disabled={!report?.id}
      >
        See More Competitor Comparisons <ArrowRightIcon className="h-4 w-4" />
      </Link>
    </PageSection>
  )
}
