import React, { ComponentPropsWithoutRef, RefObject } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'h1'> & {
  forwardRef?: RefObject<HTMLHeadingElement>
}

export const HugeText = ({ forwardRef, className = '', children, ...props }: Props) => {
  return (
    <h1
      ref={forwardRef}
      className={twMerge('font-display text-3xl uppercase text-base-content lg:text-5xl', className)}
      {...props}
    >
      {children}
    </h1>
  )
}
