import React, { ComponentProps, useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

import { PageLayout } from '@/components/pageLayout/PageLayout'

const useScrollToTop = () => {
  const { reportId = '' } = useParams()
  const { pathname } = useLocation()
  const tabPathname = pathname.split(`/${reportId}/`)[1]
  const [tabId, categoryTabId] = tabPathname.split('/')

  const [prevTabId, setPrevTabId] = useState<string | null>(null)
  const [prevCategoryTabId, setPrevCategoryTabId] = useState<string | null>(null)

  const scrollRef = useRef<HTMLDivElement>(null)

  const scrollToTop = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0)
    }
  }, [scrollRef])

  useEffect(() => {
    // If the tab changes, scroll to the top
    if (tabId !== prevTabId || categoryTabId !== prevCategoryTabId) {
      scrollToTop()
      setPrevTabId(tabId)
      setPrevCategoryTabId(categoryTabId)
    }
  }, [tabId, prevTabId, scrollToTop, categoryTabId, prevCategoryTabId])

  return scrollRef
}

type Props = ComponentProps<typeof PageLayout>

export const ReportTabLayout = ({ ...props }: Props) => {
  const scrollRef = useScrollToTop()

  return <PageLayout {...props} forwardRef={scrollRef} />
}
