'use client'

import { motion } from 'framer-motion'
import React, { ComponentProps } from 'react'

type Props = ComponentProps<typeof motion.div>

export const Backdrop = ({ ...props }: Props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-neutral/80 transition-opacity dark:bg-neutral-content/80"
      {...props}
    />
  )
}
