export const validateDocumentId = (docId: string) => {
  if (!docId) {
    throw new Error('Invalid request: missing docId.')
  }

  if (docId.length !== 20) {
    throw new Error('Invalid document ID.')
  }

  if (!docId.match(/^[a-zA-Z0-9_-]+$/)) {
    throw new Error('Invalid request: docId contains invalid characters.')
  }

  return true
}
