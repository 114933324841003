import { Headerbar } from 'components'
import { config } from 'config'
import React from 'react'

import { Markdown } from '@/components/markdown/Markdown'
import { PageLayout } from '@/components/pageLayout/PageLayout'
import { PageSection } from '@/components/pageSection/PageSection'

import privacyMarkdown from './privacy.md?raw'

export const Privacy = () => {
  return (
    <>
      <Headerbar />

      <PageLayout>
        <PageSection contentClassName="items-start max-w-3xl" title={`${config.name} Privacy Policy`}>
          <Markdown>{privacyMarkdown}</Markdown>
        </PageSection>
      </PageLayout>
    </>
  )
}
