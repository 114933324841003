import { useMemo } from 'react'
import { MetricCategories, MetricId, Metrics, ReportMetric, SCORE_THRESHOLDS } from 'types'

import { useReport } from '@/reports/hooks/useReport'

// Grab the top 5 highest impact metrics, no matter the category
export const useReportTopOpportunityMetrics = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const obfuscatedMetricIds = data?.data.obfuscatedMetricIds

  return useMemo(() => {
    const selectedMetrics: Array<ReportMetric<MetricId>> = (
      report
        ? Object.values(report.metrics).filter(reportMetric => {
            const metric = Metrics[reportMetric.id]

            if (!metric) {
              return
            }

            if (metric.hidden) {
              return
            }

            if (metric.isCompositeMetric) {
              return
            }

            const score = reportMetric.score
            const isNotApplicable = score === null

            if (isNotApplicable) {
              return
            }

            // We don't care about obfuscated metrics here, we only want to show visible metrics
            if (obfuscatedMetricIds?.includes(reportMetric.id)) {
              return
            }

            // If the category metric has a weight of 0, we don't want to consider it
            const category = Object.values(MetricCategories).find(category =>
              category.metrics.some(metric => metric.id === reportMetric.id),
            )
            const categoryMetric = category?.metrics.find(metric => metric.id === reportMetric.id)

            if (categoryMetric?.weight === 0) {
              return
            }

            return score < SCORE_THRESHOLDS.SWEET
          })
        : []
    ) // Sort by relative weight, highest to lowest
      .sort((a, b) => {
        // We first need to find the category of the metric, then we can get the weight from there
        const categoryA = Object.values(MetricCategories).find(category =>
          category.metrics.some(metric => metric.id === a.id),
        )
        const totalCategoryWeightA = categoryA?.metrics
          .filter(metric => metric.weight > 0) // Only consider metrics with a weight
          .reduce((acc, categoryMetric) => acc + categoryMetric.weight, 0)
        const categoryMetricA = categoryA?.metrics.find(metric => metric.id === a.id)
        const relativeWeightOfMetricA =
          categoryMetricA && totalCategoryWeightA ? (categoryMetricA.weight / totalCategoryWeightA) * 100 : null

        const categoryB = Object.values(MetricCategories).find(category =>
          category.metrics.some(metric => metric.id === b.id),
        )
        const totalCategoryWeightB = categoryB?.metrics
          .filter(metric => metric.weight > 0) // Only consider metrics with a weight
          .reduce((acc, categoryMetric) => acc + categoryMetric.weight, 0)
        const categoryMetricB = categoryB?.metrics.find(metric => metric.id === b.id)
        const relativeWeightOfMetricB =
          categoryMetricB && totalCategoryWeightB ? (categoryMetricB.weight / totalCategoryWeightB) * 100 : null

        return (relativeWeightOfMetricB ?? 0) - (relativeWeightOfMetricA ?? 0)
      })
      .slice(0, 5)

    return {
      data: selectedMetrics,
      isLoading,
    }
  }, [report, isLoading, obfuscatedMetricIds])
}
