import { Navbar, NavbarItem } from 'components'
import React, { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MetricCategoryId } from 'types'

import {
  REPORT_CATEGORY_TAB_ID_PARAM,
  REPORT_ID_PARAM,
  REPORT_TAB_ID_PARAM,
  ReportCategoryTabId,
  routes,
} from '@/router/routes'

import { useReportCategoryMetrics } from '../hooks/useReportCategoryMetrics'

const TABS: Array<{
  id: ReportCategoryTabId
  label: string
}> = [
  {
    id: ReportCategoryTabId.SUMMARY,
    label: 'Summary',
  },
  {
    id: ReportCategoryTabId.METRICS,
    label: 'Metrics',
  },
]

type Props = {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryNavbar = ({ metricCategoryId }: Props) => {
  const { reportId = '' } = useParams()
  const { pathname } = useLocation()

  const { metrics, isLoading } = useReportCategoryMetrics(metricCategoryId, false)

  const navigate = useNavigate()

  const onTabClick = useCallback(
    (tabId: ReportCategoryTabId) => {
      navigate(
        routes.reportCategoryTab
          .replace(REPORT_ID_PARAM, reportId)
          .replace(REPORT_TAB_ID_PARAM, metricCategoryId)
          .replace(REPORT_CATEGORY_TAB_ID_PARAM, tabId),
      )
    },
    [navigate, reportId, metricCategoryId],
  )

  return (
    <Navbar variant="boxed">
      {TABS.map(tab => (
        <NavbarItem key={tab.id} active={pathname.includes(tab.id)} onClick={() => onTabClick(tab.id)}>
          {tab.label}
          {tab.id === ReportCategoryTabId.METRICS && !isLoading && ` (${metrics?.length})`}
        </NavbarItem>
      ))}
    </Navbar>
  )
}
