import {
  Dialog,
  DialogContent,
  DialogHeader,
  ParagraphText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from 'components'
import { config } from 'config'
import React, { ComponentProps, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { MetricCategories, MetricCategoryId } from 'types'
import { formatPercentage } from 'utils'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'
import { useReport } from '@/reports/hooks/useReport'
import {
  REPORT_CATEGORY_TAB_ID_PARAM,
  REPORT_ID_PARAM,
  REPORT_TAB_ID_PARAM,
  ReportCategoryTabId,
  routes,
} from '@/router/routes'

import { ReportSupportLink } from '../../components/ReportSupportLink'
import { ReportTableCellSkeleton } from '../../components/ReportTableCellSkeleton'

type Props = ComponentProps<typeof Dialog>

export const ReportOverallScoreBreakdownDialog = ({ className = '', onClose, ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const reportId = report?.id || ''
  const isPaid = Boolean(report?.isPaid)
  const defaultAnalyticsData = {
    reportId,
    metricCategoryId: null, // Category not applicable for overall score breakdown
    metricId: null, // Metric not applicable for overall score breakdown
    isPaid,
  }

  const overallScore = report?.overallScore || 0
  const formattedOverallScore = formatPercentage(overallScore)

  const totalWeight = useMemo(
    () => Object.values(MetricCategories).reduce((acc, metricCategory) => acc + metricCategory.weight, 0),
    [],
  )

  const navigate = useNavigate()

  const onCategoryTitleClick = useCallback(
    (metricCategoryId: MetricCategoryId) => {
      navigate(
        routes.reportCategoryTab
          .replace(REPORT_ID_PARAM, reportId)
          .replace(REPORT_TAB_ID_PARAM, metricCategoryId)
          .replace(REPORT_CATEGORY_TAB_ID_PARAM, ReportCategoryTabId.SUMMARY),
      )
    },
    [navigate, reportId],
  )

  return (
    <Dialog className={twMerge('', className)} onClose={onClose} {...props}>
      <DialogHeader onClose={onClose} title="Overall Score Breakdown" />

      <DialogContent>
        <ParagraphText>
          The overall score is a weighted average of each category score in this report. Here&apos;s how the overall
          score is calculated:
        </ParagraphText>

        <Table>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Category</TableHeaderCell>

              <TableHeaderCell>Score</TableHeaderCell>

              <TableHeaderCell>Weight</TableHeaderCell>

              <TableHeaderCell>Weighted Score</TableHeaderCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.values(MetricCategories).map(metricCategory => {
              const metricId = metricCategory.id
              const score = report?.metricCategoryScores[metricId]
              const formattedScore = score ? formatPercentage(score) : '-'
              const weightedScore = score ? formatPercentage((score * metricCategory.weight) / totalWeight) : '-'

              return (
                <TableRow key={metricCategory.id}>
                  <TableCell>
                    <CustomAnchorText
                      analytics={{
                        name: 'overall_score_breakdown_category_title',
                        ...defaultAnalyticsData,
                      }}
                      onClick={() => onCategoryTitleClick(metricCategory.id)}
                    >
                      {metricCategory.title}
                    </CustomAnchorText>
                  </TableCell>

                  <TableCell>{isLoading ? <ReportTableCellSkeleton /> : formattedScore}</TableCell>

                  <TableCell>{metricCategory.weight}</TableCell>

                  <TableCell>{isLoading ? <ReportTableCellSkeleton /> : weightedScore}</TableCell>
                </TableRow>
              )
            })}

            <TableRow>
              <TableCell>
                <b>Overall Score</b>
              </TableCell>

              <TableCell />

              <TableCell />

              <TableCell>{isLoading ? <ReportTableCellSkeleton /> : <b>{formattedOverallScore}</b>}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <ReportSupportLink
          className="flex justify-center"
          href={`mailto:${config.emails.support}?subject=Report%20question%20for%20${report?.title}`}
          analytics={{
            name: 'overall_score_breakdown_support',
            ...defaultAnalyticsData,
          }}
        >
          Still have questions? Feel free to reach out!
        </ReportSupportLink>
      </DialogContent>
    </Dialog>
  )
}
