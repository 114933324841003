import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useReport } from '@/reports/hooks/useReport'

import { trackAnalyticsEvent } from '../api/mixpanel'

export const useTrackPageViews = () => {
  const location = useLocation()

  const { data } = useReport()

  const report = data?.data.report
  const reportId = report?.id
  const isPaid = report ? Boolean(report.isPaid) : null

  useEffect(() => {
    trackAnalyticsEvent('page_viewed', {
      pathname: location.pathname,
      report_id: reportId || null,
      is_paid: isPaid,
    })
  }, [location, isPaid, reportId])
}
