import React from 'react'
import { MetricCategoryId } from 'types'

import { ReportCategoryMetrics } from '@/pages/report/components/ReportCategoryMetrics'
import { ReportCategoryMetricsCta } from '@/pages/report/components/ReportCategoryMetricsCta'
import { ReportCategoryMetricsTestimonials } from '@/pages/report/components/ReportCategoryMetricsTestimonials'
import { useReport } from '@/reports/hooks/useReport'

type Props = {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryMetricsTab = ({ metricCategoryId }: Props) => {
  const { data } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <>
      <ReportCategoryMetrics metricCategoryId={metricCategoryId} />

      {!isPaid && (
        <>
          <ReportCategoryMetricsCta metricCategoryId={metricCategoryId} />

          <ReportCategoryMetricsTestimonials metricCategoryId={metricCategoryId} />
        </>
      )}
    </>
  )
}
