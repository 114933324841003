import { useMemo } from 'react'
import { MetricCategories, MetricCategoryId, MetricId, Metrics, ReportMetric } from 'types'

import { useReport } from '@/reports/hooks/useReport'

import { getVsCompetitorsScoreDifference } from '../utils/getVsCompetitorsScoreDifference'
import { ReportCategoryMetricsSortBy, useReportCategoryMetricsSorting } from './useReportCategoryMetricsSorting'

type Row = ReportMetric<MetricId> & {
  avgCompetitorsScore: number | null
  isObfuscated: boolean
  sortableValues: Record<ReportCategoryMetricsSortBy, string | number | boolean | null>
}

export const useReportCategoryMetrics = (
  metricCategoryId: MetricCategoryId,
  // shouldSort is an optimisation where we don't need to sort the metrics
  shouldSort = true,
) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const obfuscatedMetricIds = data?.data.obfuscatedMetricIds

  const [sorting] = useReportCategoryMetricsSorting(metricCategoryId)

  const reportCategoryMetrics = useMemo(() => {
    if (!report) {
      return []
    }

    const totalCategoryWeight = MetricCategories[metricCategoryId].metrics
      .filter(metric => metric.weight > 0) // Only consider metrics with a weight
      .reduce((acc, categoryMetric) => acc + categoryMetric.weight, 0)

    let metrics = MetricCategories[metricCategoryId].metrics.flatMap(categoryMetric => {
      const metricId = categoryMetric.id as keyof typeof report.metrics
      const reportMetric = report?.metrics[metricId]

      if (!reportMetric) {
        return []
      }

      // For each solutionId, add a row
      const metric = Metrics[metricId]

      if (!metric) {
        throw new Error(`Metric ${metricId} not found`)
      }

      if (metric.hidden) {
        return []
      }

      const relativeWeightOfMetric = totalCategoryWeight
        ? (categoryMetric.weight / totalCategoryWeight) * 100
        : categoryMetric.weight // Shouldn't be possible but just in case to prevent division by 0
      const avgCompetitorsScore = report.competitors.avgMetricScores[metricId] || 0
      const isObfuscated = Boolean(obfuscatedMetricIds?.includes(metricId))

      // All we need to do here is add the sortable values to the reportMetric
      const row: Row = {
        ...reportMetric,
        avgCompetitorsScore,
        isObfuscated,
        // FIXME: SS We could further optimise this by not adding the sortable values if shouldSort is false
        sortableValues: {
          metricTitle: metric.title,
          metricScoreRating: reportMetric.score,
          metricScore: reportMetric.score,
          metricImpact: relativeWeightOfMetric,
          solutionFixability: Metrics[metricId].solution?.fixability || null,
          metricVsCompetitorsScore: getVsCompetitorsScoreDifference({
            score: reportMetric.score,
            avgCompetitorsScore,
          }),
          metricObfuscated: isObfuscated,
        },
      }

      return row
    })

    if (shouldSort) {
      const reportHasObfuscatedMetrics = obfuscatedMetricIds && obfuscatedMetricIds.length > 0

      metrics = metrics.sort((a, b) => {
        if (!reportHasObfuscatedMetrics) {
          // Move null values to the bottom if there are no obfuscated metrics
          if (a.score === null && b.score !== null) {
            return 1
          }

          if (b.score === null && a.score !== null) {
            return -1
          }
        }

        // Group by obfuscation status
        if (a.isObfuscated !== b.isObfuscated) {
          return a.isObfuscated ? 1 : -1
        }

        // Sort within groups by the specified sortBy criteria
        const aValue = a.sortableValues[sorting.sortBy]
        const bValue = b.sortableValues[sorting.sortBy]

        if (sorting.sortDirection === 'asc') {
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue)
          }

          return (aValue as number) - (bValue as number)
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return bValue.localeCompare(aValue)
        }

        return (bValue as number) - (aValue as number)
      })
    }

    return metrics
  }, [metricCategoryId, obfuscatedMetricIds, report, shouldSort, sorting.sortBy, sorting.sortDirection])

  return {
    metrics: reportCategoryMetrics,
    isLoading,
  }
}
