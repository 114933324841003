import { Headerbar } from 'components'
import { config } from 'config'
import React from 'react'

import { Markdown } from '@/components/markdown/Markdown'
import { PageLayout } from '@/components/pageLayout/PageLayout'
import { PageSection } from '@/components/pageSection/PageSection'

import termsMarkdown from './terms.md?raw'

export const Terms = () => {
  return (
    <>
      <Headerbar />

      <PageLayout>
        <PageSection contentClassName="items-start max-w-3xl" title={`${config.name} Terms of Service`}>
          <Markdown>{termsMarkdown}</Markdown>
        </PageSection>
      </PageLayout>
    </>
  )
}
