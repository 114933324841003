import { MetricId, ReportMetric } from 'types'

import { formatMetricValue } from './formatMetricValue'

export const getMetricSavings = (metric: ReportMetric<MetricId>) => {
  if (metric.scoreDisplayMode === 'metricSavings') {
    if ('details' in metric && metric.details) {
      if ('overallSavingsMs' in metric.details && metric.details.overallSavingsMs) {
        return formatMetricValue(metric.details.overallSavingsMs, 'millisecond')
      }

      if ('overallSavingsBytes' in metric.details && metric.details.overallSavingsBytes) {
        return formatMetricValue(metric.details.overallSavingsBytes, 'byte')
      }

      if ('items' in metric.details && Array.isArray(metric.details.items) && metric.details.items.length > 0) {
        // FIXME: SS - any[] to overcome some type issues
        const items = metric.details.items as any[]

        const sumOfWastedMs = items.reduce(
          (acc: number, item: any) => acc + ('wastedMs' in item ? item.wastedMs : 0),
          0,
        )

        if (sumOfWastedMs) {
          return formatMetricValue(sumOfWastedMs, 'millisecond')
        }

        const sumOfWastedBytes = items.reduce(
          (acc: number, item: any) => acc + ('wastedBytes' in item ? item.wastedBytes : 0),
          0,
        )

        if (sumOfWastedBytes) {
          return formatMetricValue(sumOfWastedBytes, 'byte')
        }

        const sumOfTotalBytes = items.reduce(
          (acc: number, item: any) => acc + ('totalBytes' in item ? item.totalBytes : 0),
          0,
        )

        if (sumOfTotalBytes) {
          return formatMetricValue(sumOfTotalBytes, 'byte')
        }
      }
    }
  }

  if (metric.id === MetricId.THIRD_PARTY_SUMMARY && metric.details && metric.details.summary) {
    return formatMetricValue(metric.details.summary.wastedMs, 'millisecond')
  }

  return '-'
}
