import mixpanel from 'mixpanel-browser'

import { AnalyticsEventData } from '../models'

const isDevelopment = import.meta.env.DEV
export const ANALYTICS_ENABLED = !isDevelopment && import.meta.env.VITE_MIXPANEL_TOKEN

if (ANALYTICS_ENABLED) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN || '', {
    track_pageview: false, // we track these manually
    persistence: 'localStorage',
    debug: isDevelopment,
  })
}

export const identifyUser = (uid: string) => {
  if (ANALYTICS_ENABLED) {
    mixpanel.identify(uid)
  }
}

export const trackAnalyticsEvent = <T extends keyof AnalyticsEventData>(event: T, data: AnalyticsEventData[T]) => {
  if (ANALYTICS_ENABLED) {
    mixpanel.track(event, data)
  }
}

export { mixpanel }
