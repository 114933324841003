import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'

type Props = ComponentProps<typeof CustomAnchorText>

export const ReportSupportLink = ({ className = '', children, ...props }: Props) => {
  return (
    <CustomAnchorText className={twMerge('', className)} variant="primary" {...props}>
      <QuestionMarkCircleIcon className="h-4 w-4" />
      {children}
    </CustomAnchorText>
  )
}
