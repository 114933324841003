'use client'

import { BarsArrowDownIcon } from '@heroicons/react/24/solid'
import { useHover } from '@uidotdev/usehooks'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { TableHeaderCell } from './TableHeaderCell'

type Props = ComponentProps<'th'> & {
  sortable?: boolean
  sorted?: boolean
  sortDirection?: 'asc' | 'desc'
}

export const SortableTableHeaderCell = ({
  className = '',
  sortable,
  sorted,
  sortDirection,
  children,
  ...props
}: Props) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <TableHeaderCell
      forwardRef={hoverRef}
      className={twMerge('', sortable ? 'cursor-pointer' : '', className)}
      {...props}
    >
      <div className="flex items-center gap-x-4">
        {children}

        <AnimatePresence>
          {sortable ? (
            <>
              {sorted || isHovered ? (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <BarsArrowDownIcon
                    className={twMerge(
                      'h-4 w-4 transform text-primary transition-transform',
                      sortDirection === 'asc' ? 'rotate-180' : '',
                    )}
                  />
                </motion.div>
              ) : (
                // placeholder for the icon to keep the header cell width consistent
                <div className="w-4" />
              )}
            </>
          ) : null}
        </AnimatePresence>
      </div>
    </TableHeaderCell>
  )
}
