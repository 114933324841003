import { ArrowTurnDownRightIcon } from '@heroicons/react/24/solid'
import { ParagraphText, Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow } from 'components'
import React, { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategoryId, MetricDefinitionTableConfig, MetricId, Metrics, ReportMetric } from 'types'
import { validateUrl } from 'utils'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'
import { ReportTableCellSkeleton } from '@/pages/report/components/ReportTableCellSkeleton'
import { formatMetricValue } from '@/pages/report/utils/formatMetricValue'

// FIXME: SS make types stricter and validate that value is a node object
const renderNode = (value: any, reportScreenshotUrl?: string): React.ReactNode => {
  if (!reportScreenshotUrl || !value || !value.boundingRect) return null

  const { left, right, top, bottom, width, height } = value.boundingRect

  return (
    <div
      className="relative overflow-hidden"
      style={{
        width,
        height,
      }}
    >
      <div
        className="absolute bg-base-200"
        style={{
          top: top * -1,
          left: left * -1,
          right: right * -1,
          bottom: bottom * -1,
        }}
      >
        <img src={reportScreenshotUrl} alt={value.nodeLabel} />
      </div>
    </div>
  )
}

const renderValue = ({
  type,
  value,
  screenshotUrl,
  reportId,
  metricCategoryId,
  metricId,
  isPaid,
}: {
  type: MetricDefinitionTableConfig['columns'][number]['type']
  value: any // FIXME: SS we should make the types stricter
  screenshotUrl?: string
  reportId: string
  metricCategoryId: MetricCategoryId | null // Can be null in the Overview tab
  metricId: MetricId
  isPaid: boolean
}): React.ReactNode => {
  switch (type) {
    case 'bytes':
      return formatMetricValue(value, 'byte')
    case 'milliseconds':
      return formatMetricValue(value, 'millisecond')
    case 'unitless':
      return formatMetricValue(value, 'unitless')
    case 'link':
      if (!validateUrl(value)) return value

      return (
        <CustomAnchorText
          className="block truncate"
          href={value}
          analytics={{ name: 'metric_details_resource', reportId, metricCategoryId, metricId, label: value, isPaid }}
        >
          {value}
        </CustomAnchorText>
      )
    case 'node':
      return renderNode(value, screenshotUrl)
    case 'array':
      return value.join(', ')
    case 'boolean':
      return value ? 'Yes' : 'No'
    default:
      return value
  }
}

type MetricItem = Record<string, any>

export type Props = {
  reportId: string
  metricCategoryId: MetricCategoryId | null // Can be null in the Overview tab
  metricId: MetricId
  reportMetric?: ReportMetric<MetricId>
  reportScreenshotUrl?: string
  isLoading: boolean
  isPaid: boolean
}

export const ReportMetricDetailsDialogResources = ({
  reportId,
  metricCategoryId,
  metricId,
  reportMetric,
  reportScreenshotUrl,
  isLoading,
  isPaid,
}: Props) => {
  const tableConfig = Metrics[metricId].tableConfig

  if (!tableConfig) return null

  if (
    reportMetric &&
    (!('details' in reportMetric) ||
      ('details' in reportMetric &&
        reportMetric.details &&
        'items' in reportMetric.details &&
        reportMetric.details.items.length === 0))
  )
    return null

  const items =
    reportMetric && 'details' in reportMetric && reportMetric.details && 'items' in reportMetric.details
      ? reportMetric.details.items
      : []
  const subItemColumns = tableConfig.subItemColumns || []

  const defaultAnalyticsData = {
    reportId,
    metricCategoryId,
    metricId,
    isPaid,
  }

  return (
    <div className="space-y-2">
      <ParagraphText>{tableConfig.title}</ParagraphText>

      <Table>
        <TableHead>
          <TableRow>
            {tableConfig.columns.map(column => (
              <TableHeaderCell key={column.label}>{column.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <TableRow key={index}>
                  {tableConfig.columns.map((_, columnIndex) => (
                    <TableCell key={columnIndex}>
                      <ReportTableCellSkeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : items.map((item, index) => (
                <Fragment key={index}>
                  <TableRow>
                    {tableConfig.columns.map((column, columnIndex) => {
                      const key = column.key as keyof typeof item
                      let value = ''

                      if (key in item) {
                        if (column.subKey) {
                          value = column.subKey in item[key] ? item[key][column.subKey] : ''
                        } else {
                          value = item[key]
                        }
                      }

                      return (
                        <TableCell
                          key={columnIndex}
                          className={twMerge('align-top', column.type === 'link' && 'max-w-sm truncate')}
                          colSpan={column.colSpan}
                          title={typeof value === 'string' ? value : undefined}
                        >
                          {renderValue({
                            type: column.type,
                            value,
                            screenshotUrl: reportScreenshotUrl,
                            ...defaultAnalyticsData,
                          })}
                        </TableCell>
                      )
                    })}
                  </TableRow>

                  {/* SUB ITEMS */}
                  {subItemColumns.length > 0 &&
                    'subItems' in item &&
                    item.subItems?.items.map((subItem: MetricItem, subItemIndex: number) => {
                      return (
                        <TableRow key={subItemIndex}>
                          {subItemColumns.map((subColumn, subColumnIndex) => {
                            const isFirstSubItemColumn = subColumnIndex === 0
                            const value = subColumn.subKey
                              ? subItem[subColumn.key][subColumn.subKey]
                              : subItem[subColumn.key]

                            return (
                              <TableCell
                                key={subColumnIndex}
                                className={twMerge('bg-base-200', subColumn.type === 'link' && 'max-w-sm truncate')}
                                colSpan={subColumn.colSpan}
                                title={value}
                              >
                                <div className="flex items-center gap-x-2">
                                  {isFirstSubItemColumn && <ArrowTurnDownRightIcon className="h-4 w-4 shrink-0" />}

                                  {subColumn.label && <b>{subColumn.label}</b>}

                                  <div className="truncate">
                                    {renderValue({
                                      type: subColumn.type,
                                      value,
                                      ...defaultAnalyticsData,
                                    })}
                                  </div>
                                </div>
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
                </Fragment>
              ))}
        </TableBody>
      </Table>
    </div>
  )
}
