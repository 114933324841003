import React from 'react'

import { ReportCta } from '@/pages/report/components/ReportCta'
import { CTAS } from '@/pages/report/constants'
import { useReport } from '@/reports/hooks/useReport'

export const ReportOverviewCta = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  return (
    <ReportCta
      title={CTAS.overview.title}
      description={CTAS.overview.description}
      buttonText={CTAS.overview.button(29)}
      analytics={{
        name: 'report_overview_cta',
        reportId: report?.id || '',
        metricCategoryId: null,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
    />
  )
}
