import { filesize } from 'filesize'

import { date } from '@/date'

export const formatMetricValue = (value: number | null, unit: string) => {
  if (value === null) {
    return ''
  }

  const formattedValue = Number.isInteger(value) ? value.toString() : value.toFixed(2)

  switch (unit) {
    case 'millisecond':
      const durationObj = date.duration(value)
      return durationObj.asSeconds() >= 1
        ? `${durationObj.asSeconds().toFixed(1)} s`
        : `${Math.round(durationObj.asMilliseconds())} ms`
    case 'byte':
      return filesize(value, { round: 2 })
    case 'element':
      return `${formattedValue} elements`
    case 'unitless':
      return formattedValue
    default:
      return `${formattedValue} ${unit}`
  }
}
