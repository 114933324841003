import React, { ComponentPropsWithoutRef, ForwardedRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { SortableTableHeaderCell } from './SortableTableHeaderCell'
import { TableBody } from './TableBody'
import { TableCell } from './TableCell'
import { TableFooter } from './TableFooter'
import { TableHead } from './TableHead'
import { TableHeaderCell } from './TableHeaderCell'
import { TableRow } from './TableRow'

type Props = ComponentPropsWithoutRef<'table'> & {
  forwardRef?: ForwardedRef<HTMLDivElement>
  containerClassName?: string
  containerStyle?: React.CSSProperties
}

export const Table = ({ forwardRef, containerClassName = '', className = '', containerStyle, ...props }: Props) => {
  return (
    <div
      ref={forwardRef}
      className={twMerge(
        'relative w-full shrink-0 overflow-x-auto rounded-xl border border-base-300 sm:rounded-2xl',
        containerClassName,
      )}
      style={containerStyle}
    >
      <table className={twMerge('table table-pin-rows', className)} {...props} />
    </div>
  )
}

export { SortableTableHeaderCell, TableBody, TableCell, TableFooter, TableHead, TableHeaderCell, TableRow }
