import React, { ComponentProps } from 'react'

import { ReportTestimonialSingle } from '@/pages/report/components/ReportTestimonialSingle'
import { TESTIMONIAL_INDICES } from '@/pages/report/constants'
import { useReport } from '@/reports/hooks/useReport'
import { ReportTabId } from '@/router/routes'

type Props = Omit<ComponentProps<typeof ReportTestimonialSingle>, 'testimonial' | 'analytics' | 'isLoading'>

export const ReportDiagnosticsTestimonials = ({ ...props }: Props) => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const testimonials = data?.data.testimonials
  const isPaid = Boolean(report?.isPaid)
  const index = TESTIMONIAL_INDICES[ReportTabId.DIAGNOSTICS][0]
  const testimonial = testimonials?.items[index] || null

  return (
    <ReportTestimonialSingle
      testimonial={testimonial}
      analytics={{
        name: 'diagnostics_testimonials_url',
        reportId: report?.id || null,
        metricCategoryId: null,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
      {...props}
    />
  )
}
