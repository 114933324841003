import { ParagraphText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { PageSection } from '../../../components/pageSection/PageSection'
import { ReportCard } from './ReportCard'
import { ReportTestimonialStars } from './ReportTestimonialStars'
import { ReportUrl } from './ReportUrl'

type Props = ComponentProps<typeof PageSection> & {
  testimonials: Array<{
    name: string
    url: string
    quote: string
    image: string
  }>
  isLoading: boolean
} & Pick<ComponentProps<typeof ReportUrl>, 'analytics'>

export const ReportTestimonialsTriple = ({ className = '', testimonials, analytics, isLoading, ...props }: Props) => {
  if (!isLoading && testimonials.length === 0) {
    return null
  }

  return (
    <>
      <PageSection className={twMerge('', className)} {...props}>
        <div className="grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
          {isLoading
            ? Array.from({ length: 3 }).map((_, index) => (
                <ReportCard key={index} className="gap-y-8">
                  <div className="flex w-full flex-col items-center gap-y-4">
                    <div className="skeleton h-20 w-20 rounded-full" />

                    <div className="skeleton h-[84px] w-full" />

                    <ReportTestimonialStars isLoading={isLoading} />
                  </div>

                  <div className="flex w-full flex-col items-center gap-y-2">
                    <div className="skeleton h-6 w-32" />

                    <div className="skeleton h-6 w-32" />
                  </div>
                </ReportCard>
              ))
            : testimonials.map((testimonial, index) => (
                <ReportCard key={index} className="gap-y-8">
                  <div className="flex w-full flex-col items-center gap-y-4">
                    <img className="h-20 w-20 rounded-full" src={testimonial.image} alt={testimonial.name} />

                    <ParagraphText className="line-clamp-3 text-center text-lg sm:h-[84px]">
                      “{testimonial.quote}”
                    </ParagraphText>

                    <ReportTestimonialStars />
                  </div>

                  <div className="flex w-full flex-col items-center gap-y-2">
                    <ParagraphText>{testimonial.name}</ParagraphText>

                    <ReportUrl url={testimonial.url} analytics={analytics} />
                  </div>
                </ReportCard>
              ))}
        </div>
      </PageSection>
    </>
  )
}
