import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'a'> & {
  variant?: 'primary' | 'primary-button' | 'neutral'
  disabled?: boolean
}

export const AnchorText = ({ className = '', variant = 'neutral', disabled = false, children, ...props }: Props) => {
  return (
    <a
      className={twMerge(
        'inline-flex items-center gap-x-2',
        variant === 'primary'
          ? 'link text-primary transition-opacity hover:opacity-80'
          : variant === 'primary-button'
          ? 'bg-gradient-to-r from-primary to-secondary text-base-100 transition-opacity hover:opacity-80 dark:text-base-content'
          : 'link transition-colors hover:text-primary',
        disabled ? 'pointer-events-none opacity-50' : '',
        className,
      )}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={disabled ? -1 : undefined}
      {...props}
    >
      {children}
    </a>
  )
}
