import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { useCallback, useMemo, useState } from 'react'
import { MetricCategories, MetricCategoryId } from 'types'
import { getReportOverallScoreDescription } from 'utils'

import { Link } from '@/components/link/Link'
import { ReportJuiceMeter } from '@/pages/report/components/ReportJuiceMeter'
import { ReportSummary } from '@/pages/report/components/ReportSummary'
import { ReportOverallScoreBreakdownDialog } from '@/pages/report/dialogs/reportOverallScoreBreakdownDialog/ReportOverallScoreBreakdownDialog'
import { useReport } from '@/reports/hooks/useReport'
import {
  REPORT_CATEGORY_TAB_ID_PARAM,
  REPORT_ID_PARAM,
  REPORT_TAB_ID_PARAM,
  ReportCategoryTabId,
  routes,
} from '@/router/routes'

export const ReportOverviewSummary = () => {
  const { data, isLoading } = useReport()

  const [showOverallScoreBreakdownDialog, setShowOverallScoreBreakdownDialog] = useState(false)

  const report = data?.data.report
  const reportId = report?.id || ''
  const metricCount = useMemo(() => Object.keys(report?.metrics ?? {}).length, [report])
  const overallScore = report?.overallScore || null
  const isPaid = Boolean(report?.isPaid)

  const onOpenOverallScoreBreakdownDialog = useCallback(() => {
    setShowOverallScoreBreakdownDialog(true)
  }, [])

  const onCloseOverallScoreBreakdownDialog = useCallback(() => {
    setShowOverallScoreBreakdownDialog(false)
  }, [])

  return (
    <>
      <ReportSummary
        reportId={reportId}
        metricCategoryId={null}
        title="Is your landing page sweet 🍓 or sour 🍋?"
        score={overallScore}
        heading={getReportOverallScoreDescription(overallScore)}
        description={`Overall score based on ${metricCount} metrics.`}
        animateOnceId="overview"
        isLoading={isLoading}
        isPaid={isPaid}
        onScoreBreakdownClick={onOpenOverallScoreBreakdownDialog}
      >
        <div className="mt-8 flex flex-wrap items-center justify-center gap-x-4 gap-y-8">
          {[
            MetricCategoryId.PERFORMANCE,
            MetricCategoryId.CONVERSION,
            MetricCategoryId.SEO,
            MetricCategoryId.ACCESSIBILITY,
            MetricCategoryId.BEST_PRACTICES,
          ].map(metricCategoryId => (
            <div key={metricCategoryId} className="flex flex-col items-center gap-y-2">
              <ReportJuiceMeter
                score={report?.metricCategoryScores[metricCategoryId] || 0}
                size="sm"
                animate={false}
                isLoading={isLoading}
              />

              <Link
                to={routes.reportCategoryTab
                  .replace(REPORT_ID_PARAM, reportId)
                  .replace(REPORT_TAB_ID_PARAM, metricCategoryId)
                  .replace(REPORT_CATEGORY_TAB_ID_PARAM, ReportCategoryTabId.SUMMARY)}
              >
                {MetricCategories[metricCategoryId].title}

                <ArrowRightIcon className="h-4 w-4" />
              </Link>
            </div>
          ))}
        </div>

        {showOverallScoreBreakdownDialog && (
          <ReportOverallScoreBreakdownDialog open onClose={onCloseOverallScoreBreakdownDialog} />
        )}
      </ReportSummary>
    </>
  )
}
