import React, { ComponentPropsWithoutRef, useEffect, useRef } from 'react'
import GaugeChart from 'react-gauge-chart'
import { twMerge } from 'tailwind-merge'
import { SCORE_THRESHOLDS } from 'types'

import { JUICE_METER_ANIMATION_DELAY, JUICE_METER_ANIMATION_DURATION } from '../constants'
import { ReportScore } from './ReportScore'

const ARC_LENGTHS = [
  SCORE_THRESHOLDS.MEH - SCORE_THRESHOLDS.SOUR,
  SCORE_THRESHOLDS.SWEET - SCORE_THRESHOLDS.MEH,
  1 - SCORE_THRESHOLDS.SWEET,
]

const GAUGE_WIDTHS = {
  sm: 160,
  md: 320,
}

const NEEDLE_WIDTHS = {
  sm: 6,
  md: 12,
}

const DIMENSIONS = {
  sm: {
    gaugeWidth: GAUGE_WIDTHS.sm,
    gaugeHeight: 64,
    arcPadding: 0.04,
    arcWidth: 0.4,
    cornerRadius: 4,
    needleWidth: NEEDLE_WIDTHS.sm,
    needleHeight: 50,
    needleBottom: 10,
    needleLeft: GAUGE_WIDTHS.sm / 2 - NEEDLE_WIDTHS.sm / 2,
  },
  md: {
    gaugeWidth: GAUGE_WIDTHS.md,
    gaugeHeight: 124,
    arcPadding: 0.04,
    arcWidth: 0.4,
    cornerRadius: 8,
    needleWidth: NEEDLE_WIDTHS.md,
    needleHeight: 100,
    needleBottom: 20,
    needleLeft: GAUGE_WIDTHS.md / 2 - NEEDLE_WIDTHS.md / 2,
  },
}

type CustomNeedleProps = ComponentPropsWithoutRef<'div'> & {
  score: number
  duration: number
  animate: boolean
}

const CustomNeedle = ({ score, duration, animate, style, ...props }: CustomNeedleProps) => {
  const needleRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (needleRef.current) {
      // Calculate the rotation angle for the needle based on the score
      const angle = score * 180 - 90 // Convert score to an angle (-90 to 90)
      needleRef.current.style.transform = `rotate(${angle}deg)`
    }
  }, [score])

  return (
    <div
      ref={needleRef}
      className="absolute origin-bottom rounded-lg bg-base-content"
      style={{
        transition: animate
          ? `transform ${duration}ms cubic-bezier(0.22, 0.61, 0.36, 1) ${JUICE_METER_ANIMATION_DELAY}ms`
          : '',
        clipPath: 'polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%)',
        ...style,
      }}
      {...props}
    />
  )
}

type Props = ComponentPropsWithoutRef<typeof GaugeChart> & {
  score: number | null // If null, it's not applicable
  size?: 'sm' | 'md'
  animate: boolean
  isLoading: boolean
  isObfuscated?: boolean
}

export const ReportJuiceMeter = ({ score, size = 'md', animate, isLoading, isObfuscated, ...props }: Props) => {
  const dimensions = DIMENSIONS[size]

  return (
    <div className={twMerge('flex flex-col items-center gap-y-4')}>
      <GaugeChart
        {...props}
        animate={animate}
        nrOfLevels={3}
        percent={score ?? 0.5}
        colors={['#FF5745', '#FF8C42', '#4CAF50']}
        arcsLength={ARC_LENGTHS}
        arcPadding={dimensions.arcPadding}
        arcWidth={dimensions.arcWidth}
        cornerRadius={dimensions.cornerRadius}
        hideText
        style={{ width: dimensions.gaugeWidth, height: dimensions.gaugeHeight }}
        // @ts-expect-error it definitely exists
        customNeedleComponent={
          <>
            {/* NOTE: This fragment is intentional to surface type errors below the above ts-expect-error */}
            <CustomNeedle
              score={score ?? 0.5}
              duration={JUICE_METER_ANIMATION_DURATION}
              animate={animate}
              style={{
                width: dimensions.needleWidth,
                height: dimensions.needleHeight,
                bottom: dimensions.needleBottom,
                left: dimensions.needleLeft,
              }}
            />
          </>
        }
      />

      <ReportScore score={score} animate={animate} isLoading={isLoading} size={size} isObfuscated={isObfuscated} />
    </div>
  )
}
