import { z } from 'zod'

import { MetricId } from '../metrics'

export enum MetricCategoryId {
  PERFORMANCE = 'performance',
  ACCESSIBILITY = 'accessibility',
  SEO = 'seo',
  BEST_PRACTICES = 'best-practices',
  CONVERSION = 'conversion',
}

export const MetricCategoryIdSchema = z.nativeEnum(MetricCategoryId)

export const MetricCategories: Record<
  MetricCategoryId,
  {
    id: MetricCategoryId
    title: string
    emoji: string
    tooltip: string
    weight: number // relative weight
    metrics: {
      id: MetricId
      weight: number // relative weight
    }[]
  }
> = {
  [MetricCategoryId.PERFORMANCE]: {
    id: MetricCategoryId.PERFORMANCE,
    title: 'Performance',
    emoji: '⏱️',
    tooltip: 'How fast does your page go from zero to juicy? Faster speeds keep users around and Google happy!',
    weight: 1,
    metrics: [
      // We follow the default Lighthouse weights for now
      { id: MetricId.FIRST_CONTENTFUL_PAINT, weight: 10 },
      { id: MetricId.LARGEST_CONTENTFUL_PAINT, weight: 25 },
      { id: MetricId.TOTAL_BLOCKING_TIME, weight: 30 },
      { id: MetricId.CUMULATIVE_LAYOUT_SHIFT, weight: 25 },
      { id: MetricId.SPEED_INDEX, weight: 10 },

      // Diagnostic metrics
      { id: MetricId.INTERACTIVE, weight: 0 },
      { id: MetricId.MAX_POTENTIAL_FID, weight: 0 },
      { id: MetricId.RENDER_BLOCKING_RESOURCES, weight: 0 },
      { id: MetricId.USES_RESPONSIVE_IMAGES, weight: 0 },
      { id: MetricId.OFFSCREEN_IMAGES, weight: 0 },
      { id: MetricId.UNMINIFIED_CSS, weight: 0 },
      { id: MetricId.UNMINIFIED_JAVASCRIPT, weight: 0 },
      { id: MetricId.UNUSED_CSS_RULES, weight: 0 },
      { id: MetricId.UNUSED_JAVASCRIPT, weight: 0 },
      { id: MetricId.USES_OPTIMIZED_IMAGES, weight: 0 },
      { id: MetricId.MODERN_IMAGE_FORMATS, weight: 0 },
      { id: MetricId.USES_TEXT_COMPRESSION, weight: 0 },
      { id: MetricId.USES_REL_PRECONNECT, weight: 0 },
      { id: MetricId.SERVER_RESPONSE_TIME, weight: 0 },
      { id: MetricId.REDIRECTS, weight: 0 },
      { id: MetricId.USES_HTTP2, weight: 0 },
      { id: MetricId.EFFICIENT_ANIMATED_CONTENT, weight: 0 },
      { id: MetricId.DUPLICATED_JAVASCRIPT, weight: 0 },
      { id: MetricId.LEGACY_JAVASCRIPT, weight: 0 },
      { id: MetricId.PRIORITIZE_LCP_IMAGE, weight: 0 },
      { id: MetricId.TOTAL_BYTE_WEIGHT, weight: 0 },
      { id: MetricId.USES_LONG_CACHE_TTL, weight: 0 },
      { id: MetricId.DOM_SIZE, weight: 0 },
      { id: MetricId.BOOTUP_TIME, weight: 0 },
      { id: MetricId.FONT_DISPLAY, weight: 0 },
      { id: MetricId.THIRD_PARTY_SUMMARY, weight: 0 },
      { id: MetricId.THIRD_PARTY_FACADES, weight: 0 },
      { id: MetricId.LCP_LAZY_LOADED, weight: 0 },
      { id: MetricId.LAYOUT_SHIFTS, weight: 0 },
      { id: MetricId.USES_PASSIVE_EVENT_LISTENERS, weight: 0 },
      { id: MetricId.NO_DOCUMENT_WRITE, weight: 0 },
      { id: MetricId.LONG_TASKS, weight: 0 },
      { id: MetricId.NON_COMPOSITED_ANIMATIONS, weight: 0 },
      { id: MetricId.UNSIZED_IMAGES, weight: 0 },
      { id: MetricId.VIEWPORT, weight: 0 },
      { id: MetricId.BF_CACHE, weight: 0 },
      { id: MetricId.NETWORK_RTT, weight: 0 },
      { id: MetricId.NETWORK_SERVER_LATENCY, weight: 0 },

      // Useful data but may not necessarily be displayed in the report
      // TODO: SS why are these hidden in LH?
      { id: MetricId.MAIN_THREAD_WORK_BREAKDOWN, weight: 0 },
      { id: MetricId.NETWORK_REQUESTS, weight: 0 },
      { id: MetricId.DIAGNOSTICS, weight: 0 },
      // TODO: SS tricky to render in the UI - added as enhancement
      { id: MetricId.CRITICAL_REQUEST_CHAINS, weight: 0 },
    ],
  },
  [MetricCategoryId.CONVERSION]: {
    id: MetricCategoryId.CONVERSION,
    title: 'Conversion',
    emoji: '💰',
    tooltip: 'Is your page turning visitors into fans? We look at CTAs, form length, and that ‘gotta have it’ factor.',
    weight: 1,
    metrics: [
      { id: MetricId.CTA_VISIBLE, weight: 4 },
      { id: MetricId.CTA_QUALITY, weight: 2 },
      { id: MetricId.CTA_LENGTH, weight: 1 },
      { id: MetricId.FORM_FIELD_COUNT, weight: 2 },
      { id: MetricId.KEYWORD_OPTIMIZATION, weight: 3 },
      { id: MetricId.SOCIAL_PROOF, weight: 3 },
    ],
  },
  [MetricCategoryId.SEO]: {
    id: MetricCategoryId.SEO,
    title: 'SEO',
    emoji: '🔍',
    tooltip:
      'Are you climbing the search ranks or lost in the weeds? Keywords, meta tags, and juicy content are the key.',
    weight: 1,
    metrics: [
      // We follow the default Lighthouse weights for now
      { id: MetricId.IS_CRAWLABLE, weight: 3 }, // ~30% of the score
      { id: MetricId.DOCUMENT_TITLE, weight: 1 }, // this is duplicated in accessibility
      { id: MetricId.META_TITLE_LENGTH, weight: 1 }, // custom metric
      { id: MetricId.META_DESCRIPTION, weight: 1 },
      { id: MetricId.META_DESCRIPTION_LENGTH, weight: 1 }, // custom metric
      { id: MetricId.HTTP_STATUS_CODE, weight: 1 },
      { id: MetricId.LINK_TEXT, weight: 1 },
      { id: MetricId.CRAWLABLE_ANCHORS, weight: 1 },
      { id: MetricId.ROBOTS_TXT, weight: 1 },
      { id: MetricId.HREFLANG, weight: 1 },
      { id: MetricId.CANONICAL, weight: 1 },
    ],
  },
  [MetricCategoryId.ACCESSIBILITY]: {
    id: MetricCategoryId.ACCESSIBILITY,
    title: 'Accessibility',
    emoji: '🧑',
    tooltip:
      'Are you making it easy for everyone to use your site? We look at keyboard navigation, color contrast, and more.',
    weight: 1,
    metrics: [
      // We follow the default Lighthouse/AXE weights for now
      { id: MetricId.ACCESSKEYS, weight: 7 },
      { id: MetricId.ARIA_ALLOWED_ATTR, weight: 10 },
      { id: MetricId.ARIA_ALLOWED_ROLE, weight: 1 },
      { id: MetricId.ARIA_COMMAND_NAME, weight: 7 },
      { id: MetricId.ARIA_CONDITIONAL_ATTR, weight: 7 },
      { id: MetricId.ARIA_DEPRECATED_ROLE, weight: 1 },
      { id: MetricId.ARIA_DIALOG_NAME, weight: 7 },
      { id: MetricId.ARIA_HIDDEN_BODY, weight: 10 },
      { id: MetricId.ARIA_HIDDEN_FOCUS, weight: 7 },
      { id: MetricId.ARIA_INPUT_FIELD_NAME, weight: 7 },
      { id: MetricId.ARIA_METER_NAME, weight: 7 },
      { id: MetricId.ARIA_PROGRESSBAR_NAME, weight: 7 },
      { id: MetricId.ARIA_PROHIBITED_ATTR, weight: 7 },
      { id: MetricId.ARIA_REQUIRED_ATTR, weight: 10 },
      { id: MetricId.ARIA_REQUIRED_CHILDREN, weight: 10 },
      { id: MetricId.ARIA_REQUIRED_PARENT, weight: 10 },
      { id: MetricId.ARIA_ROLES, weight: 7 },
      { id: MetricId.ARIA_TEXT, weight: 7 },
      { id: MetricId.ARIA_TOGGLE_FIELD_NAME, weight: 7 },
      { id: MetricId.ARIA_TOOLTIP_NAME, weight: 7 },
      { id: MetricId.ARIA_TREEITEM_NAME, weight: 7 },
      { id: MetricId.ARIA_VALID_ATTR_VALUE, weight: 10 },
      { id: MetricId.ARIA_VALID_ATTR, weight: 10 },
      { id: MetricId.BUTTON_NAME, weight: 10 },
      { id: MetricId.BYPASS, weight: 7 },
      { id: MetricId.COLOR_CONTRAST, weight: 7 },
      { id: MetricId.DEFINITION_LIST, weight: 7 },
      { id: MetricId.DLITEM, weight: 7 },
      { id: MetricId.DOCUMENT_TITLE, weight: 7 },
      { id: MetricId.DUPLICATE_ID_ARIA, weight: 10 },
      { id: MetricId.FORM_FIELD_MULTIPLE_LABELS, weight: 3 },
      { id: MetricId.FRAME_TITLE, weight: 7 },
      { id: MetricId.HEADING_ORDER, weight: 3 },
      { id: MetricId.HTML_HAS_LANG, weight: 7 },
      { id: MetricId.HTML_LANG_VALID, weight: 7 },
      { id: MetricId.HTML_XML_LANG_MISMATCH, weight: 3 },
      { id: MetricId.IMAGE_ALT, weight: 10 },
      { id: MetricId.IMAGE_REDUNDANT_ALT, weight: 1 },
      { id: MetricId.INPUT_BUTTON_NAME, weight: 10 },
      { id: MetricId.INPUT_IMAGE_ALT, weight: 10 },
      { id: MetricId.LABEL, weight: 7 },
      { id: MetricId.LINK_IN_TEXT_BLOCK, weight: 7 },
      { id: MetricId.LINK_NAME, weight: 7 },
      { id: MetricId.LIST, weight: 7 },
      { id: MetricId.LISTITEM, weight: 7 },
      { id: MetricId.META_REFRESH, weight: 10 },
      { id: MetricId.META_VIEWPORT, weight: 10 },
      { id: MetricId.OBJECT_ALT, weight: 7 },
      { id: MetricId.SELECT_NAME, weight: 7 },
      { id: MetricId.SKIP_LINK, weight: 3 },
      { id: MetricId.TABINDEX, weight: 7 },
      { id: MetricId.TABLE_DUPLICATE_NAME, weight: 1 },
      { id: MetricId.TARGET_SIZE, weight: 7 },
      { id: MetricId.TD_HEADERS_ATTR, weight: 7 },
      { id: MetricId.TH_HAS_DATA_CELLS, weight: 7 },
      { id: MetricId.VALID_LANG, weight: 7 },
      { id: MetricId.VIDEO_CAPTION, weight: 10 },

      // TODO: SS why are these hidden in LH?
      { id: MetricId.EMPTY_HEADING, weight: 0 },
      { id: MetricId.IDENTICAL_LINKS_SAME_PURPOSE, weight: 0 },
      { id: MetricId.LANDMARK_ONE_MAIN, weight: 0 },
      { id: MetricId.LABEL_CONTENT_NAME_MISMATCH, weight: 0 },
      { id: MetricId.TABLE_FAKE_CAPTION, weight: 0 },
      { id: MetricId.TD_HAS_HEADER, weight: 0 },
    ],
  },
  [MetricCategoryId.BEST_PRACTICES]: {
    id: MetricCategoryId.BEST_PRACTICES,
    title: 'Best Practices',
    emoji: '🧙‍♂️',
    tooltip:
      'Verifies adherence to web standards, ensuring secure and maintainable code. Keep your site clean, lean, and secure.',
    weight: 1,
    metrics: [
      { id: MetricId.IS_ON_HTTPS, weight: 5 },
      { id: MetricId.REDIRECTS_HTTP, weight: 1 },
      { id: MetricId.GEOLOCATION_ON_START, weight: 1 },
      { id: MetricId.NOTIFICATION_ON_START, weight: 1 },
      { id: MetricId.CSP_XSS, weight: 3 }, // LH used 0
      { id: MetricId.PASTE_PREVENTING_INPUTS, weight: 3 },
      { id: MetricId.IMAGE_ASPECT_RATIO, weight: 1 },
      { id: MetricId.IMAGE_SIZE_RESPONSIVE, weight: 1 },
      { id: MetricId.VIEWPORT, weight: 1 },
      { id: MetricId.FONT_SIZE, weight: 1 },
      { id: MetricId.DOCTYPE, weight: 1 },
      { id: MetricId.CHARSET, weight: 1 },
      { id: MetricId.DEPRECATIONS, weight: 5 },
      { id: MetricId.THIRD_PARTY_COOKIES, weight: 2 },
      { id: MetricId.ERRORS_IN_CONSOLE, weight: 1 },
      { id: MetricId.VALID_SOURCE_MAPS, weight: 1 }, // LH used 0
      { id: MetricId.INSPECTOR_ISSUES, weight: 1 },
      { id: MetricId.LINE_SPACING, weight: 1 }, // custom metric
    ],
  },
}
