import { MetricId, ReportMetric } from 'types'

import { formatMetricValue } from './formatMetricValue'

export const getMetricValue = (metric: ReportMetric<MetricId>) => {
  if (metric.scoreDisplayMode === 'numeric' || metric.scoreDisplayMode === 'informative') {
    if ('numericValue' in metric && metric.numericValue) {
      return formatMetricValue(metric.numericValue, metric.numericUnit ?? '')
    }

    if ('displayValue' in metric) {
      return metric.displayValue
    }
  }

  return '-'
}
