import { Button, Dialog, DialogActions, DialogContent, DialogHeader, ParagraphText } from 'components'
import { config } from 'config'
import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate, useParams, useRouteError } from 'react-router-dom'
import { useLink } from 'utils'

import { captureException } from '@/errors/api/captureException'
import { REPORT_ID_PARAM, routes } from '@/router/routes'

export const ErrorBoundary = () => {
  const { reportId = '' } = useParams()
  const { pathname } = useLocation()
  const error = useRouteError() as { status: number; statusText: string; error: Error } | Error | undefined

  const navigate = useNavigate()
  const link = useLink()

  const errorIsError = error instanceof Error
  const status = errorIsError ? 500 : error ? error.status : '500'
  const errorMessage = errorIsError ? error.message : error ? error.error?.message : ''
  const statusText = errorIsError || !error ? 'Internal Server Error' : error.statusText

  useEffect(() => {
    if (error) {
      captureException(error)
    }
  }, [error])

  const onContactSupportClick = useCallback(() => {
    link(`mailto:${config.emails.support}`, '_blank')
  }, [link])

  const onGoHomeClick = useCallback(() => {
    // If this is not the report overview page, we should navigate to it using the reportId
    const reportOverviewPath = routes.reportOverview.replace(REPORT_ID_PARAM, reportId)

    if (pathname !== reportOverviewPath) {
      navigate(reportOverviewPath)
    } else {
      navigate(routes.dashboard)
    }
  }, [navigate, pathname, reportId])

  return (
    <Dialog open>
      <DialogHeader title="Error" description={`Code: ${status}`} />

      <DialogContent>
        <ParagraphText>{errorMessage || statusText}</ParagraphText>
      </DialogContent>

      <DialogActions>
        <Button variant="neutral" onClick={onContactSupportClick}>
          Contact support
        </Button>

        <Button variant="primary" onClick={onGoHomeClick}>
          Go home
        </Button>
      </DialogActions>
    </Dialog>
  )
}
