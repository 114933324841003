'use client'

import { XMarkIcon } from '@heroicons/react/24/solid'
import FocusTrap from 'focus-trap-react'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentProps, ReactNode, RefObject, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useKeyPress, useOutsideClick } from 'utils'

import { Backdrop } from '../backdrop/Backdrop'
import { Button } from '../button/Button'
import { ParagraphText } from '../paragraphText/ParagraphText'
import { TitleText } from '../titleText/TitleText'

type HeaderProps = {
  title?: string
  description?: string
  onClose?: () => void
} & ComponentProps<'div'>

export const DialogHeader = ({ className = '', title, description, onClose }: HeaderProps) => {
  return (
    <div className={twMerge('space-y-4 p-4 sm:p-8', className)}>
      <div className="flex items-center justify-between gap-x-4">
        <TitleText>{title}</TitleText>

        {onClose && (
          <Button variant="ghost" className="btn-circle sm:-m-4" onClick={onClose}>
            <XMarkIcon className="h-8 w-8" />
          </Button>
        )}
      </div>

      {description && <ParagraphText>{description}</ParagraphText>}
    </div>
  )
}

type ContentProps = ComponentProps<'div'>

export const DialogContent = ({ className = '', children }: ContentProps) => {
  return (
    <div className={twMerge('flex flex-col gap-y-12 overflow-y-auto px-4 pb-8 sm:px-8', className)}>{children}</div>
  )
}

type ActionsProps = ComponentProps<'div'>

export const DialogActions = ({ className = '', children }: ActionsProps) => {
  return <div className={twMerge('flex justify-end gap-x-3 p-4 sm:p-8', className)}>{children}</div>
}

export type DialogProps = {
  className?: string
  open?: boolean
  closeOnOutsideClick?: boolean
  scrollContainerRef?: RefObject<HTMLDivElement>
  children?: ReactNode
  onClose?: () => void
}

export const Dialog = ({
  className,
  open,
  closeOnOutsideClick = false,
  scrollContainerRef,
  children,
  onClose,
}: DialogProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick(ref, () => {
    if (closeOnOutsideClick && open && onClose) {
      onClose()
    }
  })

  useKeyPress('Escape', () => {
    if (open && onClose) {
      onClose()
    }
  })

  return (
    <AnimatePresence>
      {open && (
        <>
          <div ref={scrollContainerRef} className="fixed inset-0 z-30">
            <Backdrop />

            <motion.div
              className="flex h-full w-full flex-col items-center justify-center p-4"
              initial={{
                opacity: 0,
                transform: 'translateY(4px)',
                scale: 0.95,
              }}
              animate={{ opacity: 1, transform: 'translateY(0px)', scale: 1 }}
              exit={{ opacity: 0, transform: 'translateY(4px)', scale: 0.95 }}
            >
              <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <div
                  ref={ref}
                  className={twMerge(
                    'card flex w-full max-w-3xl flex-col overflow-hidden border border-base-300 bg-base-100 p-0 lg:p-0',
                    className,
                  )}
                >
                  {children}
                </div>
              </FocusTrap>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>
  )
}
