import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { ParagraphText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { useCreateCheckoutSession } from '@/billing/hooks/useCreateCheckoutSession'
import { CustomButton } from '@/components/customButton/CustomButton'

import { PageSection } from '../../../components/pageSection/PageSection'

type Props = ComponentProps<typeof PageSection> & {
  description?: string
  buttonText: string
  isLoading: boolean
} & Omit<Pick<ComponentProps<typeof CustomButton>, 'analytics'>, 'onClick'>

export const ReportCta = ({
  className = '',
  variant = 'primary',
  description,
  buttonText,
  analytics,
  isLoading,
  ...props
}: Props) => {
  const { mutate: createCheckoutSession, isLoading: isCreatingCheckoutSession } = useCreateCheckoutSession()

  return (
    <PageSection
      className={twMerge('sm:gap-y-4', className)}
      variant={variant}
      contentClassName="gap-y-4"
      isLoading={isLoading}
      {...props}
    >
      {description && !isLoading ? (
        <ParagraphText className="max-w-xl text-center text-lg">{description}</ParagraphText>
      ) : (
        <div className="skeleton h-14 w-96" />
      )}

      <CustomButton
        className="mt-4"
        variant="primary"
        size="lg"
        analytics={analytics}
        disabled={isLoading || isCreatingCheckoutSession}
        loading={isCreatingCheckoutSession}
        onClick={() => createCheckoutSession()}
      >
        {isLoading ? (
          <div className="skeleton h-10 w-96 bg-inherit" />
        ) : (
          <>
            {buttonText} <ArrowRightIcon className="h-5 w-5" />
          </>
        )}
      </CustomButton>
    </PageSection>
  )
}
