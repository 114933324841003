import { Button } from 'components'
import React, { ComponentProps } from 'react'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'

type Props = ComponentProps<typeof Button> & {
  analytics: {
    name: string
    reportId: string | null
    metricCategoryId: string | null
    metricId: string | null
    isPaid: boolean | null
  }
}

// A wrapper around CustomButton that let's us track clicks
export const CustomButton = ({ analytics, onClick, ...props }: Props) => {
  return (
    <Button
      {...props}
      onClick={event => {
        trackAnalyticsEvent('element_clicked', {
          type: 'button',
          name: analytics.name,
          report_id: analytics.reportId,
          metric_category_id: analytics.metricCategoryId,
          metric_id: analytics.metricId,
          is_paid: analytics.isPaid,
        })
        onClick?.(event)
      }}
    />
  )
}
