import { HeadingText, HugeText, ParagraphText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'
import { ReportCard } from './ReportCard'
import { ReportUpgradeButton } from './ReportUpgradeButton'

type Props = ComponentProps<'div'> & {
  valueClassName?: string
  icon: string
  value?: number | string
  unit?: string
  label: string
  isLoading?: boolean
  isObfuscated?: boolean
} & ComponentProps<typeof ReportCard> &
  Partial<Pick<ComponentProps<typeof ReportUpgradeButton>, 'analytics'>>

export const ReportHighlight = ({
  className = '',
  valueClassName = '',
  icon,
  value,
  unit,
  label,
  isLoading,
  children,
  isObfuscated,
  analytics,
  ...props
}: Props) => {
  const obfuscatedClassNames = isObfuscated ? OBFUSCATED_CLASS_NAMES : ''

  return (
    <ReportCard className={twMerge('max-w-xs flex-1 gap-y-4', className)} {...props}>
      {isLoading ? (
        <div className="skeleton h-12 w-24" />
      ) : (
        <div className="relative flex items-end gap-x-2">
          <HugeText className={twMerge(obfuscatedClassNames, valueClassName)}>
            <span>{icon}</span>

            <span>{value !== undefined ? ` ${value}` : ''}</span>
          </HugeText>

          {unit && <ParagraphText>{unit}</ParagraphText>}

          {isObfuscated && (
            <ReportUpgradeButton
              analytics={
                analytics || { name: '', reportId: null, metricCategoryId: null, metricId: null, isPaid: null }
              }
            />
          )}
        </div>
      )}

      <div className="flex flex-col items-center">
        {children && (isLoading ? <div className="skeleton mb-4 h-6 w-64" /> : <HeadingText>{children}</HeadingText>)}

        {isLoading ? (
          <div className="skeleton h-6 w-64" />
        ) : (
          <ParagraphText className="text-center">{label}</ParagraphText>
        )}
      </div>
    </ReportCard>
  )
}
