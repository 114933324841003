import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { formatPercentage } from 'utils'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'

const formatAvgCompetitorsScore = (avgCompetitorsScore: number | null | undefined) => {
  // Undefined shouldn't be possible, but just in case
  if (avgCompetitorsScore === null || avgCompetitorsScore === undefined) {
    return '-'
  }

  return formatPercentage(avgCompetitorsScore)
}

type Props = ComponentProps<'div'> & {
  avgCompetitorsScore: number | null | undefined
  isObfuscated: boolean
}

export const ReportMetricAvgCompetitorsScore = ({
  className = '',
  avgCompetitorsScore,
  isObfuscated,
  ...props
}: Props) => {
  return (
    <div
      className={twMerge('flex items-center gap-x-1 font-medium', isObfuscated && OBFUSCATED_CLASS_NAMES, className)}
      {...props}
    >
      {formatAvgCompetitorsScore(avgCompetitorsScore)}
    </div>
  )
}
