import React from 'react'
import { Outlet } from 'react-router-dom'

import { useTrackPageViews } from '@/analytics/hooks/useTrackPageViews'
import { useReleaseNotesOpen } from '@/hooks/useReleaseNotesOpen'

import { ReleaseNotesDialog } from '../releaseNotesDialog/ReleaseNotesDialog'

export const MainLayout = () => {
  useTrackPageViews()

  const [releaseNotesOpen, setReleaseNotesOpen] = useReleaseNotesOpen()

  return (
    <>
      <div className="flex h-full flex-1 flex-col overflow-hidden bg-base-100">
        <Outlet />
      </div>

      <ReleaseNotesDialog open={releaseNotesOpen} onClose={() => setReleaseNotesOpen(false)} />
    </>
  )
}
