import React, { ComponentProps, ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type NavbarItemProps = {
  active?: boolean
} & ComponentProps<'button'>

export const NavbarItem = ({ className = '', active, ...props }: NavbarItemProps) => {
  return (
    <button
      role="tab"
      className={twMerge(
        'tab inline-flex flex-nowrap gap-x-1 whitespace-nowrap font-medium hover:text-primary sm:gap-x-2',
        active ? 'tab-active' : '',
        className,
      )}
      {...props}
    />
  )
}

type Props = ComponentPropsWithoutRef<'div'> & {
  variant?: 'lifted' | 'boxed' | 'bordered'
  containerClassName?: string
}

export const Navbar = ({ containerClassName = '', className = '', variant = 'boxed', ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'shrink-0 overflow-x-auto md:overflow-x-hidden',
        variant === 'lifted' ? 'bg-gradient-to-b from-base-100 to-base-200 pt-2' : '',
        containerClassName,
      )}
    >
      <div
        role="tablist"
        className={twMerge(
          `tabs shrink-0 rounded-none`,
          variant === 'lifted'
            ? 'tabs-lifted'
            : variant === 'boxed'
            ? 'tabs-boxed rounded-xl border border-base-300 bg-base-200'
            : 'tabs-bordered',
          className,
        )}
        {...props}
      />
    </div>
  )
}
