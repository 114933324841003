import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
  size?: 'sm' | 'md'
}

export const ReportCard = ({ className = '', size = 'md', ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'flex flex-col items-center rounded-2xl border border-base-300 bg-gradient-to-b from-base-300 via-base-200 to-base-100',
        size === 'sm' ? 'px-2 py-4 sm:px-4' : 'px-4 py-8 sm:px-8',
        className,
      )}
      {...props}
    />
  )
}
