import React, { ComponentProps, useMemo } from 'react'
import { MetricCategoryId } from 'types'

import { ReportCta } from '@/pages/report/components/ReportCta'
import { useReport } from '@/reports/hooks/useReport'

import { CTAS } from '../constants'
import { useReportCategoryMetrics } from '../hooks/useReportCategoryMetrics'

type Props = Omit<
  ComponentProps<typeof ReportCta>,
  'title' | 'description' | 'buttonText' | 'analytics' | 'isLoading'
> & {
  metricCategoryId: MetricCategoryId
}

export const ReportCategoryMetricsCta = ({ metricCategoryId, ...props }: Props) => {
  const { metrics, isLoading } = useReportCategoryMetrics(metricCategoryId, false)

  const { data } = useReport()
  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  const obfuscatedOpportunitiesCount = useMemo(() => metrics.filter(metric => metric.isObfuscated).length, [metrics])

  return (
    <ReportCta
      variant="primary"
      title={CTAS[metricCategoryId].metrics.title}
      description={CTAS[metricCategoryId].metrics.description(obfuscatedOpportunitiesCount)}
      buttonText={CTAS[metricCategoryId].metrics.button(29)}
      analytics={{
        name: 'report_category_metrics_cta',
        reportId: report?.id || '',
        metricCategoryId,
        metricId: null,
        isPaid,
      }}
      isLoading={isLoading}
      {...props}
    />
  )
}
