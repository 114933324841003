import * as Sentry from '@sentry/react'

import pkg from '../../../../../package.json'

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const ENVIRONMENT = import.meta.env.VITE_SENTRY_ENV
const IGNORED_SENTRY_ERRORS: string[] = [
  'Non-Error promise rejection captured with value', // This is a known error caused by Outlook SafeScan and is safe to ignore
  'auth/wrong-password',
  'auth/network-request-failed',
  'auth/email-already-in-use',
  'auth/invalid-login-credentials',
]

if (!import.meta.env.DEV && SENTRY_DSN && ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: pkg.version,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // don't sample sessions
    replaysOnErrorSampleRate: 1.0, // record replays for all errors
    ignoreErrors: IGNORED_SENTRY_ERRORS,
  })
}

// Set global tag for the service
Sentry.setTag('service', 'app')

export { Sentry as sentry }
