import { Headerbar } from 'components'
import { config } from 'config'
import React from 'react'

import JuicyCoolDudeImage from '@/assets/juicy-cool-dude.webp'
import { BlankState } from '@/components/blankState/BlankState'
import { PageLayout } from '@/components/pageLayout/PageLayout'

export const Dashboard = () => {
  return (
    <>
      <Headerbar />

      <PageLayout>
        <div className="flex flex-1 flex-col items-center justify-center">
          <BlankState
            image={JuicyCoolDudeImage}
            imageAlt="Juicy Cool Dude"
            title={`Welcome to ${config.name}!`}
            description={config.shortDescription}
          />
        </div>
      </PageLayout>
    </>
  )
}
