import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricId, Metrics } from 'types'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'

const formatFixability = (fixability: 'easy' | 'medium' | 'hard' | undefined) => {
  switch (fixability) {
    case 'easy':
      return 'Easy'
    case 'medium':
      return 'Medium'
    case 'hard':
      return 'Hard'
    default:
      return 'Unknown'
  }
}

export const getFixabilityClassNames = (fixability: 'easy' | 'medium' | 'hard' | undefined) => {
  if (fixability === undefined) {
    return ''
  }

  if (fixability === 'easy') {
    return 'text-success'
  }

  if (fixability === 'medium') {
    return 'text-warning'
  }

  return 'text-error'
}

type Props = ComponentProps<'div'> & {
  metricId: MetricId
  isObfuscated?: boolean
}

export const ReportMetricSolutionFixability = ({ className = '', metricId, isObfuscated, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'font-medium',
        getFixabilityClassNames(Metrics[metricId].solution?.fixability),
        isObfuscated && OBFUSCATED_CLASS_NAMES,
        className,
      )}
      {...props}
    >
      {formatFixability(Metrics[metricId].solution?.fixability)}
    </div>
  )
}
