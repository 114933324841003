import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = Omit<ComponentProps<'div'>, 'title'> & {
  title: string
  disabled?: boolean
}

export const Collapsible = ({ className = '', title, disabled, children, ...props }: Props) => {
  return (
    <div
      className={twMerge('collapse collapse-arrow border border-base-300', className)}
      // Makes it focusable when not disabled
      tabIndex={disabled ? -1 : 0}
      {...props}
    >
      <input type="checkbox" disabled={disabled} />

      <div className="collapse-title text-base font-medium leading-relaxed text-base-content">{title}</div>

      <div className="collapse-content overflow-hidden">{children}</div>
    </div>
  )
}
