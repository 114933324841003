// ROUTE PARAMS
export const REPORT_ID_PARAM = ':reportId'
export const REPORT_TAB_ID_PARAM = ':tabId'
export const REPORT_CATEGORY_TAB_ID_PARAM = ':categoryTabId'

// ROUTE QUERY PARAMS
export const METRIC_ID_QUERY_PARAM = 'metricId'
export const METRIC_DETAILS_TAB_ID_QUERY_PARAM = 'tabId'
export const SUCCESS_QUERY_PARAM = 'success'

export enum ReportTabId {
  OVERVIEW = 'overview',
  PERFORMANCE = 'performance',
  CONVERSION = 'conversion',
  SEO = 'seo',
  ACCESSIBILITY = 'accessibility',
  BEST_PRACTICES = 'best-practices',
  DIAGNOSTICS = 'diagnostics',
}

export enum ReportCategoryTabId {
  SUMMARY = 'summary',
  METRICS = 'metrics',
}

export const routes = {
  back: -1,
  dashboard: '/',
  report: `/reports/${REPORT_ID_PARAM}`,
  reportTab: `/reports/${REPORT_ID_PARAM}/${REPORT_TAB_ID_PARAM}`,
  reportOverview: `/reports/${REPORT_ID_PARAM}/${ReportTabId.OVERVIEW}`,
  reportCategoryTab: `/reports/${REPORT_ID_PARAM}/${REPORT_TAB_ID_PARAM}/${REPORT_CATEGORY_TAB_ID_PARAM}`,
  reportPerformance: `/reports/${REPORT_ID_PARAM}/${ReportTabId.PERFORMANCE}`,
  reportPerformanceSummary: `/reports/${REPORT_ID_PARAM}/${ReportTabId.PERFORMANCE}/${ReportCategoryTabId.SUMMARY}`,
  reportPerformanceMetrics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.PERFORMANCE}/${ReportCategoryTabId.METRICS}`,
  reportConversion: `/reports/${REPORT_ID_PARAM}/${ReportTabId.CONVERSION}`,
  reportConversionSummary: `/reports/${REPORT_ID_PARAM}/${ReportTabId.CONVERSION}/${ReportCategoryTabId.SUMMARY}`,
  reportConversionMetrics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.CONVERSION}/${ReportCategoryTabId.METRICS}`,
  reportSeo: `/reports/${REPORT_ID_PARAM}/${ReportTabId.SEO}`,
  reportSeoSummary: `/reports/${REPORT_ID_PARAM}/${ReportTabId.SEO}/${ReportCategoryTabId.SUMMARY}`,
  reportSeoMetrics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.SEO}/${ReportCategoryTabId.METRICS}`,
  reportAccessibility: `/reports/${REPORT_ID_PARAM}/${ReportTabId.ACCESSIBILITY}`,
  reportAccessibilitySummary: `/reports/${REPORT_ID_PARAM}/${ReportTabId.ACCESSIBILITY}/${ReportCategoryTabId.SUMMARY}`,
  reportAccessibilityMetrics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.ACCESSIBILITY}/${ReportCategoryTabId.METRICS}`,
  reportBestPractices: `/reports/${REPORT_ID_PARAM}/${ReportTabId.BEST_PRACTICES}`,
  reportBestPracticesSummary: `/reports/${REPORT_ID_PARAM}/${ReportTabId.BEST_PRACTICES}/${ReportCategoryTabId.SUMMARY}`,
  reportBestPracticesMetrics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.BEST_PRACTICES}/${ReportCategoryTabId.METRICS}`,
  reportDiagnostics: `/reports/${REPORT_ID_PARAM}/${ReportTabId.DIAGNOSTICS}`,
  terms: '/terms',
  privacy: '/privacy',
}
