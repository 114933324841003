import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { MetricCategoryId, MetricDefinitionLinks, MetricId } from 'types'

import { CustomAnchorText } from '@/components/customAnchorText/CustomAnchorText'

import { OBFUSCATED_CLASS_NAMES } from '../constants'

type Props = ComponentProps<'div'> & {
  reportId: string
  metricCategoryId: MetricCategoryId | null // Can be null in the Overview tab
  metricId: MetricId
  links: MetricDefinitionLinks
  isObfuscated?: boolean
  isPaid: boolean | null
}

export const ReportMetricDetailsDialogLinks = ({
  className = '',
  reportId,
  metricCategoryId,
  metricId,
  links,
  isObfuscated,
  isPaid,
  ...props
}: Props) => {
  return (
    <div className={twMerge('flex flex-wrap justify-center gap-8', className)} {...props}>
      {links.map(link => (
        <CustomAnchorText
          key={link.url}
          className={isObfuscated ? OBFUSCATED_CLASS_NAMES : ''}
          variant={link.variant}
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          analytics={{ name: 'metric_details_link', reportId, metricCategoryId, metricId, label: link.label, isPaid }}
        >
          {link.label}
          {link.variant !== 'primary-button' && (
            <>
              {' '}
              <ArrowRightIcon className="h-4 w-4" />
            </>
          )}
        </CustomAnchorText>
      ))}
    </div>
  )
}
