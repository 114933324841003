import { httpsCallable, HttpsCallableOptions } from 'firebase/functions'
import { FunctionsMap } from 'types'

import { functions } from '..'

export const createCallableFunction = <T extends keyof FunctionsMap>(
  functionName: T,
  options?: HttpsCallableOptions,
) => {
  const functionRef = httpsCallable<FunctionsMap[T]['data'], FunctionsMap[T]['response']>(
    functions,
    functionName,
    options,
  )

  const createCallableFunction = async (data: FunctionsMap[T]['data']) => {
    const response = await functionRef(data)

    return response
  }

  return createCallableFunction
}
