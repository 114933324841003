import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MetricId } from 'types'

import { METRIC_ID_QUERY_PARAM } from '@/router/routes'

export const useReportNavigateToMetricDetails = () => {
  const { pathname, search } = useLocation()

  const navigate = useNavigate()

  const navigateToMetricDetails = useCallback(
    (metricId: MetricId) => {
      // Create a new URLSearchParams instance from the current search string
      const searchParams = new URLSearchParams(search)

      // Set the new query parameter or update if it already exists
      searchParams.set(METRIC_ID_QUERY_PARAM, metricId)

      // Navigate to the updated URL
      navigate(`${pathname}?${searchParams.toString()}`)
    },
    [navigate, pathname, search],
  )

  return navigateToMetricDetails
}
