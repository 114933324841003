import React from 'react'
import { MetricId } from 'types'

import { PageSection } from '@/components/pageSection/PageSection'
import { ReportHighlight } from '@/pages/report/components/ReportHighlight'
import { formatMetricValue } from '@/pages/report/utils/formatMetricValue'
import { useReport } from '@/reports/hooks/useReport'

// FIXME: SS Optimise rendering
export const ReportDiagnosticsKeyHighlights = () => {
  const { data, isLoading } = useReport()

  const report = data?.data.report
  const obfuscatedMetricIds = data?.data.obfuscatedMetricIds
  const isPaid = Boolean(report?.isPaid)
  const diagnostics = report?.metrics.diagnostics.details.items[0]
  const pageSize = diagnostics ? formatMetricValue(diagnostics.totalByteWeight, 'byte') : ''
  const processingTime = diagnostics ? formatMetricValue(diagnostics.totalTaskTime, 'millisecond') : ''
  const serverLatency = diagnostics ? formatMetricValue(diagnostics.maxServerLatency, 'millisecond') : ''
  const isObfuscated = Boolean(obfuscatedMetricIds?.includes(MetricId.DIAGNOSTICS))
  const defaultAnalyticsData = {
    reportId: report?.id || null,
    metricCategoryId: null,
    metricId: null,
    isPaid,
  }

  return (
    <PageSection title="Key Highlights">
      <div className="grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
        <ReportHighlight
          icon="📡"
          value={diagnostics?.numRequests || ''}
          label="No. Requests"
          analytics={{
            name: 'report_diagnostics_no_requests_upgrade',
            ...defaultAnalyticsData,
          }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />

        <ReportHighlight
          icon="🧑‍💻"
          value={diagnostics?.numScripts || ''}
          label="Scripts"
          analytics={{
            name: 'report_diagnostics_scripts_upgrade',
            ...defaultAnalyticsData,
          }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />

        <ReportHighlight
          icon="🎨"
          value={diagnostics?.numStylesheets || ''}
          label="Stylesheets"
          analytics={{
            name: 'report_diagnostics_stylesheets_upgrade',
            ...defaultAnalyticsData,
          }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />

        <ReportHighlight
          icon="🗂️"
          // FIXME: SS Cute hack to get the value and unit apart to avoid refactoring formatMetricValue
          value={pageSize ? pageSize.split(' ')[0] : ''}
          unit={pageSize ? pageSize.split(' ')[1] : ''}
          label="Page Size"
          analytics={{ name: 'report_diagnostics_page_size_upgrade', ...defaultAnalyticsData }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />

        <ReportHighlight
          icon="⏳"
          value={processingTime ? processingTime.split(' ')[0] : ''}
          unit={processingTime ? processingTime.split(' ')[1] : ''}
          label="Processing Time"
          analytics={{ name: 'report_diagnostics_processing_time_upgrade', ...defaultAnalyticsData }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />

        <ReportHighlight
          icon="🖥️"
          value={serverLatency ? serverLatency.split(' ')[0] : ''}
          unit={serverLatency ? serverLatency.split(' ')[1] : ''}
          label="Server Latency"
          analytics={{ name: 'report_diagnostics_server_latency_upgrade', ...defaultAnalyticsData }}
          isLoading={isLoading}
          isObfuscated={isObfuscated}
        />
      </div>
    </PageSection>
  )
}
