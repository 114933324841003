import React, { ComponentProps } from 'react'
import { getReportVsCompetitorScoresClassNames, getReportVsCompetitorScoresEmoji } from 'utils'

import { PageSection } from '../../../components/pageSection/PageSection'
import { ReportHighlight } from './ReportHighlight'

type Props = ComponentProps<typeof PageSection> & {
  issuesFound: number
  vsCompetitorScoreDifference: number
  lowHangingFruits: number // the number of issuesFound that are easy to fix
  isLoading: boolean
}

export const ReportKeyHighlights = ({
  issuesFound,
  vsCompetitorScoreDifference,
  lowHangingFruits,
  isLoading,
  ...props
}: Props) => {
  return (
    <PageSection title="Key Highlights" {...props}>
      <div className="grid w-full grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
        <ReportHighlight icon="🔎" value={issuesFound} label="Issues Found" isLoading={isLoading} />

        <ReportHighlight
          valueClassName={getReportVsCompetitorScoresClassNames(vsCompetitorScoreDifference)}
          icon={getReportVsCompetitorScoresEmoji(vsCompetitorScoreDifference)}
          value={Math.round(vsCompetitorScoreDifference * 100)}
          unit="%"
          label="vs Competitors"
          isLoading={isLoading}
        />

        <ReportHighlight icon="🍊" value={lowHangingFruits} label="Low Hanging Fruits" isLoading={isLoading} />
      </div>
    </PageSection>
  )
}
