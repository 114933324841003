import { z } from 'zod'

export const SystemConfigDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  productHuntForagerEnabled: z.boolean(),
  productHuntForagerLimit: z.number(),
  productHuntForagerScrolls: z.number(),
  productHuntUrlForagerEnabled: z.boolean(),
  productHuntUrlForagerLimit: z.number(),
  emailForagerEnabled: z.boolean(),
  emailForagerLimit: z.number(),
  juicerEnabled: z.boolean(),
  juicerLimit: z.number(),
  heraldEnabled: z.boolean(),
  heraldLimit: z.number(),
  heraldTestEmail: z.string(), // refer to https://resend.com/docs/dashboard/emails/send-test-emails#send-test-emails for some useful test emails
  notifications: z.object({
    reportViewed: z.boolean(),
    reportCheckoutInitiated: z.boolean(),
    reportPurchased: z.boolean(),
    emailUnsubscribed: z.boolean(),
    emailBounced: z.boolean(),
    emailReportedSpam: z.boolean(),
  }),
})
