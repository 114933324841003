import { z } from 'zod'

export const UrlDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  updatedAt: z.string().optional(),
  url: z.string(),
  status: z.enum([
    'pendingUrl', // initial state (url is empty), need to load the productHuntLink and click a button to get the loaded url
    'uselessServiceProvider', // we found a productHuntLink but it's a useless service provider that won't benefit from our service
    'failedToLoadUrl', // we failed to load the productHuntLink or click the button to get the loaded url
    'loadedUrl', // we managed to load the productHuntLink, click the button and get the loaded url
    'emailsFound', // we found emails in the loaded url and it's ready for analysis
    'emailsNotFound', // we didn't find any emails in the loaded url
    'analyzed', // we've analyzed the url
    'heralded', // we've heralded the url, ie. sent the email
    'delivered', // the email was delivered
    'deliveryDelayed', // the email was delayed
    'bounced', // the email bounced
    'complained', // the email was marked as spam
    'unsubscribed', // the user unsubscribed from the email
  ]),
  source: z.enum(['productHunt']),
  title: z.string(),
  description: z.string(),
  emails: z.array(z.string()).optional(), // added in emailsForager
  productHuntName: z.string(), // later these might be optional depending on the source
  productHuntDescription: z.string(),
  productHuntUpvotes: z.number(),
  productHuntLink: z.string(),
  resendEmailId: z.string().optional(),
})

export type UrlDocument = z.infer<typeof UrlDocumentSchema>
