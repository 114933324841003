import { Navbar, NavbarItem } from 'components'
import React, { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { MetricCategories, MetricCategoryId } from 'types'

import {
  REPORT_CATEGORY_TAB_ID_PARAM,
  REPORT_ID_PARAM,
  REPORT_TAB_ID_PARAM,
  ReportCategoryTabId,
  ReportTabId,
  routes,
} from '@/router/routes'

import { ReportLabel } from './ReportLabel'

const REPORT_CATEGORY_TABS: ReportTabId[] = [
  ReportTabId.PERFORMANCE,
  ReportTabId.CONVERSION,
  ReportTabId.SEO,
  ReportTabId.ACCESSIBILITY,
  ReportTabId.BEST_PRACTICES,
]

const tabs: Array<{
  id: ReportTabId
  label: string
  isNew?: boolean
}> = [
  {
    id: ReportTabId.OVERVIEW,
    label: 'ℹ️ Overview',
  },
  {
    id: ReportTabId.PERFORMANCE,
    label: `${MetricCategories[MetricCategoryId.PERFORMANCE].emoji} ${
      MetricCategories[MetricCategoryId.PERFORMANCE].title
    }`,
  },
  {
    id: ReportTabId.CONVERSION,
    label: `${MetricCategories[MetricCategoryId.CONVERSION].emoji} ${
      MetricCategories[MetricCategoryId.CONVERSION].title
    }`,
    isNew: true,
  },
  {
    id: ReportTabId.SEO,
    label: `${MetricCategories[MetricCategoryId.SEO].emoji} ${MetricCategories[MetricCategoryId.SEO].title}`,
  },
  {
    id: ReportTabId.ACCESSIBILITY,
    label: `${MetricCategories[MetricCategoryId.ACCESSIBILITY].emoji} ${
      MetricCategories[MetricCategoryId.ACCESSIBILITY].title
    }`,
  },
  {
    id: ReportTabId.BEST_PRACTICES,
    label: `${MetricCategories[MetricCategoryId.BEST_PRACTICES].emoji} ${
      MetricCategories[MetricCategoryId.BEST_PRACTICES].title
    }`,
  },
  {
    id: ReportTabId.DIAGNOSTICS,
    label: '⚙️ Diagnostics',
  },
]

export const ReportNavbar = () => {
  const { reportId = '' } = useParams()
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const onTabClick = useCallback(
    (tabId: ReportTabId) => {
      // If it's a category tab, we need to redirect to the summary tab for that category
      if (REPORT_CATEGORY_TABS.includes(tabId)) {
        const newRoute = routes.reportCategoryTab
          .replace(REPORT_ID_PARAM, reportId)
          .replace(REPORT_TAB_ID_PARAM, tabId)
          .replace(REPORT_CATEGORY_TAB_ID_PARAM, ReportCategoryTabId.SUMMARY)

        navigate(newRoute)

        return
      }

      const newRoute = routes.reportTab.replace(REPORT_ID_PARAM, reportId).replace(REPORT_TAB_ID_PARAM, tabId)

      navigate(newRoute)
    },
    [navigate, reportId],
  )

  return (
    <Navbar variant="lifted">
      {tabs.map(tab => (
        <NavbarItem key={tab.id} active={pathname.includes(tab.id)} onClick={() => onTabClick(tab.id)}>
          {tab.label}

          {tab.isNew && <ReportLabel>NEW</ReportLabel>}
        </NavbarItem>
      ))}
    </Navbar>
  )
}
