import { validateDocumentId } from 'utils'

import { createCallableFunction } from '@/firebase/utils/createCallableFunction'

const gatekeeperFunction = createCallableFunction('gatekeeper')

export const getReport = async ({ reportId, ...args }: Parameters<typeof gatekeeperFunction>[0]) => {
  validateDocumentId(reportId)

  return await gatekeeperFunction({ reportId, ...args })
}
