import { SCORE_THRESHOLDS } from 'types'

export const getReportScoreClassNames = (score?: number | null) => {
  if (score === null || score === undefined) {
    return ''
  }

  if (score >= SCORE_THRESHOLDS.SWEET) return 'text-success'

  if (score >= SCORE_THRESHOLDS.MEH) return 'text-warning'

  return 'text-error'
}
