export enum FirestoreCollection {
  Categories = 'categories',
  Customers = 'customers',
  EmailForagerQueue = 'emailForagerQueue',
  JuicerQueue = 'juicerQueue',
  Payments = 'payments',
  PickerQueue = 'pickerQueue',
  ProductHuntUrlForagerQueue = 'productHuntUrlForagerQueue',
  Prices = 'prices',
  Products = 'products',
  Reports = 'reports',
  SystemConfig = 'systemConfig',
  Testimonials = 'testimonials',
  Urls = 'urls',
}
