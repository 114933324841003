import React, { ComponentPropsWithoutRef, RefObject } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'h2'> & {
  forwardRef?: RefObject<HTMLHeadingElement>
}

export const TitleText = ({ forwardRef, className = '', children, ...props }: Props) => {
  return (
    <h2
      ref={forwardRef}
      className={twMerge('font-display text-lg uppercase tracking-wide text-base-content lg:text-xl', className)}
      {...props}
    >
      {children}
    </h2>
  )
}
