import { ReportTechStack } from 'types'

export const formatTechStack = (techStack: ReportTechStack) => {
  switch (techStack) {
    case 'react':
      return 'React'
    case 'next':
      return 'Next.js'
    case 'tailwindcss':
      return 'TailwindCSS'
    case 'remix':
      return 'Remix'
    case 'vue':
      return 'Vue'
    default:
      return techStack
  }
}
