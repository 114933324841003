import { DocumentDuplicateIcon } from '@heroicons/react/24/solid'
import React, { ComponentProps, useCallback } from 'react'
import toast from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button/Button'

type Props = ComponentProps<'div'> & {
  code: string
}

export const Code = ({ className = '', code, children, ...props }: Props) => {
  const onCopyCode = useCallback(() => {
    navigator.clipboard.writeText(code)

    toast.success('Copied to clipboard')
  }, [code])

  return (
    <div className="relative">
      <div className={twMerge('mockup-code relative shrink-0 ', className)} {...props}>
        {code.split('\n').map((line, index) => (
          <pre key={index}>{line}</pre>
        ))}
      </div>

      <div className="absolute right-0 top-0 flex items-center">
        {children}

        <Button variant="ghost" className="btn-circle" onClick={onCopyCode}>
          <DocumentDuplicateIcon className="h-4 w-4 text-neutral-content" />
        </Button>
      </div>
    </div>
  )
}
