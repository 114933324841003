import { ParagraphText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

import { PageSection } from '../../../components/pageSection/PageSection'
import { ReportCard } from './ReportCard'
import { ReportTestimonialStars } from './ReportTestimonialStars'
import { ReportUrl } from './ReportUrl'

type Props = ComponentProps<typeof PageSection> & {
  testimonial: {
    name: string
    url: string
    quote: string
    image: string
  } | null
  isLoading: boolean
} & Pick<ComponentProps<typeof ReportUrl>, 'analytics'>

export const ReportTestimonialSingle = ({ className = '', testimonial, analytics, isLoading, ...props }: Props) => {
  if (!isLoading && !testimonial) {
    return null
  }

  return (
    <>
      <PageSection className={twMerge('', className)} {...props}>
        <ReportCard className="w-full gap-y-8">
          <div className="flex w-full max-w-lg flex-col items-center gap-y-4">
            {isLoading ? (
              <div className="skeleton h-20 w-20 rounded-full" />
            ) : (
              <img className="h-20 w-20 rounded-full" src={testimonial?.image} alt={testimonial?.name} />
            )}

            {isLoading ? (
              <div className="skeleton h-[84px] w-full" />
            ) : (
              <ParagraphText className="max-w-lg text-center text-lg">“{testimonial?.quote}”</ParagraphText>
            )}

            <ReportTestimonialStars isLoading={isLoading} />
          </div>

          <div className="flex w-full flex-col items-center gap-y-2">
            {isLoading ? <div className="skeleton h-6 w-32" /> : <ParagraphText>{testimonial?.name}</ParagraphText>}

            {isLoading ? (
              <div className="skeleton h-6 w-32" />
            ) : testimonial?.url ? (
              <ReportUrl url={testimonial.url} analytics={analytics} />
            ) : null}
          </div>
        </ReportCard>
      </PageSection>
    </>
  )
}
