import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { formatUrl } from 'utils'

import { CustomAnchorText } from '../../../components/customAnchorText/CustomAnchorText'

type Props = ComponentProps<typeof CustomAnchorText> & {
  url: string
}

export const ReportUrl = ({ className, url, ...props }: Props) => {
  return (
    <CustomAnchorText {...props} className={twMerge('whitespace-nowrap', className)} href={url} target="_blank">
      {formatUrl(url)}
    </CustomAnchorText>
  )
}
