import { z } from 'zod'

import { MetricId, MetricIdSchema } from '../metrics'

/* HELPERS */

export const BaseAuditSchema = z.object({
  id: MetricIdSchema,
  score: z.number().nullable(),
  scoreDisplayMode: z.union([
    z.literal('binary'),
    z.literal('notApplicable'),
    z.literal('numeric'),
    z.literal('metricSavings'),
    z.literal('informative'),
    z.literal('manual'),
    z.literal('error'),
  ]),
  numericValue: z.number().nullable().optional(),
  numericUnit: z
    .union([z.literal('byte'), z.literal('millisecond'), z.literal('unitless'), z.literal('element')])
    .nullable()
    .optional(),
  details: z.object({}).optional(),
})

export const NodeAuditSchema = z.object({
  // we use this to get the position of the node for our screenshot capture
  boundingRect: z
    .object({
      top: z.number(),
      bottom: z.number(),
      left: z.number(),
      right: z.number(),
      width: z.number(),
      height: z.number(),
    })
    .optional(),
  // backup in case we can't find the element
  nodeLabel: z.string().optional(),
  // LH only - useful info to have
  explanation: z.string().optional(),
})

export const BaseAxeAuditSchema = BaseAuditSchema.extend({
  details: z
    .object({
      items: z.array(
        z.object({
          node: NodeAuditSchema,
        }),
      ),
    })
    .optional(),
})

export const SourceLocationAuditSchema = z.object({
  url: z.string(),
  urlProvider: z.union([z.literal('network'), z.literal('comment')]),
  line: z.number(),
  column: z.number(),
  original: z
    .object({
      file: z.string(),
      line: z.number(),
      column: z.number(),
    })
    .optional(),
})

/* AUDITS */

export const AccesskeysAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ACCESSKEYS),
})

export const AriaAllowedAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_ALLOWED_ATTR),
})

export const AriaAllowedRoleAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_ALLOWED_ROLE),
})

export const AriaCommandNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_COMMAND_NAME),
})

export const AriaConditionalAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_CONDITIONAL_ATTR),
})

export const AriaDeprecatedRoleAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_DEPRECATED_ROLE),
})

export const AriaDialogNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_DIALOG_NAME),
})

export const AriaHiddenBodyAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_HIDDEN_BODY),
})

export const AriaHiddenFocusAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_HIDDEN_FOCUS),
})

export const AriaInputFieldNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_INPUT_FIELD_NAME),
})

export const AriaMeterNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_METER_NAME),
})

export const AriaProgressbarNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_PROGRESSBAR_NAME),
})

export const AriaProhibitedAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_PROHIBITED_ATTR),
})

export const AriaRequiredAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_REQUIRED_ATTR),
})

export const AriaRequiredChildrenAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_REQUIRED_CHILDREN),
})

export const AriaRequiredParentAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_REQUIRED_PARENT),
})

export const AriaRolesAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_ROLES),
})

export const AriaTextAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.ARIA_TEXT),
})

export const AriaToggleFieldNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_TOGGLE_FIELD_NAME),
})

export const AriaTooltipNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_TOOLTIP_NAME),
})

export const AriaTreeitemNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_TREEITEM_NAME),
})

export const AriaValidAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_VALID_ATTR),
})

export const AriaValidAttrValueAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.ARIA_VALID_ATTR_VALUE),
})

export const BfCacheAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.BF_CACHE),
  details: z
    .object({
      items: z.array(
        z.object({
          reason: z.string(),
          failureType: z.string(),
          subItems: z.object({
            items: z.array(
              z.object({
                frameUrl: z.string(),
              }),
            ),
          }),
        }),
      ),
    })
    .optional(),
})

export const BootupTimeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.BOOTUP_TIME),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        total: z.number(),
        scripting: z.number(),
        scriptParseCompile: z.number(),
      }),
    ),
    summary: z.object({
      wastedMs: z.number(),
    }),
  }),
})

export const ButtonNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.BUTTON_NAME),
})

export const BypassAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.BYPASS),
})

export const CanonicalAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.CANONICAL),
  explanation: z.string().optional(),
})

export const CharsetAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.CHARSET),
})

export const ColorContrastAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.COLOR_CONTRAST),
})

export const CrawlableAnchorsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.CRAWLABLE_ANCHORS),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
      }),
    ),
  }),
})

export const CspXssAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.CSP_XSS),
  details: z.object({
    items: z.array(
      z.object({
        severity: z.string(),
        description: z.string(),
      }),
    ),
  }),
})

export const CumulativeLayoutShiftAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.CUMULATIVE_LAYOUT_SHIFT),
})

export const DefinitionListAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.DEFINITION_LIST),
})

export const DeprecationsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.DEPRECATIONS),
  details: z.object({
    items: z.array(
      z.object({
        value: z.string(),
        source: SourceLocationAuditSchema,
        subItems: z
          .object({
            items: z.array(
              z.object({
                url: z.string(),
                text: z.string(),
              }),
            ),
          })
          .optional(),
      }),
    ),
  }),
})

export const DiagnosticsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.DIAGNOSTICS),
  details: z.object({
    items: z.array(
      z.object({
        numRequests: z.number(),
        numScripts: z.number(),
        numStylesheets: z.number(),
        numFonts: z.number(),
        numTasks: z.number(),
        numTasksOver10ms: z.number(),
        numTasksOver25ms: z.number(),
        numTasksOver50ms: z.number(),
        numTasksOver100ms: z.number(),
        numTasksOver500ms: z.number(),
        rtt: z.number(),
        throughput: z.number(),
        maxRtt: z.number(),
        maxServerLatency: z.number(),
        totalByteWeight: z.number(),
        totalTaskTime: z.number(),
        mainDocumentTransferSize: z.number(),
      }),
    ),
  }),
})

export const DlitemAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.DLITEM),
})

export const DoctypeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.DOCTYPE),
  explanation: z.string().optional(),
})

export const DocumentTitleAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.DOCUMENT_TITLE),
})

export const DomSizeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.DOM_SIZE),
  details: z.object({
    items: z.array(
      z.object({
        statistic: z.string(),
        value: z.object({
          type: z.literal('numeric'),
          granularity: z.number(),
          value: z.number(),
        }),
        node: NodeAuditSchema.optional(),
      }),
    ),
  }),
})

export const DuplicatedJavaScriptAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.DUPLICATED_JAVASCRIPT),
  details: z.object({
    items: z.array(
      z.object({
        source: z.string(),
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
        subItems: z.object({
          items: z.array(
            z.object({
              url: z.string(),
              sourceTransferBytes: z.number().optional(),
            }),
          ),
        }),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const DuplicateIdAriaAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.DUPLICATE_ID_ARIA),
})

export const EfficientAnimatedContentAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.EFFICIENT_ANIMATED_CONTENT),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
  }),
})

export const EmptyHeadingAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.EMPTY_HEADING),
})

export const ErrorsInConsoleAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.ERRORS_IN_CONSOLE),
  details: z.object({
    items: z.array(
      z.object({
        source: z.string(),
        description: z.string(),
        sourceLocation: SourceLocationAuditSchema.optional(),
      }),
    ),
  }),
})

export const FirstContentfulPaintAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.FIRST_CONTENTFUL_PAINT),
})

export const FontDisplayAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.FONT_DISPLAY),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        wastedMs: z.number(),
      }),
    ),
  }),
})

export const FontSizeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.FONT_SIZE),
  details: z.object({
    items: z.array(
      z.object({
        source: z.object({
          type: z.string(),
          value: z.string().optional(),
        }),
        selector: z.union([z.string(), NodeAuditSchema]),
        coverage: z.string(),
        fontSize: z.string(),
      }),
    ),
  }),
})

export const FormFieldMultipleLabelsAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.FORM_FIELD_MULTIPLE_LABELS),
})

export const FrameTitleAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.FRAME_TITLE),
})

export const GeolocationOnStartAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.GEOLOCATION_ON_START),
  details: z.object({
    items: z.array(
      z.object({
        source: z.string(),
      }),
    ),
  }),
})

export const HeadingOrderAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.HEADING_ORDER),
})

export const HreflangAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.HREFLANG),
  details: z.object({
    items: z.array(
      z.object({
        source: z.union([
          NodeAuditSchema,
          z.object({
            type: z.literal('node'),
            snippet: z.string(),
          }),
          z.string(),
        ]),
        subItems: z.object({
          items: z.array(
            z.object({
              reason: z.string(),
            }),
          ),
        }),
      }),
    ),
  }),
})

export const HtmlHasLangAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.HTML_HAS_LANG),
})

export const HtmlLangValidAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.HTML_LANG_VALID),
})

export const HtmlXmlLangMismatchAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.HTML_XML_LANG_MISMATCH),
})

export const HttpStatusCodeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.HTTP_STATUS_CODE),
  displayValue: z.string().optional(),
})

export const IdenticalLinksSamePurposeAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.IDENTICAL_LINKS_SAME_PURPOSE),
})

export const ImageAltAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.IMAGE_ALT),
})

export const ImageAspectRatioAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.IMAGE_ASPECT_RATIO),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
        url: z.string(),
        displayedAspectRatio: z.string(),
        actualAspectRatio: z.string(),
        doRatiosMatch: z.boolean(),
      }),
    ),
  }),
})

export const ImageRedundantAltAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.IMAGE_REDUNDANT_ALT),
})

export const ImageSizeResponsiveAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.IMAGE_SIZE_RESPONSIVE),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        displayedSize: z.string(),
        actualSize: z.string(),
        expectedSize: z.string(),
        actualPixels: z.number(),
        expectedPixels: z.number(),
      }),
    ),
  }),
})

export const InputButtonNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.INPUT_BUTTON_NAME),
})

export const InputImageAltAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.INPUT_IMAGE_ALT),
})

export const InspectorIssuesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.INSPECTOR_ISSUES),
  details: z.object({
    items: z.array(
      z.object({
        issueType: z.string(),
        subItems: z.object({
          items: z.array(
            z.object({
              url: z.string(),
            }),
          ),
        }),
      }),
    ),
  }),
})

export const InteractiveAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.INTERACTIVE),
})

export const IsCrawlableAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.IS_CRAWLABLE),
  details: z.object({
    items: z.array(
      z.object({
        source: SourceLocationAuditSchema,
      }),
    ),
  }),
})

export const IsOnHttpsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.IS_ON_HTTPS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
      }),
    ),
  }),
})

export const LabelAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LABEL),
})

export const LabelContentNameMismatchAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LABEL_CONTENT_NAME_MISMATCH),
})

export const LandmarkOneMainAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LANDMARK_ONE_MAIN),
})

export const LargestContentfulPaintAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LARGEST_CONTENTFUL_PAINT),
})

export const LayoutShiftsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LAYOUT_SHIFTS),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema.optional(),
        score: z.number(),
        subItems: z
          .object({
            items: z.array(
              z.object({
                extra: z
                  .object({
                    type: z.string(),
                    value: z.string().optional(),
                  })
                  .optional(),
                cause: z.string(),
              }),
            ),
          })
          .optional(),
      }),
    ),
  }),
})

export const LCPLazyLoadedAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LCP_LAZY_LOADED),
  details: z
    .object({
      items: z.array(
        z.object({
          node: NodeAuditSchema,
        }),
      ),
    })
    .optional(),
})

export const LegacyJavaScriptAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LEGACY_JAVASCRIPT),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
        subItems: z.object({
          items: z.array(
            z.object({
              signal: z.string(),
              location: SourceLocationAuditSchema,
            }),
          ),
        }),
      }),
    ),
  }),
})

export const LinkInTextBlockAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LINK_IN_TEXT_BLOCK),
})

export const LinkNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LINK_NAME),
})

export const LinkTextAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LINK_TEXT),
  details: z.object({
    items: z.array(
      z.object({
        href: z.string(),
        text: z.string(),
      }),
    ),
  }),
})

export const ListAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LIST),
})

export const ListitemAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.LISTITEM),
})

export const LongTasksAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.LONG_TASKS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        duration: z.number(),
        startTime: z.number(),
      }),
    ),
  }),
})

export const MainThreadWorkBreakdownAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.MAIN_THREAD_WORK_BREAKDOWN),
  details: z.object({
    items: z.array(
      z.object({
        group: z.string(),
        groupLabel: z.string(),
        duration: z.number(),
      }),
    ),
  }),
})

export const MaxPotentialFIDAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.MAX_POTENTIAL_FID),
})

export const MetaDescriptionAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.META_DESCRIPTION),
  explanation: z.string().optional(),
})

export const MetaRefreshAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.META_REFRESH),
})

export const MetaViewportAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.META_VIEWPORT),
})

export const ModernImageFormatsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.MODERN_IMAGE_FORMATS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
        wastedWebpBytes: z.number(),
        fromProtocol: z.boolean(),
        isCrossOrigin: z.boolean(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const NetworkRequestsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NETWORK_REQUESTS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        sessionTargetType: z.string(),
        protocol: z.string(),
        rendererStartTime: z.number(),
        networkRequestTime: z.number(),
        networkEndTime: z.number().optional(), // FIXME: SS why is this optional?
        finished: z.boolean(),
        transferSize: z.number(),
        resourceSize: z.number(),
        statusCode: z.number(),
        mimeType: z.string(),
        resourceType: z.string().optional(), // FIXME: SS why is this optional?
        priority: z.string(),
        entity: z.string().optional(), // FIXME: SS why is this optional?
      }),
    ),
  }),
})

export const NetworkRttAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NETWORK_RTT),
  details: z.object({
    items: z.array(
      z.object({
        origin: z.string(),
        rtt: z.number(),
      }),
    ),
  }),
})

export const NetworkServerLatencyAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NETWORK_SERVER_LATENCY),
  details: z.object({
    items: z.array(
      z.object({
        origin: z.string(),
        serverResponseTime: z.number(),
      }),
    ),
  }),
})

export const NoDocumentWriteAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NO_DOCUMENT_WRITE),
  details: z.object({
    items: z.array(
      z.object({
        source: SourceLocationAuditSchema,
      }),
    ),
  }),
})

export const NonCompositedAnimationsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NON_COMPOSITED_ANIMATIONS),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
        subItems: z.object({
          items: z.array(
            z.object({
              failureReason: z.string(),
            }),
          ),
        }),
      }),
    ),
  }),
})

export const NotificationOnStartAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.NOTIFICATION_ON_START),
  details: z.object({
    items: z.array(
      z.object({
        source: z.string(),
      }),
    ),
  }),
})

export const ObjectAltAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.OBJECT_ALT),
})

export const OffscreenImagesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.OFFSCREEN_IMAGES),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const PastePreventingInputsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.PASTE_PREVENTING_INPUTS),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
        type: z.string(),
      }),
    ),
  }),
})

export const PrioritizeLCPImageAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.PRIORITIZE_LCP_IMAGE),
  score: z.number().nullable(),
  scoreDisplayMode: z.union([z.literal('metricSavings'), z.literal('notApplicable')]),
  numericValue: z.number().optional(),
  numericUnit: z.literal('millisecond').optional(),
  details: z
    .object({
      items: z.array(
        z.object({
          node: NodeAuditSchema,
          url: z.string(),
          wastedMs: z.number(),
        }),
      ),
    })
    .optional(),
})

export const RedirectsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.REDIRECTS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        wastedMs: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
  }),
})

export const RedirectsHttpAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.REDIRECTS_HTTP),
})

export const RenderBlockingResourcesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.RENDER_BLOCKING_RESOURCES),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedMs: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
  }),
})

export const RobotsTxtAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.ROBOTS_TXT),
  details: z
    .object({
      items: z.array(
        z.object({
          index: z.string(),
          line: z.string(),
          message: z.string(),
        }),
      ),
    })
    .optional(),
})

export const SelectNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.SELECT_NAME),
})

export const ServerResponseTimeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.SERVER_RESPONSE_TIME),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        responseTime: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
  }),
})

export const SkipLinkAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.SKIP_LINK),
})

export const SpeedIndexAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.SPEED_INDEX),
})

export const TabindexAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TABINDEX),
})

export const TableDuplicateNameAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TABLE_DUPLICATE_NAME),
})

export const TableFakeCaptionAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TABLE_FAKE_CAPTION),
})

export const TargetSizeAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TARGET_SIZE),
})

export const TdHasHeaderAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TD_HAS_HEADER),
})

export const TdHeadersAttrAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TD_HEADERS_ATTR),
})

export const ThHasDataCellsAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.TH_HAS_DATA_CELLS),
})

export const ThirdPartyCookiesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.THIRD_PARTY_COOKIES),
  details: z.object({
    items: z.array(
      z.object({
        name: z.string(),
        url: z.string(),
      }),
    ),
  }),
})

export const ThirdPartyFacadesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.THIRD_PARTY_FACADES),
  details: z
    .object({
      items: z.array(
        z.object({
          product: z.string(),
          transferSize: z.number(),
          blockingTime: z.number(),
          subItems: z.object({
            items: z.array(
              z.object({
                url: z.string(),
                mainThreadTime: z.number(),
                transferSize: z.number(),
                blockingTime: z.number(),
                tbtImpact: z.number(),
              }),
            ),
          }),
        }),
      ),
    })
    .optional(),
})

export const ThirdPartySummaryAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.THIRD_PARTY_SUMMARY),
  details: z
    .object({
      items: z.array(
        z.object({
          entity: z.string(),
          mainThreadTime: z.number(),
          transferSize: z.number(),
          blockingTime: z.number(),
          tbtImpact: z.number(),
          subItems: z.object({
            items: z.array(
              z.object({
                url: z.string(),
                mainThreadTime: z.number(),
                transferSize: z.number(),
                blockingTime: z.number(),
                tbtImpact: z.number(),
              }),
            ),
          }),
        }),
      ),
      summary: z
        .object({
          wastedBytes: z.number(),
          wastedMs: z.number(),
        })
        .optional(),
    })
    .optional(),
})

export const TotalBlockingTimeAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.TOTAL_BLOCKING_TIME),
})

export const TotalByteWeightAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.TOTAL_BYTE_WEIGHT),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
      }),
    ),
  }),
})

export const UnminifiedCssAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.UNMINIFIED_CSS),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UnminifiedJavaScriptAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.UNMINIFIED_JAVASCRIPT),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UnusedCssRulesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.UNUSED_CSS_RULES),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UnsizedImagesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.UNSIZED_IMAGES),
  details: z.object({
    items: z.array(
      z.object({
        node: NodeAuditSchema,
        url: z.string(),
      }),
    ),
  }),
})

export const UnusedJavaScriptAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.UNUSED_JAVASCRIPT),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
        subItems: z
          .object({
            items: z.array(
              z.object({
                source: z.string(),
                sourceBytes: z.number(),
                sourceWastedBytes: z.number(),
              }),
            ),
          })
          .optional(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UsesHttp2AuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_HTTP2),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        protocol: z.string(),
      }),
    ),
    overallSavingsMs: z.number(),
  }),
})

export const UsesLongCacheTtlAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_LONG_CACHE_TTL),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        cacheLifetimeMs: z.number(),
        cacheHitProbability: z.number(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
  }),
})

export const UsesOptimizedImagesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_OPTIMIZED_IMAGES),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UsesPassiveEventListenersAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_PASSIVE_EVENT_LISTENERS),
  details: z.object({
    items: z.array(
      z.object({
        source: SourceLocationAuditSchema,
      }),
    ),
  }),
})

export const UsesRelPreconnectAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_REL_PRECONNECT),
  details: z
    .object({
      items: z.array(
        z.object({
          url: z.string(),
          wastedMs: z.number(),
        }),
      ),
      overallSavingsMs: z.number(),
    })
    .optional(),
  warnings: z.array(z.string()).optional(),
})

export const UsesResponsiveImagesAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_RESPONSIVE_IMAGES),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
    overallSavingsMs: z.number(),
    overallSavingsBytes: z.number(),
  }),
})

export const UsesTextCompressionAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.USES_TEXT_COMPRESSION),
  details: z.object({
    items: z.array(
      z.object({
        url: z.string(),
        totalBytes: z.number(),
        wastedBytes: z.number(),
      }),
    ),
  }),
})

export const ValidLangAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.VALID_LANG),
})

export const ValidSourceMapsAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.VALID_SOURCE_MAPS),
  details: z.object({
    items: z.array(
      z.object({
        scriptUrl: z.string(),
        sourceMapUrl: z.string().optional(),
        subItems: z.object({
          items: z.array(
            z.object({
              error: z.string(),
            }),
          ),
        }),
      }),
    ),
  }),
})

export const VideoCaptionAuditSchema = BaseAxeAuditSchema.extend({
  id: z.literal(MetricId.VIDEO_CAPTION),
})

export const ViewportAuditSchema = BaseAuditSchema.extend({
  id: z.literal(MetricId.VIEWPORT),
})
