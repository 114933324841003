import { z } from 'zod'

export enum MetricId {
  ACCESSKEYS = 'accesskeys',
  ARIA_ALLOWED_ATTR = 'aria-allowed-attr',
  ARIA_ALLOWED_ROLE = 'aria-allowed-role',
  ARIA_COMMAND_NAME = 'aria-command-name',
  ARIA_CONDITIONAL_ATTR = 'aria-conditional-attr',
  ARIA_DEPRECATED_ROLE = 'aria-deprecated-role',
  ARIA_DIALOG_NAME = 'aria-dialog-name',
  ARIA_HIDDEN_BODY = 'aria-hidden-body',
  ARIA_HIDDEN_FOCUS = 'aria-hidden-focus',
  ARIA_INPUT_FIELD_NAME = 'aria-input-field-name',
  ARIA_METER_NAME = 'aria-meter-name',
  ARIA_PROGRESSBAR_NAME = 'aria-progressbar-name',
  ARIA_PROHIBITED_ATTR = 'aria-prohibited-attr',
  ARIA_REQUIRED_ATTR = 'aria-required-attr',
  ARIA_REQUIRED_CHILDREN = 'aria-required-children',
  ARIA_REQUIRED_PARENT = 'aria-required-parent',
  ARIA_ROLES = 'aria-roles',
  ARIA_TEXT = 'aria-text',
  ARIA_TOGGLE_FIELD_NAME = 'aria-toggle-field-name',
  ARIA_TOOLTIP_NAME = 'aria-tooltip-name',
  ARIA_TREEITEM_NAME = 'aria-treeitem-name',
  ARIA_VALID_ATTR = 'aria-valid-attr',
  ARIA_VALID_ATTR_VALUE = 'aria-valid-attr-value',
  BF_CACHE = 'bf-cache',
  BOOTUP_TIME = 'bootup-time',
  BUTTON_NAME = 'button-name',
  BYPASS = 'bypass',
  CANONICAL = 'canonical',
  CHARSET = 'charset',
  COLOR_CONTRAST = 'color-contrast',
  CRAWLABLE_ANCHORS = 'crawlable-anchors',
  CRITICAL_REQUEST_CHAINS = 'critical-request-chains',
  CSP_XSS = 'csp-xss',
  CUMULATIVE_LAYOUT_SHIFT = 'cumulative-layout-shift',
  DEFINITION_LIST = 'definition-list',
  DEPRECATIONS = 'deprecations',
  DIAGNOSTICS = 'diagnostics',
  DLITEM = 'dlitem',
  DOCUMENT_TITLE = 'document-title',
  DOCTYPE = 'doctype',
  DOM_SIZE = 'dom-size',
  DUPLICATE_ID_ARIA = 'duplicate-id-aria',
  DUPLICATED_JAVASCRIPT = 'duplicated-javascript',
  EFFICIENT_ANIMATED_CONTENT = 'efficient-animated-content',
  EMPTY_HEADING = 'empty-heading',
  ERRORS_IN_CONSOLE = 'errors-in-console',
  FIRST_CONTENTFUL_PAINT = 'first-contentful-paint',
  FONT_DISPLAY = 'font-display',
  FONT_SIZE = 'font-size',
  FORM_FIELD_MULTIPLE_LABELS = 'form-field-multiple-labels',
  FRAME_TITLE = 'frame-title',
  GEOLOCATION_ON_START = 'geolocation-on-start',
  HEADING_ORDER = 'heading-order',
  HREFLANG = 'hreflang',
  HTML_HAS_LANG = 'html-has-lang',
  HTML_LANG_VALID = 'html-lang-valid',
  HTML_XML_LANG_MISMATCH = 'html-xml-lang-mismatch',
  HTTP_STATUS_CODE = 'http-status-code',
  IDENTICAL_LINKS_SAME_PURPOSE = 'identical-links-same-purpose',
  IMAGE_ALT = 'image-alt',
  IMAGE_ASPECT_RATIO = 'image-aspect-ratio',
  IMAGE_REDUNDANT_ALT = 'image-redundant-alt',
  IMAGE_SIZE_RESPONSIVE = 'image-size-responsive',
  INPUT_BUTTON_NAME = 'input-button-name',
  INPUT_IMAGE_ALT = 'input-image-alt',
  INSPECTOR_ISSUES = 'inspector-issues',
  INTERACTIVE = 'interactive',
  IS_CRAWLABLE = 'is-crawlable',
  IS_ON_HTTPS = 'is-on-https',
  LABEL = 'label',
  LABEL_CONTENT_NAME_MISMATCH = 'label-content-name-mismatch',
  LANDMARK_ONE_MAIN = 'landmark-one-main',
  LARGEST_CONTENTFUL_PAINT = 'largest-contentful-paint',
  LAYOUT_SHIFTS = 'layout-shifts',
  LCP_LAZY_LOADED = 'lcp-lazy-loaded',
  LEGACY_JAVASCRIPT = 'legacy-javascript',
  LINK_IN_TEXT_BLOCK = 'link-in-text-block',
  LINK_NAME = 'link-name',
  LINK_TEXT = 'link-text',
  LIST = 'list',
  LISTITEM = 'listitem',
  LONG_TASKS = 'long-tasks',
  MAIN_THREAD_WORK_BREAKDOWN = 'mainthread-work-breakdown',
  MAX_POTENTIAL_FID = 'max-potential-fid',
  META_DESCRIPTION = 'meta-description',
  META_REFRESH = 'meta-refresh',
  META_VIEWPORT = 'meta-viewport',
  MODERN_IMAGE_FORMATS = 'modern-image-formats',
  NETWORK_REQUESTS = 'network-requests',
  NETWORK_RTT = 'network-rtt',
  NETWORK_SERVER_LATENCY = 'network-server-latency',
  NO_DOCUMENT_WRITE = 'no-document-write',
  NON_COMPOSITED_ANIMATIONS = 'non-composited-animations',
  NOTIFICATION_ON_START = 'notification-on-start',
  OBJECT_ALT = 'object-alt',
  OFFSCREEN_IMAGES = 'offscreen-images',
  PASTE_PREVENTING_INPUTS = 'paste-preventing-inputs',
  PRIORITIZE_LCP_IMAGE = 'prioritize-lcp-image',
  REDIRECTS = 'redirects',
  REDIRECTS_HTTP = 'redirects-http',
  RENDER_BLOCKING_RESOURCES = 'render-blocking-resources',
  ROBOTS_TXT = 'robots-txt',
  SELECT_NAME = 'select-name',
  SERVER_RESPONSE_TIME = 'server-response-time',
  SKIP_LINK = 'skip-link',
  SPEED_INDEX = 'speed-index',
  TABINDEX = 'tabindex',
  TABLE_DUPLICATE_NAME = 'table-duplicate-name',
  TABLE_FAKE_CAPTION = 'table-fake-caption',
  TARGET_SIZE = 'target-size',
  TD_HAS_HEADER = 'td-has-header',
  TD_HEADERS_ATTR = 'td-headers-attr',
  TH_HAS_DATA_CELLS = 'th-has-data-cells',
  THIRD_PARTY_COOKIES = 'third-party-cookies',
  THIRD_PARTY_FACADES = 'third-party-facades',
  THIRD_PARTY_SUMMARY = 'third-party-summary',
  TOTAL_BLOCKING_TIME = 'total-blocking-time',
  TOTAL_BYTE_WEIGHT = 'total-byte-weight',
  UNMINIFIED_CSS = 'unminified-css',
  UNMINIFIED_JAVASCRIPT = 'unminified-javascript',
  UNUSED_CSS_RULES = 'unused-css-rules',
  UNSIZED_IMAGES = 'unsized-images',
  UNUSED_JAVASCRIPT = 'unused-javascript',
  USES_HTTP2 = 'uses-http2',
  USES_LONG_CACHE_TTL = 'uses-long-cache-ttl',
  USES_OPTIMIZED_IMAGES = 'uses-optimized-images',
  USES_PASSIVE_EVENT_LISTENERS = 'uses-passive-event-listeners',
  USES_REL_PRECONNECT = 'uses-rel-preconnect',
  USES_RESPONSIVE_IMAGES = 'uses-responsive-images',
  USES_TEXT_COMPRESSION = 'uses-text-compression',
  VALID_LANG = 'valid-lang',
  VALID_SOURCE_MAPS = 'valid-source-maps',
  VIDEO_CAPTION = 'video-caption',
  VIEWPORT = 'viewport',

  // custom metrics
  // cta metrics
  CTA_VISIBLE = 'cta-visible',
  CTA_QUALITY = 'cta-quality',
  CTA_LENGTH = 'cta-length',
  // form metrics
  FORM_FIELD_COUNT = 'form-field-count',
  // keyword metrics
  KEYWORD_OPTIMIZATION = 'keyword-optimization',
  // social proof metrics
  SOCIAL_PROOF = 'social-proof',
  // ux metrics
  LINE_SPACING = 'line-spacing',
  // meta metrics
  META_TITLE_LENGTH = 'meta-title-length',
  META_DESCRIPTION_LENGTH = 'meta-description-length',
}

export const MetricIdSchema = z.nativeEnum(MetricId)

/*

Skipped audits:

FIRST_MEANINGFUL_PAINT = 'first-meaningful-paint', // deprecated
METRICS = 'metrics', // can ignore, it's just a dump of all the other metrics
RESOURCE_SUMMARY = 'resource-summary', // can ignore, it's just a dump of all the other metrics
SCRIPT_TREEMAP_DATA = 'script-treemap-data', // can ignore, used for treemap app
USER_TIMINGS = 'user-timings', // can ignore, not necessary for our report
JS_LIBRARIES = 'js-libraries', // not working at all
LARGEST_CONTENTFUL_PAINT_ELEMENT = 'largest-contentful-paint-element', // schema too complex, couldn't get it right
MAIN_THREAD_TASKS = 'main-thread-tasks', // doesn't really give us anything

Skipped manual audits:

CUSTOM_CONTROLS_LABELS = 'custom-controls-labels',
CUSTOM_CONTROLS_ROLES = 'custom-controls-roles',
FOCUS_TRAPS = 'focus-traps',
FOCUSABLE_CONTROLS = 'focusable-controls',
INTERACTIVE_ELEMENT_AFFORDANCE = 'interactive-element-affordance',
LOGICAL_TAB_ORDER = 'logical-tab-order',
MANAGED_FOCUS = 'managed-focus',
OFFSCREEN_CONTENT_HIDDEN = 'offscreen-content-hidden',
USE_LANDMARKS = 'use-landmarks',
VISUAL_ORDER_FOLLOWS_DOM = 'visual-order-follows-dom',
STRUCTURED_DATA = 'structured-data',

*/

type ValueType = 'node' | 'text' | 'link' | 'unitless' | 'bytes' | 'milliseconds' | 'array' | 'boolean'

type ColumnConfig = {
  label?: string
  key: string
  subKey?: string
  type: ValueType
  colSpan?: number
}

export type MetricDefinitionTableConfig = {
  title: string
  columns: ColumnConfig[]
  subItemColumns?: ColumnConfig[]
}

export type MetricDefinitionLinks = Array<{
  label: string
  url: string
  variant: 'primary' | 'primary-button' | 'neutral'
}>

export const Metrics: Record<
  MetricId,
  {
    title: string
    description: string
    links?: MetricDefinitionLinks
    solution?: {
      title: string
      description: string
      additionalTips?: string[]
      fixability: 'easy' | 'medium' | 'hard'
      links?: MetricDefinitionLinks // helpful links relevant to the solution, e.g. an image resizing service
      codeSnippets?: {
        generic?: string
        react?: string
        next?: string
        remix?: string
        vue?: string
        tailwindcss?: string
        shell?: string
      }
    }
    tableConfig?: MetricDefinitionTableConfig
    hidden?: boolean
    isCompositeMetric?: boolean
  }
> = {
  [MetricId.ACCESSKEYS]: {
    title: 'Access Keys',
    description:
      'Evaluates whether access keys are effectively implemented to enhance keyboard navigation and accessibility.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/accesskeys',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Unique and Relevant Access Keys',
      description:
        'Assign access keys to key interactive elements to streamline keyboard navigation for users. Avoid setting conflicting access keys within the same page.',
      additionalTips: [
        'Ensure each access key is unique on the page to avoid conflicts.',
        "Choose intuitive keys that correspond with the primary function of the element (e.g., 'N' for next, 'S' for search).",
        'Document assigned access keys for users if they aren’t common conventions.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<a href='#' accesskey='n'>Next Page</a>",
      },
    },
    tableConfig: {
      title: 'The following elements have issues with access key usage:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_ALLOWED_ATTR]: {
    title: 'ARIA Attributes',
    description: 'Checks for valid ARIA attributes used on HTML elements to ensure accessibility compliance.',
    links: [
      {
        label: 'Deque AXE Documentation on ARIA Allowed Attributes',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-allowed-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Valid ARIA Attributes',
      description:
        'Ensure ARIA attributes are applied only to elements that support them. Incorrect usage can cause accessibility issues by confusing assistive technologies.',
      fixability: 'easy',
      additionalTips: [
        'Verify that ARIA attributes align with element roles to avoid role conflicts.',
        'Avoid unnecessary ARIA attributes on elements where they don’t enhance accessibility, and rely on semantic HTML first.',
        'Use tools like AXE or Lighthouse to audit ARIA attribute usage and identify any invalid applications.',
      ],
      codeSnippets: {
        generic: "<button aria-label='Close'>X</button>",
      },
    },
    tableConfig: {
      title: 'The following elements have invalid ARIA attributes:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_ALLOWED_ROLE]: {
    title: 'ARIA Roles',
    description:
      'Ensures that ARIA roles are used on compatible elements, preventing accessibility issues from unsupported role usage.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-allowed-role',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Apply ARIA Roles Correctly',
      description:
        'Use ARIA roles only on elements that support them. Avoid using roles that are incompatible with an element’s native semantics.',
      additionalTips: [
        "Match ARIA roles with element types (e.g., use `role='button'` on interactive elements like buttons).",
        'Avoid redefining roles on elements that already have a clear semantic role.',
        'Refer to ARIA role definitions to understand appropriate element applications.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='alert'>Important message!</div>",
      },
    },
    tableConfig: {
      title: 'The following elements have invalid ARIA roles:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_COMMAND_NAME]: {
    title: 'ARIA Command Names',
    description:
      'Ensures that interactive elements using ARIA command roles (e.g., button, checkbox) have accessible names.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-command-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Commands',
      description:
        'Ensure all elements with command roles (like `button` or `checkbox`) have accessible names to aid screen readers.',
      additionalTips: [
        'Use `aria-label` or `aria-labelledby` to add accessible names to interactive elements.',
        'Avoid using duplicate labels for commands performing different actions.',
        'Test with a screen reader to confirm that the commands are accurately conveyed.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<button aria-label='Submit'>Submit</button>",
      },
    },
    tableConfig: {
      title: 'The following commands lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_CONDITIONAL_ATTR]: {
    title: 'ARIA Conditional Attributes',
    description:
      'Checks that conditional ARIA attributes (like `aria-expanded`, `aria-pressed`) are used only on elements where they are relevant.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-conditional-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Conditional ARIA Attributes Correctly',
      description:
        'Apply conditional ARIA attributes only when their associated state changes dynamically, such as `aria-expanded` on dropdowns.',
      additionalTips: [
        'Use `aria-expanded` on elements like buttons that control collapsible regions.',
        'Avoid setting attributes like `aria-pressed` on non-interactive elements.',
        'Update ARIA attributes in sync with UI changes to keep assistive technology informed.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<button aria-expanded='true'>Menu</button>",
      },
    },
    tableConfig: {
      title: 'The following elements have improper conditional ARIA attributes:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_DEPRECATED_ROLE]: {
    title: 'ARIA Deprecated Roles',
    description:
      'Identifies any use of deprecated ARIA roles, which can impact accessibility and compatibility with assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-deprecated-role',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Replace Deprecated ARIA Roles',
      description:
        'Remove or replace deprecated ARIA roles to ensure compatibility and accessibility. Use standard roles that are supported by modern assistive technologies.',
      additionalTips: [
        'Refer to current ARIA role specifications to confirm valid roles.',
        'Consider replacing deprecated roles with native HTML elements where possible.',
        'Verify with a screen reader to ensure updated roles communicate the intended function.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "<!-- Deprecated -->\n<div role='presentation'></div>\n\n<!-- Recommended -->\n<div aria-hidden='true'></div>",
      },
    },
    tableConfig: {
      title: 'The following elements use deprecated ARIA roles:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_DIALOG_NAME]: {
    title: 'ARIA Dialog Name',
    description:
      'Ensures that dialog elements (e.g., modals) have accessible names to help screen readers and other assistive technologies convey context to users.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-dialog-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Dialogs',
      description:
        'Assign accessible names to dialog elements to provide context to users. Use `aria-label` to clearly describe the dialog’s purpose.',
      additionalTips: [
        "Use `aria-label` with a brief, descriptive name for the dialog (e.g., 'Settings' or 'Confirmation').",
        'Ensure the label is unique to each dialog to avoid confusion.',
        'Confirm that the label accurately reflects the function of the dialog.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='dialog' aria-label='Settings'>\n  <!-- Dialog content -->\n</div>",
      },
    },
    tableConfig: {
      title: 'The following dialogs lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_HIDDEN_BODY]: {
    title: 'ARIA Hidden Body',
    description:
      'Checks if the `aria-hidden` attribute is mistakenly applied to the `<body>` element, which can prevent assistive technologies from accessing the page content.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-hidden-body',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove `aria-hidden` from `<body>`',
      description:
        'Avoid using the `aria-hidden` attribute on the `<body>` element as it can make all content inaccessible to screen readers. Instead, apply `aria-hidden` to individual elements as needed.',
      additionalTips: [
        'Use `aria-hidden` only on specific elements that should be hidden from assistive technology, such as decorative icons.',
        'Confirm that important content remains accessible and is not inadvertently hidden.',
        'If hiding content conditionally, dynamically update `aria-hidden` to reflect visibility changes.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<!-- Incorrect -->\n<body aria-hidden='true'>\n  <!-- Page content -->\n</body>\n\n<!-- Correct -->\n<body>\n  <div aria-hidden='true'>Decorative icon</div>\n</body>",
      },
    },
    tableConfig: {
      title: 'The following elements improperly apply `aria-hidden` to `<body>`:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_HIDDEN_FOCUS]: {
    title: 'ARIA Hidden Focus',
    description:
      'Ensures that elements with keyboard focus are not hidden with `aria-hidden`, as this can confuse users who rely on assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-hidden-focus',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Avoid Focusing on `aria-hidden` Elements',
      description:
        'Ensure that elements with keyboard focus are not hidden using `aria-hidden`. Focus should only be on elements visible to all users, including those using screen readers.',
      additionalTips: [
        'Remove `aria-hidden` from any element that is focusable.',
        'If hiding an element dynamically, remove focus from it before applying `aria-hidden`.',
        'Test keyboard navigation to confirm that focusable elements are accessible and visible.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "<!-- Incorrect -->\n<div aria-hidden='true' tabindex='0'>Hidden but focusable</div>\n\n<!-- Correct -->\n<div tabindex='-1'>Not focusable when hidden</div>",
      },
    },
    tableConfig: {
      title: 'The following elements have focus but are hidden with `aria-hidden`:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_INPUT_FIELD_NAME]: {
    title: 'ARIA Input Field Name',
    description:
      'Ensures that input fields have accessible names, making them identifiable to users relying on assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-input-field-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Provide Accessible Names for Input Fields',
      description:
        'Assign accessible names to all input fields using methods like `aria-label` or `aria-labelledby` to improve accessibility.',
      additionalTips: [
        'Use `aria-label` for brief names or `aria-labelledby` to reference a visible label.',
        'Avoid leaving input fields without a label or accessible name.',
        "Confirm that each input field’s name accurately describes its purpose (e.g., 'Email address').",
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<input type='text' aria-label='Email address' />",
      },
    },
    tableConfig: {
      title: 'The following input fields lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_METER_NAME]: {
    title: 'ARIA Meter Name',
    description:
      'Ensures that `<meter>` elements have accessible names to describe their purpose, making them identifiable to assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-meter-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Meter Elements',
      description:
        'Provide an accessible name to `<meter>` elements using `aria-label` or `aria-labelledby` to clearly describe the meter’s purpose.',
      additionalTips: [
        "Use `aria-label` to give a brief description, such as 'Battery level'.",
        'If the `<meter>` has a visible label, use `aria-labelledby` to associate it with the meter.',
        "Ensure that the accessible name reflects what the meter represents (e.g., 'Progress toward goal').",
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<meter value='0.6' aria-label='Battery level'></meter>",
      },
    },
    tableConfig: {
      title: 'The following meter elements lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_PROGRESSBAR_NAME]: {
    title: 'ARIA Progressbar Name',
    description:
      'Ensures that `<progress>` elements have accessible names to describe their purpose, making them identifiable to assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-progressbar-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Progress Bars',
      description:
        'Provide an accessible name to `<progress>` elements using `aria-label` or `aria-labelledby` to convey the purpose of the progress bar.',
      additionalTips: [
        "Use `aria-label` for a brief, descriptive name like 'File upload progress'.",
        'For visible labels, use `aria-labelledby` to associate the label with the `<progress>` element.',
        "Ensure the name accurately reflects the progress bar’s function (e.g., 'Loading status').",
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<progress value='0.5' aria-label='Loading status'></progress>",
      },
    },
    tableConfig: {
      title: 'The following progress bars lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_PROHIBITED_ATTR]: {
    title: 'ARIA Prohibited Attributes',
    description:
      'Ensures that ARIA attributes are not applied to elements where they are explicitly prohibited, which can lead to confusion for assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-prohibited-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove Prohibited ARIA Attributes',
      description:
        'Remove any ARIA attributes from elements that do not support them, as this can cause accessibility issues and inconsistent behavior.',
      additionalTips: [
        'Refer to ARIA documentation to confirm which attributes are allowed on specific elements.',
        'Avoid adding ARIA attributes to elements that already have clear, semantic roles (e.g., using `aria-label` on an `<img>` with `alt`).',
        'Test with assistive technologies to confirm that content is conveyed correctly after removing prohibited attributes.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "<!-- Incorrect -->\n<img aria-label='Logo' src='logo.png' alt='Logo'>\n\n<!-- Correct -->\n<img src='logo.png' alt='Logo'>",
      },
    },
    tableConfig: {
      title: 'The following elements use prohibited ARIA attributes:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_REQUIRED_ATTR]: {
    title: 'ARIA Required Attributes',
    description:
      'Ensures that elements with specific ARIA roles include all required ARIA attributes, helping assistive technologies interpret them correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-required-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Missing Required ARIA Attributes',
      description:
        'Ensure that all elements with ARIA roles include any required ARIA attributes. Missing attributes can prevent assistive technologies from conveying the intended information.',
      additionalTips: [
        'Review the ARIA role specifications to identify required attributes for each role.',
        "For example, `role='slider'` requires `aria-valuemin`, `aria-valuemax`, and `aria-valuenow` attributes.",
        'Confirm that the required attributes are dynamically updated if the element’s state changes.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "<div role='slider' aria-valuemin='0' aria-valuemax='100' aria-valuenow='50'></div>",
      },
    },
    tableConfig: {
      title: 'The following elements are missing required ARIA attributes:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_REQUIRED_CHILDREN]: {
    title: 'ARIA Required Children',
    description:
      'Ensures that elements with specific ARIA roles include the required child elements, which help assistive technologies interpret the content structure correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-required-children',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Required Child Elements',
      description:
        'Include all required child elements for ARIA roles that specify a particular structure. Missing children can prevent screen readers from interpreting the element properly.',
      additionalTips: [
        'Review ARIA specifications for each role to identify required child elements.',
        "For example, `role='list'` should contain only elements with `role='listitem'` as children.",
        'Ensure the structure is logical and intuitive to assistive technology users.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "<ul role='list'>\n  <li role='listitem'>Item 1</li>\n  <li role='listitem'>Item 2</li>\n</ul>",
      },
    },
    tableConfig: {
      title: 'The following elements lack required child elements:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_REQUIRED_PARENT]: {
    title: 'ARIA Required Parent',
    description:
      'Ensures that elements with specific ARIA roles are placed within the required parent elements, enabling assistive technologies to interpret the structure correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-required-parent',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Required Parent Elements',
      description:
        'Ensure that elements with specific ARIA roles are nested within the appropriate parent elements. Missing parents can disrupt the expected structure and impact accessibility.',
      additionalTips: [
        'Check ARIA specifications for each role to identify required parent elements.',
        "For example, `role='listitem'` should be within an element with `role='list`.",
        'Verify that the structure is correctly nested to assistive technology users.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "<ul role='list'>\n  <li role='listitem'>Item 1</li>\n</ul>",
      },
    },
    tableConfig: {
      title: 'The following elements are missing required parent elements:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_ROLES]: {
    title: 'ARIA Roles',
    description:
      'Ensures that ARIA roles are used correctly and applied only to elements that support them, helping assistive technologies interpret content as intended.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-roles',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Apply ARIA Roles Correctly',
      description:
        'Use ARIA roles only on elements that support them, and ensure each role is applied in a way that aligns with the element’s purpose and function.',
      additionalTips: [
        'Check ARIA documentation to confirm which elements support specific roles.',
        "Avoid using ARIA roles on elements that have native semantics, like using `role='button'` on a `<button>` element.",
        'Test the page with a screen reader to ensure roles enhance rather than conflict with the native structure.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='navigation'>\n  <!-- Navigation content -->\n</div>",
      },
    },
    tableConfig: {
      title: 'The following elements have incorrect or unsupported ARIA roles:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_TEXT]: {
    title: 'ARIA Text',
    description:
      'Ensures that ARIA text roles are used correctly, making text-based content accessible and meaningful to assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-text',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use ARIA Text Roles Properly',
      description:
        "Apply ARIA text roles, like `role='text'`, only on elements where they clarify the content for assistive technologies. This ensures that screen readers interpret text-based elements accurately.",
      additionalTips: [
        "Use `role='text'` on elements that serve a purely textual purpose and lack inherent semantics.",
        "Avoid adding `role='text'` on elements that already have a semantic meaning, such as `<p>` or `<h1>`.",
        'Check the text’s readability and structure using a screen reader to ensure accessibility.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='text'>Informational content</div>",
      },
    },
    tableConfig: {
      title: 'The following elements use ARIA text roles incorrectly:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_TOGGLE_FIELD_NAME]: {
    title: 'ARIA Toggle Field Name',
    description:
      'Ensures that toggle fields, such as switches and checkboxes, have accessible names, allowing assistive technologies to convey their function accurately.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-toggle-field-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Toggle Fields',
      description:
        "Provide accessible names for all toggle fields using `aria-label` or `aria-labelledby`. This helps screen readers identify the toggle's purpose and state.",
      additionalTips: [
        "Use `aria-label` to add a descriptive name, such as 'Enable notifications'.",
        'If there is a visible label, use `aria-labelledby` to link the label to the toggle field.',
        'Ensure the name clarifies what the toggle controls, especially for custom toggle elements.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<input type='checkbox' aria-label='Enable notifications'>",
      },
    },
    tableConfig: {
      title: 'The following toggle fields lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_TOOLTIP_NAME]: {
    title: 'ARIA Tooltip Name',
    description:
      'Ensures that elements with ARIA tooltips have accessible names, allowing assistive technologies to convey their purpose and content accurately.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-tooltip-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Tooltips',
      description:
        'Provide an accessible name to elements with ARIA tooltips using `aria-label` or `aria-labelledby` to ensure that screen readers can communicate the tooltip’s purpose.',
      additionalTips: [
        "Use `aria-label` to give a concise description, such as 'More information'.",
        'For a visible tooltip heading, use `aria-labelledby` to link it to the tooltip content.',
        'Ensure that the tooltip name accurately describes the information it provides.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='tooltip' aria-label='Additional information'></div>",
      },
    },
    tableConfig: {
      title: 'The following tooltips lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_TREEITEM_NAME]: {
    title: 'ARIA Treeitem Name',
    description:
      'Ensures that elements with the `treeitem` role have accessible names, helping assistive technologies convey the item’s purpose within a tree structure.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-treeitem-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Tree Items',
      description:
        'Provide accessible names for all elements with the `treeitem` role using `aria-label` or `aria-labelledby`. This ensures that screen readers can identify each item in the tree structure.',
      additionalTips: [
        "Use `aria-label` to give each `treeitem` a unique and descriptive name, such as 'Folder 1'.",
        'If there is a visible label, use `aria-labelledby` to link it to the `treeitem`.',
        'Ensure that each tree item’s name is concise and reflects its function or content.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<div role='treeitem' aria-label='Folder 1'></div>",
      },
    },
    tableConfig: {
      title: 'The following tree items lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_VALID_ATTR]: {
    title: 'ARIA Valid Attributes',
    description:
      'Ensures that only valid ARIA attributes are used on elements, preventing issues where assistive technologies may misinterpret or ignore the attributes.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-valid-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Only Valid ARIA Attributes',
      description:
        'Remove any invalid ARIA attributes from elements. Using only recognized ARIA attributes ensures compatibility with assistive technologies.',
      additionalTips: [
        'Refer to ARIA specifications to confirm the validity of each attribute.',
        'Avoid custom or unsupported ARIA attributes, as they can lead to unexpected behavior.',
        'Use tools or validators to check for ARIA attribute compliance.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<!-- Incorrect -->\n<div aria-invalidattr='true'></div>\n\n<!-- Correct -->\n<div aria-hidden='true'></div>",
      },
    },
    tableConfig: {
      title: 'The following elements contain invalid ARIA attributes:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ARIA_VALID_ATTR_VALUE]: {
    title: 'ARIA Valid Attribute Value',
    description:
      'Ensures that ARIA attributes have valid values, helping assistive technologies interpret the element’s state and behavior correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/aria-valid-attr-value',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Valid Values for ARIA Attributes',
      description:
        'Ensure that all ARIA attributes have valid values, as specified by ARIA guidelines. Invalid values may cause assistive technologies to misinterpret the element.',
      additionalTips: [
        'Refer to ARIA specifications to confirm acceptable values for each attribute.',
        'For example, `aria-hidden` should only be set to `true` or `false`.',
        'Use validation tools to identify any attributes with unsupported or incorrect values.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<!-- Incorrect -->\n<div aria-hidden='yes'></div>\n\n<!-- Correct -->\n<div aria-hidden='true'></div>",
      },
    },
    tableConfig: {
      title: 'The following elements have ARIA attributes with invalid values:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.BF_CACHE]: {
    title: 'Back/Forward Cache',
    description:
      'Evaluates whether your page is optimized to take advantage of the back/forward cache, which can speed up navigation for users revisiting pages.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/bfcache/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize for Back/Forward Cache',
      description:
        'Make your page compatible with the back/forward cache to enhance navigation speed when users go back or forward to previously visited pages.',
      additionalTips: [
        'Avoid using `unload` event listeners, which prevent caching.',
        'Use passive event listeners for scroll and other user interactions to improve performance.',
        'Verify back/forward cache usage by testing your page in Chrome DevTools under the Application tab.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Avoiding unload event\nwindow.addEventListener('pagehide', () => {\n  // Perform necessary cleanup here\n});",
        react:
          "import { useEffect } from 'react';\n\nuseEffect(() => {\n  const handlePageHide = () => {\n    // Perform necessary cleanup here\n  };\n  window.addEventListener('pagehide', handlePageHide);\n\n  return () => {\n    window.removeEventListener('pagehide', handlePageHide);\n  };\n}, []);",
      },
    },
    tableConfig: {
      title: 'The following resources cannot enter back/forward cache:',
      columns: [
        { label: 'Reason', key: 'reason', type: 'text' },
        { label: 'Failure Type', key: 'failureType', type: 'text' },
      ],
      subItemColumns: [{ label: 'URL', key: 'frameUrl', type: 'link', colSpan: 2 }],
    },
  },
  [MetricId.BOOTUP_TIME]: {
    title: 'Bootup Time',
    description:
      'Evaluates the time taken by scripts and other resources during the page’s initial load, which impacts user-perceived performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/bootup-time/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Bootup Time',
      description:
        'Optimize scripts and resources to decrease bootup time, ensuring faster load performance for users.',
      additionalTips: [
        'Identify large scripts and consider splitting them into smaller chunks to reduce load time.',
        'Minify and compress JavaScript files to decrease their size.',
        'Consider loading non-essential scripts asynchronously or defer their execution.',
        'Use lazy loading for non-essential components to reduce initial page load.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "// Example: Using async to defer script loading\n<script src='script.js' async></script>",
        react:
          "// React example: Lazy load a component\nimport { lazy, Suspense } from 'react';\n\nconst LazyComponent = lazy(() => import('./NonEssentialComponent'));\n\nfunction App() {\n  return (\n    <div>\n      <h1>Main Content</h1>\n      <Suspense fallback={<div>Loading...</div>}>\n        <LazyComponent />\n      </Suspense>\n    </div>\n  );\n}",
      },
    },
    tableConfig: {
      title: 'The following resources contribute to bootup time:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Time', key: 'total', type: 'milliseconds' },
        { label: 'Scripting', key: 'scripting', type: 'milliseconds' },
        { label: 'Script Parse & Compile', key: 'scriptParseCompile', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.BUTTON_NAME]: {
    title: 'Button Name',
    description:
      'Ensures that buttons have accessible names, helping assistive technologies convey their purpose to users effectively.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/button-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Buttons',
      description:
        'Provide accessible names for all button elements using `aria-label`, `aria-labelledby`, or visible text. This allows screen readers to identify the button’s function.',
      additionalTips: [
        'Use `aria-label` for buttons without visible text to provide a descriptive name.',
        'If there’s a visible label, use `aria-labelledby` to link it to the button.',
        "Ensure button names are unique and convey their function (e.g., 'Submit form' or 'Close dialog').",
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<button aria-label='Close'>X</button>",
      },
    },
    tableConfig: {
      title: 'The following buttons lack accessible names:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.BYPASS]: {
    title: 'Bypass Blocks',
    description:
      'Ensures that users can bypass blocks of content, such as navigation menus, to quickly access the main content on a page. This is essential for accessibility and efficient navigation.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/bypass',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Provide Bypass Mechanisms',
      description:
        "Add mechanisms that allow users to bypass repetitive content blocks, like navigation menus, and access the main content directly. This is typically achieved with a 'Skip to main content' link.",
      additionalTips: [
        "Add a visually hidden 'Skip to main content' link at the top of the page.",
        'Ensure the bypass link is visible and accessible via keyboard navigation.',
        'Verify that users can activate the bypass link without triggering other interactions.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<a href='#main-content' class='sr-only'>Skip to main content</a>\n<main id='main-content'>\n  <!-- Main content here -->\n</main>",
      },
    },
    tableConfig: {
      title: 'The following pages lack a bypass mechanism:',
      columns: [
        {
          label: 'Element',
          key: 'node',
          type: 'node',
        },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.CANONICAL]: {
    title: 'Canonical Link',
    description:
      'Ensures that pages use a canonical link to specify the preferred URL, which helps search engines understand and prioritize the correct version of the page.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/canonical/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Canonical Link',
      description:
        'Include a canonical link in the `<head>` section of the page to specify the preferred URL, preventing duplicate content issues and consolidating SEO value.',
      additionalTips: [
        'Use the full URL as the `href` for the canonical link (e.g., `https://example.com/page`).',
        'Ensure that only one canonical link is present on the page.',
        'Verify that the canonical link points to the correct, preferred version of the page.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<link rel='canonical' href='https://example.com/page' />",
      },
    },
  },
  [MetricId.CHARSET]: {
    title: 'Character Set',
    description:
      'Ensures that the character encoding is specified at the beginning of the document to help browsers render the page correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/charset/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Specify a Character Encoding',
      description:
        "Add a `<meta charset='UTF-8'>` tag in the `<head>` section as the first element to define the character encoding, ensuring correct text rendering.",
      additionalTips: [
        "Place the `<meta charset='UTF-8'>` tag at the very top of the `<head>` section.",
        'Use UTF-8 as the encoding, as it is widely supported and compatible with most languages.',
        'Verify the correct rendering of special characters after adding the encoding.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<meta charset='UTF-8'>",
      },
    },
  },
  [MetricId.COLOR_CONTRAST]: {
    title: 'Color Contrast',
    description:
      'Ensures that text and interactive elements have sufficient color contrast, making content readable for all users, including those with visual impairments.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/color-contrast',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Improve Color Contrast',
      description:
        'Adjust text and background colors to achieve a contrast ratio that meets WCAG guidelines. This enhances readability and accessibility for users with low vision or color blindness.',
      additionalTips: [
        'Use a contrast checker such as [WebAIM Contrast Checker](https://webaim.org/resources/contrastchecker/) or [Contrast Ratio](https://contrast-ratio.com/) to verify that text meets the minimum contrast ratio of 4.5:1 for normal text and 3:1 for large text.',
        'Consider adjusting either the text or background color to achieve sufficient contrast.',
        'Ensure that contrast requirements are met across all themes or modes (e.g., dark mode).',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          '/* Example: Improving contrast */\n.text-high-contrast {\n  color: #000;\n  background-color: #FFF;\n}',
      },
    },
    tableConfig: {
      title: 'The following elements have insufficient color contrast:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        { label: 'Label', key: 'node', subKey: 'nodeLabel', type: 'text' },
        { label: 'Explanation', key: 'node', subKey: 'explanation', type: 'text' },
      ],
    },
  },
  [MetricId.CRAWLABLE_ANCHORS]: {
    title: 'Crawlable Anchors',
    description:
      'Ensures that anchor (`<a>`) elements contain valid and descriptive href attributes, making them accessible to search engines for better indexing.',
    solution: {
      title: 'Ensure Anchors Are Crawlable',
      description:
        'Provide valid href attributes for anchor elements, and avoid placeholder links like `#` or `javascript:void(0)`. Descriptive and crawlable links improve SEO and help users navigate effectively.',
      additionalTips: [
        'Use descriptive URLs that indicate the destination content.',
        "Avoid placeholder links and instead use `role='button'` for elements acting as buttons.",
        'Ensure all anchor links are functional and lead to valid destinations.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<a href='https://example.com'>Learn more about our services</a>",
      },
    },
    tableConfig: {
      title: 'The following anchor elements are not crawlable:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.CRITICAL_REQUEST_CHAINS]: {
    title: 'Critical Request Chains',
    description:
      'Analyzes critical request chains that delay the initial page load, impacting performance by blocking other resources.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/critical-request-chains/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Critical Request Chains',
      description:
        'Reduce or eliminate critical request chains to speed up page load. This can improve rendering performance and reduce delays for users.',
      additionalTips: [
        'Identify large or slow-loading resources in critical chains and consider delaying or deferring them.',
        'Break down large scripts or styles into smaller chunks and load only the essential parts initially.',
        'Use asynchronous or deferred loading for non-critical scripts.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "// Example: Deferring non-critical scripts\n<script src='non-critical.js' defer></script>",
      },
    },
    // TODO: SS enhancement: tableConfig will need some work
    hidden: true,
  },
  [MetricId.CSP_XSS]: {
    title: 'Content Security Policy (CSP) for XSS Protection',
    description:
      'Evaluates whether a Content Security Policy (CSP) is implemented to protect against cross-site scripting (XSS) attacks, helping to prevent unauthorized script execution.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/csp-xss/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Implement a Content Security Policy for XSS',
      description:
        'Add a Content Security Policy (CSP) to restrict unauthorized scripts, reducing the risk of XSS attacks and enhancing security.',
      additionalTips: [
        'Specify `script-src` in your CSP to allow scripts only from trusted sources.',
        'Consider using `nonce` attributes for inline scripts to allow only approved scripts to execute.',
        'Monitor CSP violations to identify and address potential security risks.',
      ],
      fixability: 'hard',
      codeSnippets: {
        generic:
          "<meta http-equiv='Content-Security-Policy' content=\"script-src 'self' https://trusted-scripts.com; object-src 'none';\">",
      },
    },
    tableConfig: {
      title: 'The following CSP issues increase XSS vulnerability:',
      columns: [
        { label: 'Severity', key: 'severity', type: 'text' },
        { label: 'Description', key: 'description', type: 'text' },
      ],
    },
  },
  [MetricId.CUMULATIVE_LAYOUT_SHIFT]: {
    title: 'Cumulative Layout Shift (CLS)',
    description:
      'Measures the cumulative layout shift score, which indicates unexpected layout shifts on the page that can negatively impact user experience. Aim to keep CLS under 0.1 for an optimal experience.',
    links: [
      {
        label: 'Learn more about CLS',
        url: 'https://web.dev/cls/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Cumulative Layout Shift',
      description:
        'Minimize layout shifts by ensuring that elements are properly sized and stable as they load. Reducing CLS enhances the visual stability of the page, improving the user experience.',
      additionalTips: [
        'Set explicit width and height attributes on images and videos to reserve space before they load.',
        'Use CSS `aspect-ratio` for responsive images to maintain their intended proportions.',
        'Avoid inserting content above existing content unless necessary, as it may shift elements down.',
        'Ensure fonts are loaded efficiently to avoid shifts caused by font swaps (e.g., use `font-display: swap`).',
      ],
      fixability: 'medium',
    },
    isCompositeMetric: true,
  },
  [MetricId.DEFINITION_LIST]: {
    title: 'Definition List Usage',
    description:
      'Ensures that definition lists (`<dl>`) are correctly structured with only `<dt>` (definition terms) and `<dd>` (definition descriptions) elements as children, promoting semantic HTML.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/definition-list',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Definition Lists Properly',
      description:
        'Ensure that `<dl>` elements contain only `<dt>` and `<dd>` elements as direct children. This maintains semantic structure and helps assistive technologies interpret content accurately.',
      additionalTips: [
        'Avoid placing non-definition elements like `<div>` or `<p>` directly inside a `<dl>`.',
        'Ensure each `<dt>` (term) is followed by a `<dd>` (description) to maintain proper hierarchy.',
        'Use definition lists only for content that has a clear term-and-definition relationship.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          '<dl>\n  <dt>Term 1</dt>\n  <dd>Description 1</dd>\n  <dt>Term 2</dt>\n  <dd>Description 2</dd>\n</dl>',
      },
    },
    tableConfig: {
      title: 'The following definition lists have improper children:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.DEPRECATIONS]: {
    title: 'Deprecated APIs',
    description:
      'Identifies usage of deprecated web APIs, which may lead to future compatibility issues as browsers phase them out.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/deprecations/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Replace Deprecated APIs',
      description:
        'Update or replace deprecated APIs to ensure long-term compatibility. Using modern, supported APIs improves performance and reliability across browsers.',
      additionalTips: [
        'Consult MDN or caniuse.com to find modern alternatives to deprecated APIs.',
        'Test your page in multiple browsers to ensure compatibility.',
        'Avoid relying on APIs that are marked for removal in future browser versions.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Replacing deprecated usage\n// Deprecated\ndocument.createElement('blink');\n\n// Modern alternative\n// (Typically avoid unsupported elements or use CSS for visual effects)",
      },
    },
    tableConfig: {
      title: 'The following deprecated APIs are used:',
      columns: [
        { label: 'Deprecated API', key: 'value', type: 'text' },
        { label: 'Source', key: 'source', type: 'text' },
      ],
      subItemColumns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Text', key: 'text', type: 'text' },
      ],
    },
  },
  [MetricId.DIAGNOSTICS]: {
    title: 'Performance Diagnostics',
    description:
      'A collection of performance statistics that offer insights into page load and resource usage, helping to identify potential areas for optimization.',
    tableConfig: {
      title: 'Diagnostics data for your page:',
      columns: [
        { label: 'Number of Requests', key: 'numRequests', type: 'unitless' },
        { label: 'Number of Scripts', key: 'numScripts', type: 'unitless' },
        { label: 'Number of Stylesheets', key: 'numStylesheets', type: 'unitless' },
        { label: 'Number of Fonts', key: 'numFonts', type: 'unitless' },
        { label: 'Number of Tasks', key: 'numTasks', type: 'unitless' },
        { label: 'Tasks Over 10ms', key: 'numTasksOver10ms', type: 'unitless' },
        { label: 'Tasks Over 25ms', key: 'numTasksOver25ms', type: 'unitless' },
        { label: 'Tasks Over 50ms', key: 'numTasksOver50ms', type: 'unitless' },
        { label: 'Tasks Over 100ms', key: 'numTasksOver100ms', type: 'unitless' },
        { label: 'Tasks Over 500ms', key: 'numTasksOver500ms', type: 'unitless' },
        { label: 'Round-Trip Time (ms)', key: 'rtt', type: 'milliseconds' },
        { label: 'Throughput (Kbps)', key: 'throughput', type: 'unitless' },
        { label: 'Max Round-Trip Time (ms)', key: 'maxRtt', type: 'milliseconds' },
        { label: 'Max Server Latency (ms)', key: 'maxServerLatency', type: 'milliseconds' },
        { label: 'Total Byte Weight (bytes)', key: 'totalByteWeight', type: 'bytes' },
        { label: 'Total Task Time (ms)', key: 'totalTaskTime', type: 'milliseconds' },
        { label: 'Main Document Transfer Size (bytes)', key: 'mainDocumentTransferSize', type: 'bytes' },
      ],
    },
    hidden: true,
  },
  [MetricId.DLITEM]: {
    title: 'Definition List Item Structure',
    description:
      'Ensures that definition list items within `<dl>` elements are structured correctly with `<dt>` and `<dd>` elements, enhancing semantic HTML and accessibility.',
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Structure Definition List Items Properly',
      description:
        'Ensure that only `<dt>` (definition terms) and `<dd>` (definition descriptions) are direct children of `<dl>`. This maintains semantic meaning and improves accessibility.',
      additionalTips: [
        'Each `<dt>` should be followed by one or more `<dd>` elements to maintain correct structure.',
        'Avoid placing non-definition elements, like `<div>` or `<span>`, directly inside a `<dl>`.',
        'Use `<dl>` lists only for content with a clear term-and-definition relationship.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          '<dl>\n  <dt>Term 1</dt>\n  <dd>Description 1</dd>\n  <dt>Term 2</dt>\n  <dd>Description 2</dd>\n</dl>',
      },
    },
    tableConfig: {
      title: 'The following definition lists contain incorrectly structured items:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.DOCUMENT_TITLE]: {
    title: 'Document Title',
    description:
      'Ensures that the page has a descriptive `<title>` element in the `<head>`, which helps users and search engines understand the content of the page.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/document-title/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add or Update the Document Title',
      description:
        'Include a `<title>` element in the `<head>` section with a descriptive, unique title for each page. This improves accessibility, SEO, and user experience.',
      additionalTips: [
        'Keep the title concise yet descriptive, ideally between 50-60 characters.',
        "Avoid generic titles like 'Home' or 'Untitled Page'.",
        'Include keywords that accurately represent the page content without overloading.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: '<title>Your Page Title Here</title>',
      },
    },
  },
  [MetricId.DOCTYPE]: {
    title: 'Document Doctype',
    description:
      'Ensures that the document includes a `<!DOCTYPE html>` declaration at the very beginning, which enables standards mode for consistent rendering across browsers.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/doctype/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Doctype Declaration',
      description:
        'Add `<!DOCTYPE html>` at the top of the HTML document to ensure it is rendered in standards mode, improving compatibility across browsers.',
      additionalTips: [
        'Place `<!DOCTYPE html>` as the very first line in the HTML document.',
        'Avoid any content or whitespace before the doctype declaration.',
        'Ensure the doctype is lowercase for compatibility across all modern browsers.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: '<!DOCTYPE html>',
      },
    },
  },
  [MetricId.DOM_SIZE]: {
    title: 'DOM Size',
    description:
      'Evaluates the size of the Document Object Model (DOM) to identify potential performance bottlenecks caused by a large number of elements.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/dom-size/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize DOM Size',
      description:
        'Reduce the number of DOM elements to improve load performance and responsiveness, particularly on resource-constrained devices.',
      additionalTips: [
        'Limit the number of top-level elements and avoid deeply nested structures.',
        'Remove unnecessary or hidden elements from the DOM.',
        'Use simpler, reusable components and avoid generating large amounts of dynamic content if possible.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "<!-- Example: Reducing unnecessary elements -->\n<div class='item'>\n  <span>Item description</span>\n</div>",
      },
    },
    tableConfig: {
      title: 'DOM elements contributing to performance issues:',
      columns: [
        { label: 'Statistic', key: 'statistic', type: 'text' },
        { label: 'Value', key: 'value', subKey: 'value', type: 'unitless' },
      ],
    },
  },
  [MetricId.DUPLICATE_ID_ARIA]: {
    title: 'Duplicate ARIA IDs',
    description:
      'Identifies duplicate IDs used with ARIA attributes, which can lead to accessibility issues as screen readers and other assistive technologies may not interpret the elements correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/duplicate-id-aria',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Unique ARIA IDs',
      description:
        'Assign unique IDs to elements used with ARIA attributes to ensure they are correctly interpreted by assistive technologies.',
      additionalTips: [
        'Check for duplicate `id` attributes within the document, especially on elements used with ARIA attributes.',
        'Consider dynamically generating unique IDs when creating repeated elements with ARIA.',
        'Validate ARIA attributes using accessibility tools to catch any potential ID conflicts.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<!-- Correct example -->\n<div id='unique-id' aria-labelledby='unique-id'></div>",
      },
    },
    tableConfig: {
      title: 'Elements with duplicate ARIA IDs:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.DUPLICATED_JAVASCRIPT]: {
    title: 'Duplicated JavaScript Code',
    description:
      'Identifies duplicated JavaScript code loaded on the page, which can increase load time and consume unnecessary resources.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/articles/remove-unused-code',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Eliminate Duplicated JavaScript Code',
      description:
        'Reduce page load time by eliminating duplicated JavaScript code. Consolidating code or caching it effectively can improve performance.',
      additionalTips: [
        'Review and consolidate duplicated JavaScript code or libraries.',
        'Use a Content Delivery Network (CDN) to cache commonly used libraries across pages.',
        'Ensure that JavaScript code is only loaded once and reused where possible.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Removing duplicate imports\n// Before\n<script src='library.js'></script>\n<script src='library.js'></script>\n\n// After\n<script src='library.js'></script>",
        next: "// Next.js example: Avoid redundant modules with bundle analysis\n// Install the package:\n// npm install @next/bundle-analyzer\n\nconst withBundleAnalyzer = require('@next/bundle-analyzer')({\n  enabled: process.env.ANALYZE === 'true'\n});\n\nmodule.exports = withBundleAnalyzer({\n  // Next.js config here\n});",
      },
    },
    tableConfig: {
      title: 'The following JavaScript code is duplicated:',
      columns: [
        { label: 'Source', key: 'source', type: 'text' },
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Wasted Bytes', key: 'wastedBytes', type: 'bytes' },
      ],
      subItemColumns: [
        { label: 'URL', key: 'url', type: 'link', colSpan: 2 },
        { label: 'Transferred Bytes', key: 'sourceTransferBytes', type: 'bytes', colSpan: 2 },
      ],
    },
  },
  [MetricId.EFFICIENT_ANIMATED_CONTENT]: {
    title: 'Efficient Animated Content',
    description:
      'Evaluates the efficiency of animated content on the page, identifying animations that may consume excessive resources and impact performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/efficient-animated-content/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Animated Content',
      description:
        'Use efficient animation techniques to reduce resource consumption and enhance performance, especially on resource-constrained devices.',
      additionalTips: [
        'Use CSS animations and transforms for simple, lightweight effects.',
        'For complex animations, consider formats like MP4 or Lottie to achieve high-quality, efficient animations.',
        'Limit animations to essential elements or small areas to reduce impact on performance.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          '<!-- Example: Using CSS for lightweight animation -->\n<style>\n  .fade-in { animation: fadeIn 2s ease-in; }\n</style>',
        react:
          "// React example: Using framer-motion for lightweight animations\nimport { motion } from 'framer-motion';\n\nfunction FadeInComponent() {\n  return <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>Hello</motion.div>;\n}",
        vue: "<!-- Vue example: Using Vue’s built-in transition component -->\n<template>\n  <transition name='fade'>\n    <div v-if='show'>Hello</div>\n  </transition>\n</template>\n\n<style>\n.fade-enter-active, .fade-leave-active { transition: opacity 2s; }\n.fade-enter, .fade-leave-to { opacity: 0; }\n</style>",
        tailwindcss:
          "<!-- Tailwind CSS example: Using utility classes for animation -->\n<div class='animate-fadeIn opacity-0 transition-opacity duration-2000'>Hello</div>",
      },
    },
    tableConfig: {
      title: 'The following animated content is inefficient:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Wasted Bytes', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.EMPTY_HEADING]: {
    title: 'Empty Heading',
    description:
      'Identifies heading (`<h1>`, `<h2>`, etc.) elements that are empty, which can confuse users and assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/empty-heading',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove or Populate Empty Headings',
      description:
        'Ensure that all heading elements contain meaningful text or remove them if they serve no purpose. Properly populated headings improve accessibility and content structure.',
      additionalTips: [
        'Review all heading elements to ensure they contain descriptive content.',
        'Use headings to structure content logically, helping users navigate the page.',
        'Avoid using headings purely for styling; use CSS for visual adjustments.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: '<!-- Correct usage -->\n<h2>Section Title</h2>',
      },
    },
    tableConfig: {
      title: 'The following headings are empty:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.ERRORS_IN_CONSOLE]: {
    title: 'Console Errors',
    description:
      'Identifies errors in the browser console that may indicate issues in code execution or loading resources. Addressing console errors can improve performance and prevent unexpected behavior.',
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.chrome.com/docs/lighthouse/best-practices/errors-in-console',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Fix Console Errors',
      description:
        'Review and resolve errors logged in the browser console. Reducing console errors can improve stability, performance, and user experience.',
      additionalTips: [
        'Check the console for stack traces to identify the source of each error.',
        'Use `try-catch` blocks in JavaScript to handle errors gracefully.',
        'Verify that all external resources (like scripts and images) are loading correctly.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Console errors detected:',
      columns: [
        { label: 'Source', key: 'source', type: 'text' },
        { label: 'Description', key: 'description', type: 'text' },
        { label: 'Source URL', key: 'sourceLocation', subKey: 'url', type: 'link' },
        { label: 'Line', key: 'sourceLocation', subKey: 'line', type: 'unitless' },
        { label: 'Column', key: 'sourceLocation', subKey: 'column', type: 'unitless' },
      ],
    },
  },
  [MetricId.FIRST_CONTENTFUL_PAINT]: {
    title: 'First Contentful Paint (FCP)',
    description:
      'Measures the time it takes for the first content element to appear on the screen. A lower FCP score indicates faster initial rendering, improving perceived load speed for users. Aim to keep FCP under 1.8 seconds on mobile for optimal performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/fcp/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Improve First Contentful Paint',
      description:
        'Optimize FCP by focusing on reducing the time it takes for the first visible element to render. Enhancing FCP can greatly improve the perceived load time for users.',
      additionalTips: [
        'Use server-side rendering (SSR) to deliver content faster to users.',
        'Optimize CSS by inlining critical CSS and deferring non-critical styles.',
        'Minimize JavaScript that may delay rendering by deferring or async-loading non-essential scripts.',
        'Use preloading for key resources, like fonts and above-the-fold images, to speed up initial rendering.',
      ],
      fixability: 'medium',
    },
    isCompositeMetric: true,
  },
  [MetricId.FONT_DISPLAY]: {
    title: 'Font Display',
    description:
      'Ensures that custom fonts are displayed in a way that minimizes invisible text during load, improving perceived load performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/font-display/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Font Display for Faster Text Rendering',
      description:
        'Apply the `font-display: swap` property in custom fonts to prevent invisible text during load, ensuring users see text immediately with a fallback font.',
      additionalTips: [
        'Set `font-display: swap` in your @font-face CSS rule to improve text visibility.',
        'Consider using system fonts where performance is critical, especially on slow connections.',
        'Load critical fonts asynchronously to reduce initial load impact.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Font display options',
          url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "@font-face {\n  font-family: 'CustomFont';\n  src: url('/fonts/customfont.woff2') format('woff2');\n  font-display: swap;\n}",
      },
    },
    tableConfig: {
      title: 'Fonts that may benefit from font-display settings:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Potential Load Delay (ms)', key: 'wastedMs', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.FONT_SIZE]: {
    title: 'Font Size',
    description:
      'Ensures that text on the page is large enough to be legible on all devices, improving readability and accessibility.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/font-size/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Adjust Font Sizes for Better Readability',
      description:
        'Increase the font size of text elements that are too small, especially for body text. A minimum size of 16px is recommended for readability on mobile and desktop.',
      additionalTips: [
        'Use relative units like `rem` or `em` for scalable text sizes across different devices.',
        'Ensure all body text is at least 16px to meet readability standards.',
        'Avoid small font sizes for buttons and other interactive elements to improve accessibility.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/font-size',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '/* Example: Setting a minimum font size */\nbody {\n  font-size: 16px;\n}\n',
      },
    },
    tableConfig: {
      title: 'Elements with insufficient font size:',
      columns: [
        { label: 'Source', key: 'source', subKey: 'value', type: 'text' },
        { label: 'Font Size', key: 'fontSize', type: 'text' },
        { label: 'Coverage', key: 'coverage', type: 'text' },
      ],
    },
  },
  [MetricId.FORM_FIELD_MULTIPLE_LABELS]: {
    title: 'Form Field with Multiple Labels',
    description:
      'Ensures that form fields have a single, clear label to avoid confusion for users and assistive technologies. Multiple labels on a single field can disrupt accessibility.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/form-field-multiple-labels',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Unique Labels for Form Fields',
      description:
        'Assign a single, descriptive label to each form field to improve clarity and accessibility for screen readers.',
      additionalTips: [
        'Check that each form field has one `<label>` element associated with it.',
        'If additional context is needed, consider using `aria-describedby` to provide extra information without adding multiple labels.',
        'Validate form fields with accessibility tools to ensure label clarity.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<label for='email'>Email</label>\n<input type='email' id='email' aria-describedby='email-hint'>\n<span id='email-hint'>We'll never share your email.</span>",
      },
    },
    tableConfig: {
      title: 'The following form fields have multiple labels:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.FRAME_TITLE]: {
    title: 'Frame Title',
    description:
      'Ensures that all `<iframe>` elements have a descriptive title, which improves accessibility by providing context for screen reader users.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/frame-title',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Descriptive Titles to Frames',
      description:
        'Provide each `<iframe>` with a unique and descriptive title to help screen reader users understand the content or purpose of the frame.',
      additionalTips: [
        "Use concise but meaningful titles for frames, such as 'Video player' or 'Map location'.",
        "Avoid generic titles like 'Iframe' or 'Embedded content'.",
        'Verify that frame titles are contextually accurate for each embedded element.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe#best_practices',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<iframe src='video.html' title='Video player'></iframe>",
      },
    },
    tableConfig: {
      title: 'The following frames lack descriptive titles:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.GEOLOCATION_ON_START]: {
    title: 'Geolocation on Page Load',
    description:
      'Detects geolocation requests triggered automatically on page load, which may negatively impact user experience by prompting permission dialogs unexpectedly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/geolocation-on-start/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Request Geolocation on User Interaction',
      description:
        'Trigger geolocation requests only after user interaction, such as a button click. This prevents unexpected permission dialogs on page load and enhances user experience.',
      additionalTips: [
        'Use event listeners, like click events, to initiate geolocation requests based on user action.',
        'Provide context for geolocation requests to help users understand the purpose before prompting.',
        'Test the flow to ensure the request is only triggered when users engage with relevant content or features.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API#security_and_privacy',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Example: Requesting geolocation on user interaction\nbutton.addEventListener('click', () => {\n  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);\n});",
      },
    },
    tableConfig: {
      title: 'The following scripts request geolocation on page load:',
      columns: [{ label: 'Source', key: 'source', type: 'text' }],
    },
  },
  [MetricId.HEADING_ORDER]: {
    title: 'Heading Order',
    description:
      'Ensures that heading elements (`<h1>`, `<h2>`, etc.) follow a logical order to improve accessibility and help users navigate content more easily.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/heading-order',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Correct Heading Order',
      description:
        'Organize heading elements in a sequential and logical order to maintain a clear content hierarchy. This helps screen readers and users navigate the content more effectively.',
      additionalTips: [
        'Ensure headings proceed in order, such as `<h1>` followed by `<h2>`, without skipping levels.',
        'Avoid using heading tags for styling purposes; use CSS for visual adjustments.',
        'Regularly validate heading structure to confirm it accurately reflects the page’s content hierarchy.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#usage_notes',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          '<!-- Correct order example -->\n<h1>Main Title</h1>\n<h2>Subheading 1</h2>\n<h2>Subheading 2</h2>\n<h3>Subsection</h3>',
      },
    },
    tableConfig: {
      title: 'The following headings are out of order:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.HREFLANG]: {
    title: 'Hreflang',
    description:
      'Hreflang attributes specify the language and regional targeting of a web page, helping search engines deliver the correct version of the page to users based on their language and location.',
    links: [
      {
        label: 'Learn more',
        url: 'https://support.google.com/webmasters/answer/189077?hl=en',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Implement Correct Hreflang Attributes',
      description:
        'Ensure hreflang attributes are properly set up to specify language and regional targeting for different versions of your site, which helps search engines display the right page to the right audience.',
      additionalTips: [
        'Each hreflang attribute should include both a language code and, if applicable, a regional code (e.g., `en-us`).',
        'Verify that each hreflang value corresponds to an existing URL and is correctly formatted.',
        'Check for any duplicate or conflicting hreflang attributes, as these can cause indexing issues.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<link rel='alternate' hreflang='en-us' href='https://example.com/us/' />",
      },
    },
    tableConfig: {
      title: 'Hreflang issues detected:',
      columns: [{ label: 'Source', key: 'source', type: 'node' }],
      subItemColumns: [{ label: 'Issue', key: 'reason', type: 'text' }],
    },
  },
  [MetricId.HTML_HAS_LANG]: {
    title: 'HTML Has Lang Attribute',
    description:
      'Ensures that the `<html>` element includes a `lang` attribute to specify the language of the document, enhancing accessibility and helping screen readers interpret the page content correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/html-has-lang/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Lang Attribute to the HTML Element',
      description:
        'Specify a `lang` attribute on the `<html>` element to declare the primary language of the document. This aids accessibility tools in interpreting the content appropriately.',
      additionalTips: [
        'Use standard language codes (e.g., `en` for English, `fr` for French).',
        'Ensure the `lang` attribute is accurate and reflects the document’s main language.',
        'Avoid changing the `lang` attribute dynamically, as it may confuse screen readers.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<html lang='en'>",
      },
    },
  },
  [MetricId.HTML_LANG_VALID]: {
    title: 'HTML Lang Attribute Validity',
    description:
      'Ensures that the `lang` attribute on the `<html>` element uses a valid language code, which helps screen readers interpret the page content correctly.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/html-lang-valid/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use a Valid Lang Attribute',
      description:
        'Specify a valid language code in the `lang` attribute on the `<html>` element. Valid codes improve accessibility by helping assistive technologies understand the document’s language.',
      additionalTips: [
        'Use standard ISO language codes, such as `en` for English or `es` for Spanish.',
        'For regional variations, use codes like `en-US` for American English or `fr-CA` for Canadian French.',
        'Refer to the IETF language tag list to ensure correct language codes.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<html lang='en'>",
      },
    },
  },
  [MetricId.HTML_XML_LANG_MISMATCH]: {
    title: 'HTML and XML Lang Attribute Mismatch',
    description:
      'Ensures that the `lang` and `xml:lang` attributes on the `<html>` element are consistent, improving compatibility and accessibility.',
    links: [
      {
        label: 'Learn more',
        url: 'https://www.w3.org/TR/WCAG20-TECHS/H57.html',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Consistency Between Lang and XML:Lang Attributes',
      description:
        'If both `lang` and `xml:lang` attributes are present on the `<html>` element, they should specify the same language code to prevent ambiguity for assistive technologies and browsers.',
      additionalTips: [
        "Use the same language code for both `lang` and `xml:lang` (e.g., `lang='en'` and `xml:lang='en'`).",
        'Check for any discrepancies between these attributes to avoid conflicts.',
        'Ensure that language codes match the primary language of the document content.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<html lang='en' xml:lang='en'>",
      },
    },
  },
  [MetricId.HTTP_STATUS_CODE]: {
    title: 'HTTP Status Code',
    description:
      'Checks the HTTP status code of the page to ensure it returns a successful response (200 OK). Non-success codes can impact SEO and user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/http-status-code',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Successful HTTP Status Code',
      description:
        'Verify that the page returns a 200 OK status. Avoid redirect chains and ensure that error responses (such as 404 or 500) are handled properly.',
      additionalTips: [
        'Check server configurations to ensure pages load successfully with a 200 OK status.',
        'Avoid excessive redirect chains, as they can slow down page load and impact SEO.',
        'Implement custom error pages for 404 and 500 statuses to improve user experience.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Checking HTTP status in JavaScript\nfetch('https://example.com')\n  .then(response => {\n    if (response.status === 200) {\n      console.log('Page loaded successfully');\n    } else {\n      console.error('Page load error:', response.status);\n    }\n  })",
      },
    },
  },
  [MetricId.IDENTICAL_LINKS_SAME_PURPOSE]: {
    title: 'Identical Links with Same Purpose',
    description:
      'Identifies links with the same purpose that lead to the same destination but use different text. Consistent link text improves accessibility and usability.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/identical-links-same-purpose',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Consistent Link Text for Identical Destinations',
      description:
        'Use the same link text for links that have the same destination and purpose. This helps users, especially those using assistive technologies, understand link destinations more clearly.',
      additionalTips: [
        'Review links leading to the same URL and ensure they have identical or consistent link text.',
        "Avoid using vague phrases like 'click here' for identical links with different destinations.",
        'Use descriptive text that reflects the purpose or content of the destination.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "<!-- Consistent link text example -->\n<a href='https://example.com/about'>About Us</a>\n<a href='https://example.com/about'>About Us</a>",
      },
    },
    tableConfig: {
      title: 'The following links have identical destinations but different text:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.IMAGE_ALT]: {
    title: 'Image Alt Text',
    description:
      'Ensures that all `<img>` elements include descriptive `alt` text, which improves accessibility by providing context to users who cannot see images.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/image-alt/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Descriptive Alt Text to Images',
      description:
        'Provide meaningful `alt` text for each image, describing its content or purpose. This helps screen readers convey the image’s information to visually impaired users.',
      additionalTips: [
        'Keep `alt` text concise but descriptive, focusing on the essential content of the image.',
        "For decorative images, use `alt=''` to allow screen readers to skip the image.",
        "Avoid using phrases like 'image of' or 'picture of' in `alt` text.",
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-alt',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<img src='example.jpg' alt='Description of the image'>",
      },
    },
    tableConfig: {
      title: 'The following images are missing alt text:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.IMAGE_ASPECT_RATIO]: {
    title: 'Image Aspect Ratio',
    description:
      'Ensures that images are displayed with their correct aspect ratios to prevent distortion and improve visual quality.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/image-aspect-ratio/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Maintain Correct Aspect Ratio for Images',
      description:
        'Set images to display at their correct aspect ratio to prevent stretching or squashing, ensuring a better visual experience.',
      additionalTips: [
        'Use CSS to control image dimensions while maintaining the original aspect ratio.',
        'Check that image elements match the original aspect ratio of the file to prevent distortion.',
        'Avoid hardcoding width and height values that distort the aspect ratio.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#styling_image_aspect_ratio',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<img src='example.jpg' style='aspect-ratio: 16/9;'>",
        tailwindcss:
          "<!-- Tailwind CSS example: Maintain aspect ratio -->\n<div class='aspect-w-16 aspect-h-9'>\n  <img src='example.jpg' alt='Example image' class='object-cover'>\n</div>",
      },
    },
    tableConfig: {
      title: 'The following images have incorrect aspect ratios:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Displayed Aspect Ratio', key: 'displayedAspectRatio', type: 'text' },
        { label: 'Actual Aspect Ratio', key: 'actualAspectRatio', type: 'text' },
        { label: 'Do Ratios Match?', key: 'doRatiosMatch', type: 'text' },
      ],
    },
  },
  [MetricId.IMAGE_REDUNDANT_ALT]: {
    title: 'Redundant Alt Text on Images',
    description:
      "Detects images with redundant `alt` text (such as 'image of' or 'picture of') that may reduce clarity for screen readers. Descriptive alt text improves accessibility by providing essential information without unnecessary details.",
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/image-redundant-alt',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove Redundant Terms from Alt Text',
      description:
        "Ensure `alt` text describes the image content directly without including redundant phrases like 'image of' or 'picture of'. This provides clearer information for screen readers.",
      additionalTips: [
        'Focus on describing the content or purpose of the image without stating that it is an image.',
        "For example, use `alt='Golden Retriever puppy'` instead of `alt='Image of a Golden Retriever puppy'.`",
        'Review all `alt` text on the page to ensure it is concise and descriptive.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-alt',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<img src='puppy.jpg' alt='Golden Retriever puppy'>",
      },
    },
    tableConfig: {
      title: 'The following images contain redundant alt text:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.IMAGE_SIZE_RESPONSIVE]: {
    title: 'Responsive Image Size',
    description:
      'Checks if images are sized appropriately for responsive layouts, ensuring they are displayed at an optimal resolution without unnecessary pixel density. This can improve load times and performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/serve-responsive-images/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Image Size for Responsive Design',
      description:
        'Ensure images are displayed at the correct size for their layout and screen resolution. Using responsive images reduces load time and improves visual quality across devices.',
      additionalTips: [
        'Use the `srcset` attribute to serve different image sizes based on screen resolution.',
        'Avoid using images with excessive resolution as it increases load time without improving quality.',
        'Consider using `sizes` in conjunction with `srcset` to optimize image display based on viewport width.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<img src='example.jpg' srcset='example-480w.jpg 480w, example-800w.jpg 800w' sizes='(max-width: 600px) 480px, 800px' alt='Responsive image example'>",
      },
    },
    tableConfig: {
      title: 'The following images are not sized responsively:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Displayed Size', key: 'displayedSize', type: 'text' },
        { label: 'Actual Size', key: 'actualSize', type: 'text' },
        { label: 'Expected Size', key: 'expectedSize', type: 'text' },
        { label: 'Actual Pixels', key: 'actualPixels', type: 'unitless' },
        { label: 'Expected Pixels', key: 'expectedPixels', type: 'unitless' },
      ],
    },
  },
  [MetricId.INPUT_BUTTON_NAME]: {
    title: 'Input Button Name',
    description:
      "Ensures that input buttons (e.g., `<input type='button'>`, `<input type='submit'>`) have accessible names, improving usability for screen reader users.",
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/button-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Input Buttons',
      description:
        'Provide an accessible name for each input button using `value` attributes or `aria-label`, helping screen readers convey the button’s purpose to users.',
      additionalTips: [
        'Use a descriptive `value` attribute directly in the `<input>` element for common input buttons.',
        'If more context is needed, consider adding an `aria-label` for improved accessibility.',
        'Ensure that input buttons used as icons or without visible text have clear and meaningful accessible names.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<input type='submit' value='Submit'>\n<input type='button' aria-label='Search' />",
      },
    },
    tableConfig: {
      title: 'The following input buttons lack accessible names:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.INPUT_IMAGE_ALT]: {
    title: 'Input Image Alt',
    description:
      "Checks if `<input type='image'>` elements have alternative text, providing accessible names for screen readers.",
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/image#accessibility_considerations',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Alt Text to Input Images',
      description:
        "Ensure that each `<input type='image'>` element has an `alt` attribute that describes its function. This helps screen readers convey the button’s purpose to users who may not see the image.",
      additionalTips: [
        "Use concise, descriptive text in the `alt` attribute to describe the button’s action, like 'Search' or 'Submit'.",
        "Avoid redundant terms like 'button' or 'image' in the `alt` text.",
        'If the image input is purely decorative, consider if it’s necessary or if an alternative element would be more appropriate.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://www.w3.org/WAI/tutorials/images/decorative/',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<input type='image' src='search-icon.png' alt='Search'>",
      },
    },
    tableConfig: {
      title: 'The following input images lack alt text:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.INSPECTOR_ISSUES]: {
    title: 'Inspector Issues',
    description:
      'Identifies issues detected by the browser’s Developer Tools Inspector, which can impact performance, security, or user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.chrome.com/docs/devtools/issues/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Resolve Inspector Issues',
      description:
        'Review and resolve issues identified by the Inspector to improve page performance, security, and user experience.',
      additionalTips: [
        'Use Developer Tools to inspect and resolve issues affecting the page.',
        'Address security-related issues, such as mixed content, as these can compromise user safety.',
        'Review warnings and errors for potential optimizations in loading and rendering.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Inspector issues found on the page:',
      columns: [{ label: 'Issue Type', key: 'issueType', type: 'text' }],
      subItemColumns: [{ label: 'Affected URL', key: 'url', type: 'link' }],
    },
  },
  [MetricId.INTERACTIVE]: {
    title: 'Time to Interactive (TTI)',
    description:
      'Measures the time taken for the page to become fully interactive, where the user can engage without significant delays. Optimizing TTI improves user experience, especially on resource-constrained devices.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/interactive/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Time to Interactive',
      description:
        'Minimize TTI by optimizing resources and deferring non-essential scripts. Ensuring that the main thread is free improves load responsiveness.',
      additionalTips: [
        'Reduce JavaScript execution time by breaking up long tasks and deferring non-critical scripts.',
        'Use code splitting to load only the necessary scripts initially.',
        'Optimize server response times and resource loading to reduce initial load.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "// Example: Deferring non-essential scripts\n<script src='non-critical.js' defer></script>",
      },
    },
  },
  [MetricId.IS_CRAWLABLE]: {
    title: 'Crawlability',
    description:
      'Checks if page content is accessible to search engine crawlers. Ensuring crawlability improves SEO by allowing search engines to index and rank your content.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/is-crawlable/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Content is Crawlable',
      description:
        'Verify that all important content is accessible to search engines. This may involve reviewing robots.txt settings and ensuring that key resources aren’t blocked.',
      additionalTips: [
        'Review robots.txt and meta tags to ensure essential pages are not blocked.',
        'Use the `noindex` directive only on pages that shouldn’t appear in search results.',
        'Test crawlability using tools like Google Search Console to identify blocked resources.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Non-crawlable resources:',
      columns: [
        { label: 'Source URL', key: 'source', subKey: 'url', type: 'link' },
        { label: 'Line', key: 'source', subKey: 'line', type: 'unitless' },
        { label: 'Column', key: 'source', subKey: 'column', type: 'unitless' },
      ],
    },
  },
  [MetricId.IS_ON_HTTPS]: {
    title: 'HTTPS Usage',
    description:
      'Checks if the page and its resources are served over HTTPS to ensure secure data transfer, improving security and user trust.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/is-on-https/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use HTTPS for All Resources',
      description:
        'Ensure that the page and all linked resources are served over HTTPS to protect data integrity and enhance security.',
      additionalTips: [
        'Verify that all resources, including images, scripts, and stylesheets, are loaded over HTTPS.',
        'Redirect HTTP requests to HTTPS to prevent mixed content warnings.',
        'Install an SSL certificate if your server does not already support HTTPS.',
      ],
      fixability: 'easy',
    },
    tableConfig: {
      title: 'Resources not served over HTTPS:',
      columns: [{ label: 'URL', key: 'url', type: 'link' }],
    },
  },
  [MetricId.LABEL]: {
    title: 'Form Element Label',
    description:
      'Ensures that form elements have associated labels to improve accessibility, helping screen readers convey the purpose of form fields to users.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/label',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Labels to Form Elements',
      description:
        'Ensure each form element has an associated label to improve accessibility and usability. Labels help screen readers and assistive technologies interpret form fields.',
      additionalTips: [
        'Use the `<label>` element with a `for` attribute pointing to the `id` of the form element.',
        'For complex form layouts, consider using `aria-labelledby` to link labels to form fields.',
        'Verify that every form element with user input has a descriptive label.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/forms/Basic_form_hints',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<label for='username'>Username</label>\n<input type='text' id='username' name='username'>",
      },
    },
    tableConfig: {
      title: 'Form elements missing labels:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LABEL_CONTENT_NAME_MISMATCH]: {
    title: 'Label Content Name Mismatch',
    description:
      'Ensures that visible label text matches the accessible name to improve accessibility and user understanding. Mismatched labels can confuse screen readers and other assistive technologies.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/label-content-name-mismatch',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Matching Accessible Names and Visible Labels',
      description:
        'Verify that the visible text of a label matches its accessible name to improve clarity for screen reader users.',
      additionalTips: [
        'Use the same wording for both the visible label text and accessible name.',
        'Avoid hidden or visually different text that could create mismatches between the label and accessible name.',
        'Test label consistency with screen readers to confirm compatibility.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<label for='search'>Search</label>\n<input type='text' id='search' aria-label='Search'>",
      },
    },
    tableConfig: {
      title: 'Elements with label content and accessible name mismatches:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LANDMARK_ONE_MAIN]: {
    title: 'One Main Landmark',
    description:
      'Ensures that each page contains exactly one `<main>` landmark, providing a clear and unique primary content region for screen readers and improving navigation.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/landmark-one-main',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Single Main Landmark',
      description:
        'Ensure that the page contains one `<main>` element to define the primary content region. This helps screen readers locate the main content easily.',
      additionalTips: [
        'Place a single `<main>` element to wrap the core content of the page.',
        'Avoid using multiple `<main>` elements, as this can confuse assistive technologies.',
        'Position the `<main>` element in a meaningful location to represent the primary content area.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/main',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '<main>\n  <!-- Main content goes here -->\n</main>',
      },
    },
    tableConfig: {
      title: 'Issues with main landmarks:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LARGEST_CONTENTFUL_PAINT]: {
    title: 'Largest Contentful Paint (LCP)',
    description:
      'Measures the time it takes for the largest content element to become visible, providing a key indicator of perceived load speed. An ideal LCP score is 2.5 seconds or less.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/lcp/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Improve Largest Contentful Paint',
      description:
        'To improve LCP, focus on optimizing the load time of above-the-fold content, especially large images or background elements that may slow down perceived load speed.',
      additionalTips: [
        'Ensure images are optimized and served in modern formats like WebP.',
        'Use a Content Delivery Network (CDN) to load resources faster from servers closer to users.',
        'Minimize render-blocking resources by deferring non-critical JavaScript and CSS.',
        'Consider lazy-loading below-the-fold content to prioritize visible content.',
      ],
      fixability: 'medium',
    },
    isCompositeMetric: true,
  },
  [MetricId.LAYOUT_SHIFTS]: {
    title: 'Layout Shifts',
    description:
      'Identifies unexpected layout shifts that occur during page load, impacting visual stability and user experience. Reducing layout shifts improves the page’s cumulative layout shift (CLS) score, a key web performance metric.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/cls/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minimize Layout Shifts',
      description:
        'Reduce layout shifts by reserving space for images, ads, and other dynamic content to prevent sudden content movement. This enhances user experience, particularly on mobile devices.',
      additionalTips: [
        'Set explicit width and height attributes for images and media to avoid layout shifts.',
        'Reserve space for dynamic content like ads to prevent them from pushing content around when they load.',
        'Load fonts and other resources asynchronously to reduce visual shifting during load.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Elements causing layout shifts:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'Score', key: 'score', type: 'unitless' },
      ],
      subItemColumns: [
        { label: 'Cause', key: 'cause', type: 'text' },
        { label: 'Extra', key: 'extra', subKey: 'value', type: 'link', colSpan: 2 },
      ],
    },
  },
  [MetricId.LCP_LAZY_LOADED]: {
    title: 'Largest Contentful Paint Element Lazy-Loaded',
    description:
      'Checks if the Largest Contentful Paint (LCP) element is lazy-loaded, which can delay its visibility and impact perceived performance. LCP elements should load without delay for optimal performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/lcp/#optimize-lcp',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Avoid Lazy-Loading the LCP Element',
      description:
        'Ensure that the element contributing to LCP is not lazy-loaded. Loading this element eagerly improves perceived load time and enhances user experience.',
      additionalTips: [
        'Identify the primary element that contributes to LCP and ensure it loads eagerly.',
        'Reserve lazy-loading for non-critical images or content that appears below the fold.',
        'Consider preloading images that are likely to contribute to LCP, especially on the homepage.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "<img src='hero.jpg' loading='eager' alt='Hero image'>",
      },
    },
    tableConfig: {
      title: 'LCP elements found to be lazy-loaded:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LEGACY_JAVASCRIPT]: {
    title: 'Legacy JavaScript',
    description:
      'Identifies legacy JavaScript syntax or polyfills that may be causing larger file sizes or slower performance. Modern JavaScript improves efficiency, especially on newer browsers.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/articles/publish-modern-javascript',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Replace Legacy JavaScript',
      description:
        'Refactor or replace legacy JavaScript to reduce file sizes and improve performance. Using modern syntax and avoiding unnecessary polyfills can optimize code execution.',
      additionalTips: [
        'Use Babel to transpile only necessary polyfills based on your target browsers.',
        'Review bundle analysis to identify and remove outdated libraries or polyfills.',
        'Test the application on modern browsers to confirm compatibility without legacy code.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Legacy JavaScript resources detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link', colSpan: 2 },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
      subItemColumns: [
        { label: 'Signal', key: 'signal', type: 'text' },
        { label: 'Source URL', key: 'location', subKey: 'url', type: 'link' },
        { label: 'Line', key: 'location', subKey: 'line', type: 'unitless' },
        { label: 'Column', key: 'location', subKey: 'column', type: 'unitless' },
      ],
    },
  },
  [MetricId.LINK_IN_TEXT_BLOCK]: {
    title: 'Link in Text Block',
    description:
      'Checks if links are placed within large text blocks without clear visual cues, which can make them harder for users to identify and interact with.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/link-in-text-block',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Enhance Link Visibility in Text Blocks',
      description:
        'Ensure links within text blocks have distinct styling, such as underlining or color contrast, to improve visibility and usability.',
      additionalTips: [
        'Use consistent and visible styles, like underline or color contrast, to differentiate links from regular text.',
        'Avoid placing links in dense paragraphs without visual indicators.',
        'Test link visibility in text blocks for readability and ease of access.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#styling_links',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<a href='https://example.com' style='text-decoration: underline;'>Example Link</a>",
      },
    },
    tableConfig: {
      title: 'Links in text blocks without visual indicators:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LINK_NAME]: {
    title: 'Link Name',
    description:
      'Ensures that each link has a descriptive name, allowing screen readers to convey the link’s purpose to users. Descriptive link names improve accessibility and usability.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/link-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Descriptive Names to Links',
      description:
        'Ensure each link has a descriptive name or text that accurately conveys its purpose. This helps screen readers and users understand the link’s destination.',
      additionalTips: [
        "Use meaningful text within links, avoiding generic phrases like 'click here' or 'read more.'",
        'If a link is represented by an icon, use `aria-label` or `aria-labelledby` to provide a descriptive name.',
        'Test links with screen readers to verify that the purpose is clear.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<a href='https://example.com' aria-label='Learn more about our services'>Learn More</a>",
      },
    },
    tableConfig: {
      title: 'Links missing descriptive names:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LINK_TEXT]: {
    title: 'Link Text',
    description:
      'Evaluates the text of links to ensure it is descriptive and meaningful, improving accessibility and user experience by providing clarity on link destinations.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/link-text/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Descriptive Link Text',
      description:
        "Ensure each link has meaningful text that reflects its destination, avoiding generic terms like 'click here.' Descriptive link text improves usability and accessibility.",
      additionalTips: [
        'Use unique, descriptive words in the link text that indicate the destination or purpose.',
        "Avoid using only generic terms like 'click here' or 'read more' without additional context.",
        'Test link text with screen readers to ensure clarity and ease of navigation.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<a href='https://example.com'>Learn more about our services</a>",
      },
    },
    tableConfig: {
      title: 'Links with unclear or generic text:',
      columns: [
        { label: 'Link URL', key: 'href', type: 'link' },
        { label: 'Link Text', key: 'text', type: 'text' },
      ],
    },
  },
  [MetricId.LIST]: {
    title: 'Semantic List Structure',
    description:
      'Ensures that lists are marked up semantically using `<ul>`, `<ol>`, and `<li>` elements to improve accessibility and content structure.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/list',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Proper List Elements',
      description:
        'Structure lists using semantic HTML elements like `<ul>`, `<ol>`, and `<li>` to enhance accessibility and readability. Avoid using plain paragraphs or divs to simulate lists.',
      additionalTips: [
        'Use `<ul>` for unordered lists and `<ol>` for ordered lists to reflect the list type.',
        'Ensure each list item is wrapped in an `<li>` element.',
        'Avoid using CSS to mimic list styles on non-list elements, as this can confuse screen readers.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ul',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '<ul>\n  <li>Item 1</li>\n  <li>Item 2</li>\n  <li>Item 3</li>\n</ul>',
      },
    },
    tableConfig: {
      title: 'Lists found without semantic structure:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LISTITEM]: {
    title: 'List Item Structure',
    description:
      'Ensures that list items are properly wrapped in `<li>` tags within `<ul>` or `<ol>` elements, enhancing accessibility and content structure.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/listitem',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Proper List Item Structure',
      description:
        'Wrap each list item in an `<li>` element and nest it within a `<ul>` or `<ol>` element. This helps screen readers interpret the list structure correctly.',
      additionalTips: [
        'Use `<li>` elements only within `<ul>` or `<ol>` containers.',
        'Avoid using non-semantic elements like `<div>` or `<span>` to create lists.',
        'Regularly review list structure to ensure that all items are contained within `<li>` tags.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/li',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '<ul>\n  <li>First item</li>\n  <li>Second item</li>\n  <li>Third item</li>\n</ul>',
      },
    },
    tableConfig: {
      title: 'Elements detected without proper list item structure:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.LONG_TASKS]: {
    title: 'Long Tasks',
    description:
      'Identifies long-running tasks that may block the main thread, impacting page responsiveness. Reducing long tasks can improve performance and user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/long-tasks-devtools/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Long-Running Tasks',
      description:
        'Break down long-running tasks or defer non-critical scripts to prevent blocking the main thread and improve responsiveness.',
      additionalTips: [
        'Use requestAnimationFrame or setTimeout to break up large tasks into smaller parts.',
        'Consider deferring or lazy-loading non-essential JavaScript to reduce main thread load.',
        'Optimize complex scripts and calculations to run asynchronously if possible.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          '// Example: Breaking up a long task\nfunction processData() {\n  for (let i = 0; i < largeDataSet.length; i++) {\n    if (i % 100 === 0) {\n      requestAnimationFrame(processData);\n      return;\n    }\n    // Process data...\n  }\n}',
      },
    },
    tableConfig: {
      title: 'Long tasks detected on the page:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Duration (ms)', key: 'duration', type: 'milliseconds' },
        { label: 'Start Time (ms)', key: 'startTime', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.MAIN_THREAD_WORK_BREAKDOWN]: {
    title: 'Main Thread Work Breakdown',
    description:
      'Analyzes the main thread work to identify areas that consume significant time, impacting performance. Optimizing main thread tasks can improve page responsiveness.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/mainthread-work-breakdown/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Main Thread Work',
      description:
        'Reduce time-consuming tasks on the main thread by deferring non-critical scripts and optimizing long-running processes. This improves the page’s responsiveness and load performance.',
      additionalTips: [
        'Minimize JavaScript execution time by deferring or lazy-loading non-essential scripts.',
        'Optimize large, synchronous tasks to run in smaller segments or asynchronously.',
        'Consider using Web Workers to offload complex calculations from the main thread.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Using a Web Worker to handle data processing\nconst worker = new Worker('worker.js');\nworker.postMessage(data);",
      },
    },
    tableConfig: {
      title: 'Main thread work breakdown:',
      columns: [
        { label: 'Task Group', key: 'groupLabel', type: 'text' },
        { label: 'Duration (ms)', key: 'duration', type: 'milliseconds' },
      ],
    },
    hidden: true,
  },
  [MetricId.MAX_POTENTIAL_FID]: {
    title: 'Max Potential First Input Delay (FID)',
    description:
      "Measures the maximum potential delay for a user's first interaction, which can impact the page’s responsiveness and perceived performance. An ideal FID is less than 100 milliseconds.",
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/fid/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce First Input Delay',
      description:
        'Optimize the main thread to reduce delay during user interactions. Minimizing heavy JavaScript execution can lower FID and improve responsiveness.',
      additionalTips: [
        'Break up long-running JavaScript tasks to prevent blocking the main thread.',
        'Use code splitting to load only the necessary scripts for initial interactions.',
        'Defer non-essential scripts and prioritize interactivity-related tasks.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "// Example: Deferring non-critical JavaScript\n<script src='non-critical.js' defer></script>",
      },
    },
  },
  [MetricId.META_DESCRIPTION]: {
    title: 'Meta Description',
    description:
      'Checks if the page includes a meta description tag to summarize content for search engines and users. A well-crafted meta description improves click-through rates and SEO.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/meta-description/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Meta Description',
      description:
        "Include a concise and descriptive `<meta name='description'>` tag to summarize the page content. Meta descriptions enhance search visibility and encourage user engagement.",
      additionalTips: [
        'Limit the meta description to around 150-160 characters for optimal display in search results.',
        'Use clear and compelling language to entice users to click through.',
        'Avoid keyword stuffing; focus on providing a natural, relevant summary of the page.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<meta name='description' content='A brief summary of your page content here.'>",
      },
    },
  },
  [MetricId.META_REFRESH]: {
    title: 'Meta Refresh',
    description:
      "Detects the use of the `<meta http-equiv='refresh'>` tag, which can automatically reload or redirect the page. Meta refresh can disrupt user experience and accessibility, especially for screen readers.",
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/meta-refresh/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Avoid Using Meta Refresh',
      description:
        "Remove or avoid using `<meta http-equiv='refresh'>` tags to reload or redirect pages. Instead, use server-side redirects or JavaScript for better control and accessibility.",
      additionalTips: [
        'For page redirects, use server-side HTTP redirects (301 or 302) instead of meta refresh.',
        'If reloading content is necessary, consider using JavaScript with user controls to refresh only specific elements.',
        'Meta refresh tags can disorient screen readers; avoid them for improved accessibility.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta#attr-http-equiv',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<!-- Avoid using meta refresh --><meta http-equiv='refresh' content='30'>",
      },
    },
    tableConfig: {
      title: 'Instances of meta refresh tags:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.META_VIEWPORT]: {
    title: 'Meta Viewport',
    description:
      "Checks if the page includes a `<meta name='viewport'>` tag, which is essential for responsive design on mobile devices. Properly configured viewport settings ensure the page displays correctly across screen sizes.",
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/meta-viewport/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Meta Viewport Tag',
      description:
        "Include a `<meta name='viewport'>` tag with appropriate settings to improve the page’s display on mobile devices. This helps ensure responsive layout and better usability.",
      additionalTips: [
        "Use `<meta name='viewport' content='width=device-width, initial-scale=1'>` to allow the page to scale properly on different devices.",
        'Avoid setting fixed-width values, as they can cause layout issues on smaller screens.',
        'Test the page on various devices to confirm the viewport settings improve the layout.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<meta name='viewport' content='width=device-width, initial-scale=1'>",
      },
    },
  },
  [MetricId.MODERN_IMAGE_FORMATS]: {
    title: 'Modern Image Formats',
    description:
      'Identifies images that could be optimized by using modern formats like WebP or AVIF, which offer better compression rates than JPEG or PNG, improving load times and performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.chrome.com/docs/lighthouse/performance/uses-webp-images',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Modern Image Formats',
      description:
        'Convert images to modern formats like WebP or AVIF to reduce file sizes without sacrificing quality. This improves load speed and reduces bandwidth usage.',
      additionalTips: [
        'Use WebP or AVIF for images to significantly reduce file sizes compared to JPEG and PNG.',
        'Consider serving images in multiple formats and using `picture` elements to deliver optimal formats based on browser support.',
        'Check image quality after conversion to ensure it meets visual requirements.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types',
          variant: 'neutral',
        },
        {
          label: 'Convert to WebP with Squoosh',
          url: 'https://squoosh.app/',
          variant: 'primary-button',
        },
      ],
      codeSnippets: {
        generic:
          "<picture>\n  <source srcset='example.webp' type='image/webp'>\n  <img src='example.jpg' alt='Example image'>\n</picture>",
        shell: 'convert example.png -quality 85 example.webp  # Using ImageMagick for PNG to WebP conversion',
      },
    },
  },
  [MetricId.NETWORK_REQUESTS]: {
    title: 'Network Requests',
    description:
      'Analyzes the network requests made by the page, providing insights into resource loading, file sizes, and request timings. Optimizing network requests can improve page load performance.',
    tableConfig: {
      title: 'Network requests details:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Session Target Type', key: 'sessionTargetType', type: 'text' },
        { label: 'Protocol', key: 'protocol', type: 'text' },
        { label: 'Renderer Start Time (ms)', key: 'rendererStartTime', type: 'milliseconds' },
        { label: 'Request Time (ms)', key: 'networkRequestTime', type: 'milliseconds' },
        { label: 'End Time (ms)', key: 'networkEndTime', type: 'milliseconds' },
        { label: 'Finished', key: 'finished', type: 'text' },
        { label: 'Transfer Size (bytes)', key: 'transferSize', type: 'bytes' },
        { label: 'Resource Size (bytes)', key: 'resourceSize', type: 'bytes' },
        { label: 'Status Code', key: 'statusCode', type: 'unitless' },
        { label: 'MIME Type', key: 'mimeType', type: 'text' },
        { label: 'Resource Type', key: 'resourceType', type: 'text' },
        { label: 'Priority', key: 'priority', type: 'text' },
        { label: 'Entity', key: 'entity', type: 'text' },
      ],
    },
    hidden: true,
  },
  [MetricId.NETWORK_RTT]: {
    title: 'Network Round-Trip Time (RTT)',
    description:
      'Measures the round-trip time (RTT) for network requests to various origins, providing insight into connection speed and latency. High RTT can indicate slower load times, especially for resource-heavy pages.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/time-to-first-byte/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Network Round-Trip Time',
      description:
        'Improving RTT can enhance page load speed by reducing latency for critical requests. Optimizing server response time and connection speed can directly lower RTT.',
      additionalTips: [
        'Use a Content Delivery Network (CDN) to serve content from locations closer to users.',
        'Minimize the number of round-trip requests required for key resources by bundling or combining files.',
        'Reduce payload size for critical resources to allow faster network transfer.',
        'Consider prefetching resources for repeat visits to speed up load times.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Round-trip times for network origins:',
      columns: [
        { label: 'Origin', key: 'origin', type: 'text' },
        { label: 'RTT (ms)', key: 'rtt', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.NETWORK_SERVER_LATENCY]: {
    title: 'Network Server Latency',
    description:
      'Measures the server response time for various origins, providing insight into backend performance and latency. High server latency can impact page load speed and user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/time-to-first-byte/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Server Latency',
      description:
        'Lowering server latency helps improve page load speed and overall user experience. Focus on optimizing backend performance and network response times.',
      additionalTips: [
        'Use a Content Delivery Network (CDN) to reduce latency by serving content from geographically closer servers.',
        'Optimize server processing by reducing complex database queries and streamlining backend workflows.',
        'Implement caching strategies for frequently accessed resources to reduce server load and speed up responses.',
        'Optimize server infrastructure and consider load balancing for better performance under high traffic.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Server response times for network origins:',
      columns: [
        { label: 'Origin', key: 'origin', type: 'text' },
        { label: 'Server Response Time (ms)', key: 'serverResponseTime', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.NO_DOCUMENT_WRITE]: {
    title: 'Avoid document.write()',
    description:
      'Checks for the use of `document.write()`, which can delay page load and cause performance issues. Avoiding `document.write()` helps improve loading speed and user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/no-document-write/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Replace document.write()',
      description:
        'Avoid using `document.write()` for injecting content. Instead, use modern alternatives like DOM manipulation methods to improve performance and reduce blocking.',
      additionalTips: [
        'Use DOM methods like `appendChild()` or `insertAdjacentHTML()` for dynamic content insertion.',
        'If necessary, load scripts asynchronously to avoid blocking the main thread.',
        'Test your page performance after removing `document.write()` to verify improvements.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/API/Document/write',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Instead of document.write()\ndocument.getElementById('content').insertAdjacentHTML('beforeend', '<p>Your content here</p>');",
      },
    },
    tableConfig: {
      title: 'Instances of document.write() usage:',
      columns: [{ label: 'Source', key: 'source', type: 'text' }],
    },
  },
  [MetricId.NON_COMPOSITED_ANIMATIONS]: {
    title: 'Non-Composited Animations',
    description:
      'Identifies animations that are not handled by the compositor, which can cause jank and affect smoothness. Using composited animations can improve performance, especially on resource-constrained devices.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/non-composited-animations/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Animations for Compositing',
      description:
        'Ensure animations are handled by the compositor by animating properties like `transform` and `opacity` instead of layout-related properties. This enhances performance and smoothness.',
      additionalTips: [
        'Animate properties that do not trigger layout recalculations, like `transform` and `opacity`.',
        'Avoid animating layout-affecting properties, such as `width`, `height`, `margin`, and `top`.',
        'Use `will-change: transform;` or `will-change: opacity;` sparingly to optimize for composited animations.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/will-change',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "/* Example: Optimizing for compositing */\n.element {\n  will-change: transform;\n}\n\n/* Animate transform property instead of position */\n.element.animate({ transform: 'translateX(100px)' }, { duration: 500 });",
      },
    },
    tableConfig: {
      title: 'Non-composited animations detected:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
      subItemColumns: [{ label: 'Failure Reason', key: 'failureReason', type: 'text' }],
    },
  },
  [MetricId.NOTIFICATION_ON_START]: {
    title: 'Notification Request on Page Load',
    description:
      'Detects if the page requests notification permissions on load, which can be intrusive and negatively impact user experience. Requesting notifications on user interaction is recommended.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/notification-on-start/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Request Notifications on User Interaction',
      description:
        'Prompt for notification permissions only after user interaction. This improves user experience by avoiding unexpected permission dialogs on page load.',
      additionalTips: [
        'Request notifications in response to a user action, such as a button click.',
        'Provide context for the notification request, explaining the benefits to the user.',
        'Avoid requesting notification permissions immediately upon page load, as this can cause users to deny the request.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Example: Requesting notifications on user interaction\nbutton.addEventListener('click', () => {\n  Notification.requestPermission().then(permission => {\n    if (permission === 'granted') {\n      // Show notification\n    }\n  });\n});",
      },
    },
    tableConfig: {
      title: 'Sources requesting notifications on page load:',
      columns: [{ label: 'Source', key: 'source', type: 'text' }],
    },
  },
  [MetricId.OBJECT_ALT]: {
    title: 'Object Element Alt Text',
    description:
      'Ensures that `<object>` elements include alternative text to improve accessibility. Alt text provides context for screen readers when embedded content cannot be displayed.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/object-alt',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Alternative Text to Object Elements',
      description:
        'Include alternative text for `<object>` elements to describe their content or purpose. This helps screen readers provide context to users who cannot view embedded content.',
      additionalTips: [
        'Use the `aria-label` attribute or include descriptive text within the `<object>` element.',
        'If the object is purely decorative, consider whether it is necessary for accessibility.',
        'Test with screen readers to ensure the alternative text provides clear and helpful information.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/object#accessibility_concerns',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<object data='chart.svg' aria-label='Sales chart for Q1'></object>",
      },
    },
    tableConfig: {
      title: 'Object elements missing alt text:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.OFFSCREEN_IMAGES]: {
    title: 'Offscreen Images',
    description:
      'Identifies images that are loaded but not visible in the initial viewport, which can slow down page load times. Deferring offscreen images can improve performance and reduce data usage.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/offscreen-images/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Defer Loading of Offscreen Images',
      description:
        'Use lazy-loading techniques to delay loading images that are initially offscreen, improving page load speed and reducing data usage.',
      additionalTips: [
        "Add the `loading='lazy'` attribute to defer loading of non-critical images.",
        'For images below the fold, consider using lazy-loading libraries if further control is needed.',
        'Monitor page performance after implementing lazy loading to ensure no layout shifts occur.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Performance/Lazy_loading',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<img src='example.jpg' loading='lazy' alt='Example image'>",
      },
    },
    tableConfig: {
      title: 'Offscreen images detected:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Wasted Bytes', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.PASTE_PREVENTING_INPUTS]: {
    title: 'Paste Prevention in Input Fields',
    description:
      'Detects input fields where pasting is disabled, which can hinder user experience. Allowing paste functionality in input fields improves accessibility and usability, especially for password and email fields.',
    links: [
      {
        label: 'Learn more',
        url: 'https://developer.chrome.com/docs/lighthouse/best-practices/paste-preventing-inputs',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Enable Pasting in Input Fields',
      description:
        'Allow users to paste into input fields to improve accessibility and usability. Restricting paste functionality can frustrate users and hinder input accuracy.',
      additionalTips: [
        'Enable pasting in sensitive fields like email and password fields to reduce user errors.',
        'If paste prevention is necessary for security, consider alternative methods to enhance usability.',
        'Test fields with accessibility tools to ensure compatibility and ease of use.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Example: Allow pasting in input fields\ndocument.querySelectorAll('input').forEach(input => {\n  input.addEventListener('paste', (e) => {\n    // Logic to handle paste\n  });\n});",
      },
    },
    tableConfig: {
      title: 'Inputs preventing paste functionality:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'Input Type', key: 'type', type: 'text' },
      ],
    },
  },
  [MetricId.PRIORITIZE_LCP_IMAGE]: {
    title: 'Prioritize Largest Contentful Paint Image',
    description:
      'Identifies the main image contributing to Largest Contentful Paint (LCP) and checks if it is prioritized for loading. Properly prioritizing the LCP image can significantly improve perceived load time.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/optimize-lcp/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Prioritize LCP Image Loading',
      description:
        'Ensure the LCP image loads quickly by using `preload` and optimizing for faster rendering. This can improve initial page load and enhance user experience.',
      additionalTips: [
        "Use `<link rel='preload'>` for the LCP image to prioritize its loading.",
        'Compress and optimize the LCP image to reduce load time.',
        'Consider using responsive image techniques to serve the most appropriate image size for different devices.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Preloading_content',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<link rel='preload' href='path-to-lcp-image.jpg' as='image'>",
      },
    },
    tableConfig: {
      title: 'LCP images to prioritize:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Potential Savings (ms)', key: 'wastedMs', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.REDIRECTS]: {
    title: 'Reduce Redirects',
    description:
      'Identifies unnecessary redirects that can delay page load times. Reducing redirects improves performance, especially on mobile networks.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/redirects/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minimize or Eliminate Redirects',
      description:
        'Avoid unnecessary redirects to improve load speed. Direct users to the final URL and reduce redirect chains whenever possible.',
      additionalTips: [
        'Set up server redirects directly to the final URL, avoiding intermediate steps.',
        'Use responsive design techniques to serve the same URL across devices instead of separate mobile redirects.',
        'Check analytics to ensure outdated URLs redirect efficiently without chaining.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          '// Example of setting a single redirect\nRewriteEngine On\nRewriteRule ^old-page$ /new-page [R=301,L]',
      },
    },
    tableConfig: {
      title: 'Redirects causing performance delays:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Wasted Time (ms)', key: 'wastedMs', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.REDIRECTS_HTTP]: {
    title: 'Avoid HTTP Redirects',
    description:
      'Detects redirects from HTTP to HTTPS, which can delay page load times. Redirecting traffic directly to HTTPS improves performance and security.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/redirects/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Directly Serve HTTPS URLs',
      description:
        'Configure the server to serve HTTPS URLs directly, bypassing HTTP redirects. This minimizes redirect latency and enhances both security and performance.',
      additionalTips: [
        'Update links and bookmarks to use HTTPS URLs, avoiding the need for HTTP redirects.',
        'Set up HSTS (HTTP Strict Transport Security) to enforce HTTPS connections without requiring redirects.',
        'Ensure that all resources (scripts, styles, images) are also requested over HTTPS to prevent mixed content issues.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Strict-Transport-Security',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          '# Example of redirecting all HTTP traffic to HTTPS\nRewriteEngine On\nRewriteCond %{HTTPS} !=on\nRewriteRule ^ https://%{HTTP_HOST}%{REQUEST_URI} [L,R=301]',
      },
    },
  },
  [MetricId.RENDER_BLOCKING_RESOURCES]: {
    title: 'Render-Blocking Resources',
    description:
      'Identifies resources that block the page from rendering quickly, such as CSS or JavaScript files. Minimizing or deferring render-blocking resources can improve load speed.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/render-blocking-resources/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minimize Render-Blocking Resources',
      description:
        'Defer or asynchronously load resources that block rendering. Optimizing these resources improves load performance and reduces initial display time.',
      additionalTips: [
        'Use `async` or `defer` for non-critical JavaScript to prevent it from blocking rendering.',
        'Inline critical CSS to avoid loading external files for initial styling.',
        'Consider splitting large CSS files and loading non-critical parts only after the page has loaded.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic: "<script src='script.js' async></script>",
      },
    },
    tableConfig: {
      title: 'Render-blocking resources detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (ms)', key: 'wastedMs', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.ROBOTS_TXT]: {
    title: 'Robots.txt',
    description:
      'Analyzes the `robots.txt` file to check for any issues that may impact search engine crawling and indexing. A well-configured `robots.txt` file is essential for controlling which parts of the site search engines can access.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/robots-txt',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Robots.txt File',
      description:
        'Review and configure the `robots.txt` file to ensure it allows essential pages to be crawled while blocking sensitive or irrelevant content.',
      additionalTips: [
        'Ensure critical pages are not unintentionally blocked by the `robots.txt` file.',
        'Use `Disallow` to restrict access to private or non-public sections of the site.',
        'Validate the syntax and structure of `robots.txt` to avoid accidental errors.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://www.robotstxt.org/robotstxt.html',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '# Example robots.txt\nUser-agent: *\nDisallow: /private/\nAllow: /public/',
      },
    },
    tableConfig: {
      title: 'Issues found in robots.txt:',
      columns: [
        { label: 'Line Number', key: 'index', type: 'unitless' },
        { label: 'Line Content', key: 'line', type: 'text' },
        { label: 'Message', key: 'message', type: 'text' },
      ],
    },
  },
  [MetricId.SELECT_NAME]: {
    title: 'Select Element Name',
    description:
      'Ensures that `<select>` elements have accessible names, helping screen readers and other assistive technologies convey the purpose of the selection input to users.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/select-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Accessible Names to Select Elements',
      description:
        'Ensure each `<select>` element has an accessible name, either through a visible `<label>` or an `aria-label` attribute. This helps users understand the purpose of the select input.',
      additionalTips: [
        "Use a `<label>` element with a `for` attribute linked to the `<select>` element's `id`.",
        'If a visible label is not possible, add an `aria-label` or `aria-labelledby` attribute to provide an accessible name.',
        'Test with screen readers to ensure that each select element’s purpose is clearly announced.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/forms/Basic_form_hints',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<label for='options'>Choose an option:</label>\n<select id='options'>\n  <option value='1'>Option 1</option>\n  <option value='2'>Option 2</option>\n</select>",
      },
    },
    tableConfig: {
      title: 'Select elements missing accessible names:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.SERVER_RESPONSE_TIME]: {
    title: 'Server Response Time',
    description:
      'Measures the server response time for requests. Reducing server response times can improve page load speed, particularly for users on slower networks.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/time-to-first-byte/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Server Response Time',
      description:
        'Optimize server response times to improve load performance. Slow server responses can delay page loading, so minimizing response times enhances user experience.',
      additionalTips: [
        'Use caching to reduce load on the server and deliver content faster.',
        'Optimize database queries and reduce server-side processing time.',
        'Consider using a Content Delivery Network (CDN) to serve content more quickly to global users.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Set up caching for faster responses\napp.use((req, res, next) => {\n  res.set('Cache-Control', 'public, max-age=3600');\n  next();\n});",
      },
    },
    tableConfig: {
      title: 'Server response times by URL:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Response Time (ms)', key: 'responseTime', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.SKIP_LINK]: {
    title: 'Skip Link',
    description:
      "Ensures the presence of a 'skip link' to allow keyboard users to bypass repetitive navigation and access main content directly, improving accessibility.",
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/skip-link',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add a Skip Link',
      description:
        "Provide a 'skip link' at the beginning of the page to allow keyboard users to bypass navigation and go directly to the main content area. This improves accessibility and ease of navigation.",
      additionalTips: [
        'Place the skip link as the first focusable element on the page to make it easily accessible for keyboard users.',
        'Ensure the skip link is visible when focused but can be visually hidden otherwise.',
        "Use `href='#main-content'` in the skip link to direct users to the main content area.",
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets#skip_links',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<a href='#main-content' class='skip-link'>Skip to main content</a>\n\n<main id='main-content'>\n  <!-- Main content here -->\n</main>",
      },
    },
    tableConfig: {
      title: 'Missing or non-functional skip links:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.SPEED_INDEX]: {
    title: 'Speed Index',
    description:
      'Measures how quickly content is visually displayed during page load. Speed Index provides an overall sense of loading performance and user experience, especially on slower networks.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/speed-index/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Improve Speed Index',
      description:
        'To improve Speed Index, focus on optimizing how quickly the page’s visible content is rendered, particularly for above-the-fold elements.',
      additionalTips: [
        'Prioritize rendering critical content by deferring non-essential scripts and styles.',
        'Use lazy loading for images and other below-the-fold resources to improve perceived load speed.',
        'Ensure fonts are loaded quickly by preloading key fonts and using `font-display: swap`.',
        'Minify and compress CSS and JavaScript to reduce resource sizes and improve download speed.',
      ],
      fixability: 'medium',
    },
    isCompositeMetric: true,
  },
  [MetricId.TABINDEX]: {
    title: 'Tabindex Usage',
    description:
      'Ensures appropriate use of the `tabindex` attribute to maintain logical keyboard navigation order. Misusing `tabindex` can disrupt accessibility and user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/tabindex',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Tabindex for Accessible Navigation',
      description:
        'Use `tabindex` carefully to ensure a logical and accessible navigation order. Avoid values greater than zero, as they can interfere with natural tabbing order.',
      additionalTips: [
        "Use `tabindex='0'` to include elements in the tab order without changing their position.",
        'Avoid using positive `tabindex` values, as this can create a confusing experience for keyboard users.',
        'Remove `tabindex` attributes from elements that do not need to be focusable.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<button tabindex='0'>Click me</button>\n<div tabindex='-1'>Non-focusable element</div>",
      },
    },
    tableConfig: {
      title: 'Elements with potentially problematic tabindex values:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TABLE_DUPLICATE_NAME]: {
    title: 'Duplicate Table Name',
    description:
      'Detects tables with duplicate or identical names, which can confuse screen readers and impair accessibility. Each table should have a unique name or label to provide context.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/table-duplicate-name',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Unique Table Names',
      description:
        'Provide a unique name or label for each table to improve accessibility for screen readers. This helps users differentiate between tables with similar content.',
      additionalTips: [
        'Use the `aria-label` or `aria-labelledby` attributes to give each table a unique identifier.',
        'If tables are visually distinct but have similar content, ensure each label reflects its specific context.',
        'Test with screen readers to verify that each table’s name provides clear, distinguishing context.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Table_Role',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<table aria-label='Sales Data Q1'>\n  <!-- Table content -->\n</table>\n<table aria-label='Sales Data Q2'>\n  <!-- Table content -->\n</table>",
      },
    },
    tableConfig: {
      title: 'Tables with duplicate names:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TABLE_FAKE_CAPTION]: {
    title: 'Fake Table Caption',
    description:
      'Detects tables that use non-semantic elements to simulate captions. Proper captions improve accessibility by helping screen readers understand the table’s purpose.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/table-fake-caption',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Proper Table Captions',
      description:
        'Use the `<caption>` element to provide a descriptive title for each table. This ensures that screen readers and assistive technologies can interpret the table’s purpose accurately.',
      additionalTips: [
        'Place the `<caption>` element as the first child of the `<table>` element.',
        'Avoid using `<div>` or `<p>` elements styled to look like captions.',
        'Ensure the caption text is concise and descriptive, summarizing the table’s content.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/caption',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: '<table>\n  <caption>Quarterly Sales Data</caption>\n  <!-- Table content -->\n</table>',
      },
    },
    tableConfig: {
      title: 'Tables using fake captions:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TARGET_SIZE]: {
    title: 'Interactive Target Size',
    description:
      'Checks if interactive elements, like buttons and links, meet minimum size requirements for easy accessibility on touch screens. Small target sizes can hinder usability, especially on mobile devices.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/target-size',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Adequate Target Size for Interactive Elements',
      description:
        'Increase the size of interactive elements to at least 44x44 pixels for better accessibility, especially on mobile devices. Larger target sizes improve usability and reduce interaction errors.',
      additionalTips: [
        'Aim for a minimum target size of 44x44 pixels for touch-friendly elements.',
        'Ensure there’s adequate spacing around buttons and links to avoid accidental clicks.',
        'Regularly test the design on touch devices to ensure target sizes are accessible.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic: "<button style='width: 44px; height: 44px;'>Click Me</button>",
      },
    },
    tableConfig: {
      title: 'Interactive elements with insufficient target size:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TD_HAS_HEADER]: {
    title: 'Table Cell Header Association',
    description:
      'Ensures that `<td>` cells in tables are correctly associated with corresponding `<th>` headers. Proper header associations improve accessibility by helping screen readers convey table structure and content.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/td-has-header',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Associate Table Cells with Headers',
      description:
        'Ensure each `<td>` element is correctly associated with a corresponding `<th>` element. Use `scope` or `headers` attributes to establish a clear relationship between data cells and headers.',
      additionalTips: [
        "For simple tables, use `<th scope='col'>` or `<th scope='row'>` to associate headers directly with rows or columns.",
        'For complex tables, use the `headers` attribute on `<td>` cells to link them to specific `<th>` elements.',
        'Verify header associations with screen readers to ensure table content is announced correctly.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th#attr-scope',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<table>\n  <tr>\n    <th scope='col'>Name</th>\n    <th scope='col'>Age</th>\n  </tr>\n  <tr>\n    <td headers='name'>Alice</td>\n    <td headers='age'>30</td>\n  </tr>\n</table>",
      },
    },
    tableConfig: {
      title: 'Table cells missing header associations:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TD_HEADERS_ATTR]: {
    title: 'Table Cell Headers Attribute',
    description:
      'Ensures that `<td>` elements use the `headers` attribute to correctly associate data cells with their corresponding headers in complex tables. Proper header associations improve accessibility for screen readers.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/td-headers-attr',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Headers Attribute for Table Cell Associations',
      description:
        'Use the `headers` attribute on `<td>` elements to establish clear relationships with corresponding `<th>` headers in complex tables. This helps screen readers interpret the table structure accurately.',
      additionalTips: [
        'Ensure each `<td>` element in a complex table has a `headers` attribute pointing to the `id` of its associated `<th>` elements.',
        'Use unique `id` attributes on `<th>` elements for clear associations with `<td>` cells.',
        'Test with screen readers to verify that table content is read in a logical order.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/td#attr-headers',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<table>\n  <tr>\n    <th id='name'>Name</th>\n    <th id='age'>Age</th>\n  </tr>\n  <tr>\n    <td headers='name'>Alice</td>\n    <td headers='age'>30</td>\n  </tr>\n</table>",
      },
    },
    tableConfig: {
      title: 'Table cells missing headers attribute associations:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.TH_HAS_DATA_CELLS]: {
    title: 'Table Header Has Data Cells',
    description:
      'Ensures that `<th>` elements are associated with corresponding `<td>` data cells. Properly linked headers and data cells improve accessibility and table comprehension for screen reader users.',
    links: [
      {
        label: 'Learn more',
        url: 'https://dequeuniversity.com/rules/axe/4.10/th-has-data-cells',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Associate Table Headers with Data Cells',
      description:
        'Verify that each `<th>` element is associated with corresponding `<td>` cells to enhance accessibility. Use the `scope` attribute or proper table structure to ensure meaningful associations.',
      additionalTips: [
        'In simple tables, use the `scope` attribute on `<th>` elements to indicate whether they apply to rows or columns.',
        'For more complex tables, ensure that headers have meaningful relationships with data cells, either through table structure or by using IDs and headers.',
        'Check table structure with screen readers to confirm proper reading order.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/th#attr-scope',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<table>\n  <tr>\n    <th scope='col'>Name</th>\n    <th scope='col'>Age</th>\n  </tr>\n  <tr>\n    <td>Alice</td>\n    <td>30</td>\n  </tr>\n</table>",
      },
    },
    tableConfig: {
      title: 'Headers missing associated data cells:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        {
          label: 'Explanation',
          key: 'node',
          subKey: 'explanation',
          type: 'text',
        },
      ],
    },
  },
  [MetricId.THIRD_PARTY_COOKIES]: {
    title: 'Third-Party Cookies',
    description:
      'Identifies third-party cookies that may impact privacy and performance. Limiting third-party cookies can improve load times and reduce data privacy concerns.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/articles/understanding-cookies',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Limit Third-Party Cookies',
      description:
        'Consider limiting or removing third-party cookies to improve privacy and performance. Evaluate the necessity of each third-party service that sets cookies.',
      additionalTips: [
        'Review third-party services and remove unnecessary cookies to enhance user privacy.',
        'Use consent management tools to allow users control over third-party cookies.',
        'Consider using first-party cookies for essential functionality where possible.',
      ],
      fixability: 'medium',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Third-party cookies detected:',
      columns: [
        { label: 'Cookie Name', key: 'name', type: 'text' },
        { label: 'Source URL', key: 'url', type: 'link' },
      ],
    },
  },
  [MetricId.THIRD_PARTY_FACADES]: {
    title: 'Third-Party Facades',
    description:
      'Analyzes the impact of third-party scripts and services on page performance, identifying large or blocking resources. Optimizing third-party resources can reduce load times and improve user experience.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/third-party-facades/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Third-Party Resources',
      description:
        'Consider deferring, lazy-loading, or implementing facades for third-party resources that impact performance. Reducing the load and execution time of third-party scripts can improve page speed.',
      additionalTips: [
        'Implement lazy-loading or conditional loading for third-party resources to avoid blocking the main thread.',
        'Use facades or placeholders that load third-party scripts only when needed (e.g., after user interaction).',
        'Monitor and audit third-party scripts regularly to identify those that can be removed or optimized.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Third-party resources impacting performance:',
      columns: [
        { label: 'Product', key: 'product', type: 'text', colSpan: 2 },
        { label: 'Transfer Size (bytes)', key: 'transferSize', type: 'bytes' },
        { label: 'Blocking Time (ms)', key: 'blockingTime', type: 'milliseconds', colSpan: 2 },
      ],
      subItemColumns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Main Thread Time (ms)', key: 'mainThreadTime', type: 'milliseconds' },
        { label: 'Transfer Size (bytes)', key: 'transferSize', type: 'bytes' },
        { label: 'Blocking Time (ms)', key: 'blockingTime', type: 'milliseconds' },
        { label: 'TBT Impact (ms)', key: 'tbtImpact', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.THIRD_PARTY_SUMMARY]: {
    title: 'Third-Party Summary',
    description:
      'Summarizes the impact of third-party scripts and services on main thread time, transfer size, and blocking time. Understanding the load caused by third-party resources can guide performance optimizations.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/third-party-summary/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minimize Impact of Third-Party Resources',
      description:
        'Reduce the performance impact of third-party resources by lazy-loading, deferring, or limiting non-critical scripts. Managing third-party scripts can significantly improve page speed and user experience.',
      additionalTips: [
        'Consider loading third-party scripts asynchronously or using a facade to delay loading until needed.',
        'Audit third-party services regularly to identify high-impact resources.',
        'Evaluate if certain third-party services can be self-hosted to reduce load time.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Third-party services and their performance impact:',
      columns: [
        { label: 'Entity', key: 'entity', type: 'text' },
        { label: 'Main Thread Time (ms)', key: 'mainThreadTime', type: 'milliseconds' },
        { label: 'Transfer Size (bytes)', key: 'transferSize', type: 'bytes' },
        { label: 'Blocking Time (ms)', key: 'blockingTime', type: 'milliseconds' },
        { label: 'TBT Impact (ms)', key: 'tbtImpact', type: 'milliseconds' },
      ],
      subItemColumns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: '', key: 'mainThreadTime', type: 'milliseconds' },
        { label: '', key: 'transferSize', type: 'bytes' },
        { label: '', key: 'blockingTime', type: 'milliseconds' },
        { label: '', key: 'tbtImpact', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.TOTAL_BLOCKING_TIME]: {
    title: 'Total Blocking Time (TBT)',
    description:
      'Measures the total time during which the main thread was blocked, preventing user interaction. A lower TBT score indicates better responsiveness, especially during load.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/tbt/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Reduce Total Blocking Time',
      description:
        'Minimize tasks that block the main thread to improve responsiveness and reduce TBT, especially during page load.',
      additionalTips: [
        'Break up long-running JavaScript tasks to avoid blocking the main thread.',
        'Defer or async-load non-critical JavaScript to prevent render-blocking.',
        "Use web workers for resource-intensive scripts that don't require access to the DOM.",
        'Optimize third-party scripts by loading them asynchronously or moving them to later in the load sequence.',
      ],
      fixability: 'medium',
    },
    isCompositeMetric: true,
  },
  [MetricId.TOTAL_BYTE_WEIGHT]: {
    title: 'Total Byte Weight',
    description:
      'Measures the total amount of data transferred, including all resources on the page. Reducing the total byte weight can improve load speed, especially for users on slower networks.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/total-byte-weight/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Total Byte Weight',
      description:
        'Reduce the total byte weight by compressing and optimizing resources, such as images, scripts, and stylesheets. Minimizing resource sizes improves performance.',
      additionalTips: [
        'Compress images using modern formats like WebP or AVIF.',
        'Minify and bundle CSS and JavaScript files to reduce file size.',
        'Enable server-side compression (e.g., GZIP or Brotli) for text-based resources.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'Resources contributing to total byte weight:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.UNMINIFIED_CSS]: {
    title: 'Unminified CSS',
    description:
      'Identifies CSS files that are not minified, which can increase load times. Minifying CSS reduces file size and improves page performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/unminified-css/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minify CSS Files',
      description:
        'Reduce CSS file size by minifying it. Minification removes unnecessary whitespace and comments, speeding up file download times.',
      additionalTips: [
        'Use CSS minification tools (e.g., [cssnano](https://cssnano.co/), [CleanCSS](https://clean-css.github.io/)) to automate the process.',
        'Ensure that minification is part of your build process to keep production files optimized.',
        'Check performance after minifying CSS to confirm file size reductions.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/CSS/Optimization',
          variant: 'primary',
        },
        {
          label: 'CleanCSS',
          url: 'https://clean-css.github.io/',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Example: Using cssnano for minification\nconst cssnano = require('cssnano');\ncssnano.process(css).then(result => {\n  console.log(result.css);\n});",
      },
    },
    tableConfig: {
      title: 'Unminified CSS resources detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.UNMINIFIED_JAVASCRIPT]: {
    title: 'Unminified JavaScript',
    description:
      'Identifies JavaScript files that are not minified, which can increase load times. Minifying JavaScript reduces file size and improves page performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/unminified-javascript/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Minify JavaScript Files',
      description:
        'Reduce JavaScript file size by minifying it. Minification removes unnecessary whitespace and comments, improving file download speed.',
      additionalTips: [
        'Use JavaScript minification tools like [UglifyJS](https://www.npmjs.com/package/uglify-js) or [Terser](https://www.npmjs.com/package/terser) in your build process.',
        'Ensure minification is automated in production environments for optimal performance.',
        'Check overall file size after minifying to verify performance improvements.',
      ],
      fixability: 'easy',
      codeSnippets: {
        generic:
          "// Example: Using Terser for JavaScript minification\nconst Terser = require('terser');\nconst result = Terser.minify(jsCode);\nconsole.log(result.code);",
      },
    },
    tableConfig: {
      title: 'Unminified JavaScript resources detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.UNUSED_CSS_RULES]: {
    title: 'Unused CSS Rules',
    description:
      'Identifies CSS files that contain unused rules, which can increase load times. Removing unused CSS can reduce file size and improve page performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/unused-css-rules/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove Unused CSS Rules',
      description:
        'Audit and remove unused CSS rules to reduce file size and improve performance. Keeping only necessary CSS helps to optimize page load times.',
      additionalTips: [
        'Use tools like [PurgeCSS](https://purgecss.com/) or [UnCSS](https://uncss-online.com/) to automatically identify and remove unused CSS.',
        'Ensure that only the CSS needed for current page styles is loaded.',
        'Regularly audit CSS files to prevent accumulation of unused styles over time.',
      ],
      fixability: 'medium',
      codeSnippets: {
        generic:
          "// Example: Using PurgeCSS with a build tool\nconst purgecss = require('@fullhuman/postcss-purgecss');\n\nmodule.exports = {\n  plugins: [\n    purgecss({ content: ['./src/**/*.html'] })\n  ]\n};",
      },
    },
    tableConfig: {
      title: 'CSS resources with unused rules detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.UNSIZED_IMAGES]: {
    title: 'Unsized Images',
    description:
      'Detects images without explicit width and height attributes. Specifying image dimensions prevents layout shifts and improves performance by allowing the browser to allocate space for images during loading.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/optimize-cls/#images-without-dimensions',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Set Explicit Width and Height for Images',
      description:
        'Add width and height attributes to images to prevent layout shifts and improve loading stability. Explicit sizing helps the browser allocate space for images, reducing layout shifts as the page loads.',
      additionalTips: [
        'Include `width` and `height` attributes on all images to prevent cumulative layout shifts (CLS).',
        'For responsive images, use CSS to set max-width to 100% for flexibility, while maintaining explicit HTML dimensions.',
        'Test pages after setting image dimensions to ensure that layout shifts are minimized.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-width',
          variant: 'neutral',
        },
        {
          label: 'Optimize with Squoosh',
          url: 'https://squoosh.app/',
          variant: 'primary',
        },
      ],
      codeSnippets: {
        generic: "<img src='example.jpg' width='600' height='400' alt='Example image'>",
      },
    },
    tableConfig: {
      title: 'Images without specified dimensions:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        { label: 'Label', key: 'node', subKey: 'nodeLabel', type: 'text' },
        { label: 'URL', key: 'url', type: 'link' },
      ],
    },
  },
  [MetricId.UNUSED_JAVASCRIPT]: {
    title: 'Unused JavaScript',
    description:
      'Identifies JavaScript files that contain unused code, which can increase load times and negatively impact performance. Removing unused JavaScript can reduce file size and improve page speed.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/unused-javascript/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Remove Unused JavaScript',
      description:
        'Audit and remove unused JavaScript to reduce file size and improve performance. Keeping only essential JavaScript helps to optimize page load times.',
      additionalTips: [
        'Use tools like [Webpack](https://webpack.js.org/) or [Rollup](https://rollupjs.org/) to analyze and tree-shake unused code.',
        "Consider splitting code and loading only what's necessary for initial render.",
        'Audit JavaScript dependencies to avoid loading unused libraries or functions.',
      ],
      fixability: 'medium',
    },
    tableConfig: {
      title: 'JavaScript resources with unused code:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
      subItemColumns: [
        { label: 'Source', key: 'source', type: 'text' },
        { label: '', key: 'sourceBytes', type: 'bytes' },
        { label: '', key: 'sourceWastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.USES_HTTP2]: {
    title: 'Uses HTTP/2',
    description:
      'Identifies resources that are not served over HTTP/2. Upgrading to HTTP/2 can improve load performance by enabling multiplexing, header compression, and prioritization.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-http2/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Upgrade to HTTP/2',
      description:
        'Serve resources over HTTP/2 to improve loading speed and efficiency. HTTP/2 enables multiple resources to load over a single connection, reducing latency and enhancing performance.',
      additionalTips: [
        'Check with your hosting provider to ensure HTTP/2 is enabled.',
        'Ensure your server supports HTTP/2 and consider upgrading if necessary.',
        'Monitor performance after switching to HTTP/2 to assess improvements in load speed.',
      ],
      fixability: 'easy',
    },
    tableConfig: {
      title: 'Resources not served over HTTP/2:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Protocol', key: 'protocol', type: 'text' },
      ],
    },
  },
  [MetricId.USES_LONG_CACHE_TTL]: {
    title: 'Uses Long Cache TTL',
    description:
      'Identifies resources with short cache lifetimes. Extending cache durations for static resources can improve load performance and reduce network requests on repeat visits.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-long-cache-ttl/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Extend Cache Duration for Static Resources',
      description:
        'Set longer cache durations for static resources to reduce redundant network requests on repeat visits, enhancing load performance.',
      additionalTips: [
        'Use `Cache-Control` headers with a `max-age` directive to define cache durations.',
        'For content that rarely changes, consider a cache lifetime of one year.',
        'Review and update caching policies to balance performance and content freshness.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Resources with short cache TTL:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Cache Lifetime (ms)', key: 'cacheLifetimeMs', type: 'milliseconds' },
        { label: 'Cache Hit Probability', key: 'cacheHitProbability', type: 'unitless' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Wasted Bytes', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.USES_OPTIMIZED_IMAGES]: {
    title: 'Uses Optimized Images',
    description:
      'Identifies images that are not optimized, which can increase load times and consume more bandwidth. Optimizing images reduces file size and improves performance.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-optimized-images/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Images for Better Performance',
      description: 'Compress and optimize images to reduce file size, improve load times, and enhance user experience.',
      additionalTips: [
        'Use tools like [ImageOptim](https://imageoptim.com/), [TinyPNG](https://tinypng.com/), or [Squoosh](https://squoosh.app/) to compress images.',
        'Convert images to modern formats like WebP or AVIF for better compression.',
        'Ensure image dimensions are set to avoid loading larger-than-necessary files.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types',
          variant: 'neutral',
        },
        {
          label: 'Optimize with Squoosh',
          url: 'https://squoosh.app/',
          variant: 'primary',
        },
      ],
      codeSnippets: {
        generic: "<img src='optimized-example.webp' alt='Optimized example image'>",
      },
    },
    tableConfig: {
      title: 'Unoptimized images detected:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.USES_PASSIVE_EVENT_LISTENERS]: {
    title: 'Uses Passive Event Listeners',
    description:
      'Checks if event listeners that don’t call `preventDefault()` are marked as passive, which can improve scroll performance by allowing the browser to optimize handling.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-passive-event-listeners/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Mark Event Listeners as Passive',
      description:
        'Mark scroll, touch, and wheel event listeners as passive to improve performance. This allows the browser to process these events more efficiently.',
      additionalTips: [
        'Use `{ passive: true }` in event listeners that don’t call `preventDefault()`.',
        'Adding `{ passive: true }` is particularly effective for touch and wheel events on mobile.',
        'Test for smoothness on scroll-heavy pages after applying passive listeners.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#parameters',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "// Example: Adding a passive event listener\nwindow.addEventListener('scroll', handleScroll, { passive: true });",
      },
    },
    tableConfig: {
      title: 'Event listeners that could benefit from passive optimization:',
      columns: [
        { label: 'Source URL', key: 'source', subKey: 'url', type: 'link' },
        { label: 'Provider', key: 'source', subKey: 'urlProvider', type: 'text' },
        { label: 'Line', key: 'source', subKey: 'line', type: 'unitless' },
        { label: 'Column', key: 'source', subKey: 'column', type: 'unitless' },
      ],
    },
  },
  [MetricId.USES_REL_PRECONNECT]: {
    title: 'Uses rel=preconnect',
    description:
      'Identifies external resources that could benefit from `rel=preconnect` to establish early connections, reducing latency and improving load times.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-rel-preconnect/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Preconnect for Faster Resource Loading',
      description:
        'Add `rel=preconnect` to establish early connections to critical third-party resources. This reduces latency by opening a connection before the resource is requested.',
      additionalTips: [
        "Use `<link rel='preconnect' href='https://example.com'>` in the document head for third-party resources.",
        'Prioritize preconnecting to origins that serve essential resources like fonts or critical scripts.',
        'Monitor network performance to ensure preconnect optimizations are effective.',
      ],
      fixability: 'easy',
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/Performance/dns-prefetch',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<link rel='preconnect' href='https://example.com'>",
      },
    },
    tableConfig: {
      title: 'Resources that could benefit from preconnect:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Potential Savings (ms)', key: 'wastedMs', type: 'milliseconds' },
      ],
    },
  },
  [MetricId.USES_RESPONSIVE_IMAGES]: {
    title: 'Uses Responsive Images',
    description:
      'Identifies images that are not appropriately sized for the device, leading to wasted bandwidth. Using responsive images improves load times by serving the right image size for each device.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-responsive-images/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Use Responsive Images',
      description:
        'Serve appropriately sized images for different devices by using responsive image techniques, reducing bandwidth usage and improving performance.',
      fixability: 'easy',
      additionalTips: [
        'Use the `srcset` attribute to define multiple image sizes for different screen resolutions.',
        'Consider using `sizes` with `srcset` to control image display based on viewport size.',
        'Optimize images for mobile devices to prevent large downloads on smaller screens.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<img src='small.jpg' srcset='large.jpg 1024w, medium.jpg 640w, small.jpg 320w' sizes='(max-width: 600px) 480px, 800px' alt='Responsive example image'>",
      },
    },
    tableConfig: {
      title: 'Images that could benefit from responsive sizes:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.USES_TEXT_COMPRESSION]: {
    title: 'Uses Text Compression',
    description:
      'Identifies text-based resources that are not compressed. Enabling text compression reduces file sizes and improves page load times.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/uses-text-compression/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Enable Text Compression',
      description:
        'Configure text compression (such as GZIP or Brotli) on your server to reduce the size of text-based resources, improving load performance.',
      fixability: 'easy',
      additionalTips: [
        'Enable GZIP or Brotli compression on your server for text-based resources like HTML, CSS, and JavaScript files.',
        'Verify that compressed resources are served with the correct `Content-Encoding` headers, such as `gzip` or `br`.',
        'Test page load times before and after enabling compression to measure performance improvements.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Encoding',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          '# Example: Enable GZIP compression in Apache\n<IfModule mod_deflate.c>\n  AddOutputFilterByType DEFLATE text/html text/plain text/xml text/css application/javascript\n</IfModule>',
      },
    },
    tableConfig: {
      title: 'Resources that could benefit from text compression:',
      columns: [
        { label: 'URL', key: 'url', type: 'link' },
        { label: 'Total Bytes', key: 'totalBytes', type: 'bytes' },
        { label: 'Potential Savings (bytes)', key: 'wastedBytes', type: 'bytes' },
      ],
    },
  },
  [MetricId.VALID_LANG]: {
    title: 'Valid lang Attribute',
    description:
      'Checks that the `lang` attribute on the `<html>` element is set to a valid language code. A valid `lang` attribute helps screen readers and other assistive technologies interpret the content accurately.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/html-has-lang/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Set a Valid lang Attribute',
      description:
        'Ensure the `lang` attribute on the `<html>` element is set to a valid language code (e.g., `en` for English, `es` for Spanish). This helps assistive technologies interpret content correctly.',
      fixability: 'easy',
      additionalTips: [
        'Use a valid language code based on the ISO 639-1 standard.',
        'For multilingual content, set the primary language of the document in the `lang` attribute on the `<html>` element.',
        'Test with assistive technologies to verify that content is recognized in the correct language.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/lang',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<html lang='en'>\n  <!-- Content -->\n</html>",
      },
    },
  },
  [MetricId.VALID_SOURCE_MAPS]: {
    title: 'Valid Source Maps',
    description:
      'Checks if JavaScript source maps are valid and accessible, which can help with debugging in production. Invalid source maps make it difficult to trace errors in minified code.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/source-maps/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure Source Maps Are Valid',
      description:
        'Validate JavaScript source maps to ensure they are correctly generated and accessible. Valid source maps improve debugging capabilities in production environments.',
      fixability: 'medium',
      additionalTips: [
        'Verify that source maps are generated without errors during the build process.',
        'Ensure source maps are accessible in production by checking server configuration.',
        'Check source maps for errors, such as missing or broken mappings, to improve debugging accuracy.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Tools/Debugger/How_to/Use_a_source_map',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'JavaScript files with invalid source maps:',
      columns: [
        { label: 'Script URL', key: 'scriptUrl', type: 'link' },
        { label: 'Source Map URL', key: 'sourceMapUrl', type: 'link' },
      ],
      subItemColumns: [{ label: 'Error', key: 'error', type: 'text', colSpan: 2 }],
    },
  },
  [MetricId.VIDEO_CAPTION]: {
    title: 'Video Captions',
    description:
      'Ensures that videos include captions to make content accessible to users with hearing impairments. Captions improve accessibility by providing text alternatives for audio content.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/video-caption/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Add Captions to Videos',
      description:
        'Include captions in video content to make it accessible for users with hearing impairments and to improve comprehension for all users.',
      fixability: 'easy',
      additionalTips: [
        "Use the `<track kind='captions'>` element within the `<video>` tag to add captions.",
        'Provide captions in the primary language of the video for clarity.',
        'Consider using automated tools for captioning but review for accuracy to ensure reliable accessibility.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/track',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic:
          "<video controls>\n  <source src='example.mp4' type='video/mp4'>\n  <track kind='captions' src='captions_en.vtt' srclang='en' label='English'>\n</video>",
      },
    },
    tableConfig: {
      title: 'Videos missing captions:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        { label: 'Label', key: 'node', subKey: 'nodeLabel', type: 'text' },
        { label: 'Explanation', key: 'node', subKey: 'explanation', type: 'text' },
      ],
    },
  },
  [MetricId.VIEWPORT]: {
    title: 'Viewport',
    description:
      'Checks if a viewport meta tag is set, which controls layout on mobile devices. Setting an appropriate viewport helps ensure a responsive design and improves usability on various screen sizes.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/viewport/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Set a Viewport Meta Tag',
      description:
        "Add a `<meta name='viewport'>` tag to control the layout on mobile devices. An appropriate viewport setting ensures a responsive design that scales correctly across different screen sizes.",
      fixability: 'easy',
      additionalTips: [
        "Use `<meta name='viewport' content='width=device-width, initial-scale=1'>` to set a responsive viewport.",
        'Avoid setting fixed widths, as this can cause content to be cut off on smaller screens.',
        'Test your page on various devices to ensure the layout adjusts correctly.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag',
          variant: 'neutral',
        },
      ],
      codeSnippets: {
        generic: "<meta name='viewport' content='width=device-width, initial-scale=1'>",
      },
    },
  },
  [MetricId.CTA_VISIBLE]: {
    title: 'CTA Visibility',
    description:
      'Checks if the primary call-to-action (CTA) is visible within the viewport on page load. Ensuring CTA visibility can improve user engagement and conversions.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/optimize-cls/#ensure-critical-elements-are-visible',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Ensure CTA is Visible in the Viewport',
      description:
        'Place the primary CTA within the initial viewport, ideally positioned at the top of the page, to ensure visibility without scrolling. Use design elements to make it stand out, as a visible CTA can increase engagement and conversions.',
      fixability: 'easy',
      additionalTips: ['Test the page on various screen sizes to confirm CTA visibility on load.'],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'CTAs visibility status:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'Is Visible in Viewport', key: 'isVisibleInViewport', type: 'boolean' },
      ],
    },
  },
  [MetricId.CTA_QUALITY]: {
    title: 'CTA Quality',
    description:
      'Evaluates the quality of call-to-action (CTA) elements based on clarity, urgency, and relevance. High-quality CTAs are clear, prompt action, and align with the user’s needs.',
    solution: {
      title: 'Enhance CTA Quality',
      description:
        'Optimize CTA quality by focusing on **clarity**, **urgency**, and **relevance**. CTAs with high scores in at least two of these areas are more likely to drive engagement and conversions. Use action-oriented language, create a sense of urgency, and ensure alignment with content.',
      fixability: 'medium',
      additionalTips: [
        "For clarity, use phrases like **'Get Started'** or **'Sign Up Free'** that clearly indicate the next step.",
        "To add urgency, consider words like **'Buy Now'**, **'Limited Time Offer'**, or **'Join Today'** to motivate immediate action.",
        'Ensure relevance by tailoring the CTA to match the page’s content, such as **‘Learn More about Our Services’** on an informational page or **‘Shop Our New Collection’** on a product page.',
      ],
    },
    tableConfig: {
      title: 'CTA quality analysis results:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'Clarity Score', key: 'clarityScore', type: 'unitless' },
        { label: 'Urgency Score', key: 'urgencyScore', type: 'unitless' },
        { label: 'Relevance Score', key: 'relevanceScore', type: 'unitless' },
        { label: 'Suggestions', key: 'suggestions', type: 'array' },
      ],
    },
  },
  [MetricId.CTA_LENGTH]: {
    title: 'CTA Text Length',
    description:
      'Evaluates the length of call-to-action (CTA) text. Short, concise CTA text improves readability and encourages user interaction.',
    solution: {
      title: 'Optimize CTA Text Length',
      description:
        'Limit CTA text to **2-4 words** for optimal clarity and engagement. CTAs with **fewer than 2 words** or **between 5 and 6 words** are acceptable but may reduce effectiveness slightly. Avoid CTAs with **more than 6 words** as they may be too lengthy and reduce impact.',
      fixability: 'easy',
    },
    tableConfig: {
      title: 'CTAs with text length details:',
      columns: [
        { label: 'Element', key: 'node', type: 'node' },
        {
          label: 'Label',
          key: 'node',
          subKey: 'nodeLabel',
          type: 'text',
        },
        { label: 'Word Count', key: 'wordCount', type: 'unitless' },
        { label: 'Character Count', key: 'charCount', type: 'unitless' },
      ],
    },
  },
  [MetricId.FORM_FIELD_COUNT]: {
    title: 'Form Field Count',
    description:
      'Evaluates the number of fields within forms. Streamlined forms improve user experience and increase completion rates by reducing friction.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/sign-up-form-best-practices/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Form Field Count',
      description:
        'Keep form fields to a minimum for better usability and higher completion rates. Aim for fewer than **6 fields** per form to maximize completion rates. Avoid forms with **more than 10 fields**, as they may discourage users and lead to higher abandonment.',
      fixability: 'medium',
      additionalTips: [
        'Combine related fields where possible, such as consolidating "First Name" and "Last Name" into a single "Full Name" field.',
        'Consider using optional fields only when necessary, clearly marking them to help streamline the form without losing essential information.',
        'Periodically test form completion rates and user feedback to ensure the field count is optimized without compromising data quality.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Forms with field count details:',
      columns: [
        { label: 'Form Element', key: 'node', type: 'node' },
        { label: 'Field Count', key: 'fields', subKey: 'length', type: 'unitless' },
      ],
    },
  },
  [MetricId.KEYWORD_OPTIMIZATION]: {
    title: 'Keyword Optimization',
    description:
      'Analyzes keyword usage and density across critical content areas (e.g., title, meta description, headings, body). Proper keyword placement and density can improve SEO and relevance.',
    solution: {
      title: 'Optimize Keyword Placement and Density',
      description:
        'Ensure the primary keyword is present in the title, meta description, H1, and body, with a density between **1.5% and 2.5%** to balance readability and SEO. The secondary keyword should appear in at least two elements, including title, meta description, H1, H2, or body, with the same ideal density range.',
      fixability: 'easy',
      links: [
        {
          label: 'SERP Preview Tool',
          url: 'https://www.sistrix.com/serp-snippet-generator/',
          variant: 'neutral',
        },
        {
          label: 'Analyze Competitor Keywords',
          url: 'https://www.semrush.com/',
          variant: 'neutral',
        },
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Keyword analysis results:',
      columns: [
        { label: 'Keyword', key: 'keyword', type: 'text' },
        { label: 'In Title', key: 'presence', subKey: 'title', type: 'boolean' },
        { label: 'In Meta Description', key: 'presence', subKey: 'metaDescription', type: 'boolean' },
        { label: 'In H1', key: 'presence', subKey: 'h1', type: 'boolean' },
        { label: 'In H2', key: 'presence', subKey: 'h2', type: 'boolean' },
        { label: 'In Body', key: 'presence', subKey: 'body', type: 'boolean' },
        { label: 'Density (%)', key: 'density', type: 'unitless' },
      ],
    },
  },
  [MetricId.SOCIAL_PROOF]: {
    title: 'Social Proof',
    description:
      'Analyzes the presence and sentiment of social proof elements, such as reviews, testimonials, star ratings, and endorsements. Positive social proof can build trust and influence user decisions, increasing conversion rates.',
    solution: {
      title: 'Enhance Social Proof',
      description:
        'Incorporate social proof elements like testimonials, reviews, star ratings, and endorsements to build trust and increase credibility. Ensure that social proof content is positive, authentic, and regularly updated, as this can directly impact conversions and user trust.',
      fixability: 'easy',
      additionalTips: [
        'Highlight social proof above the fold or near key conversion points to maximize visibility.',
        'Use high-quality images or logos from customers or partners to enhance authenticity and appeal.',
        'Showcase recent, positive reviews prominently to give users confidence in your product or service.',
      ],
      links: [
        {
          label: 'Guide to User-Generated Content',
          url: 'https://sproutsocial.com/insights/user-generated-content/',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Social proof analysis:',
      columns: [
        { label: 'Social Proof Detected', key: 'socialProofDetected', type: 'boolean' },
        { label: 'Overall Sentiment', key: 'summary', subKey: 'overallSentiment', type: 'text' },
        { label: 'Reviews', key: 'types', subKey: 'reviews', type: 'boolean' },
        { label: 'Testimonials', key: 'types', subKey: 'testimonials', type: 'boolean' },
        { label: 'Star Ratings', key: 'types', subKey: 'starRatings', type: 'boolean' },
        { label: 'Endorsements', key: 'types', subKey: 'endorsements', type: 'boolean' },
      ],
    },
  },
  [MetricId.LINE_SPACING]: {
    title: 'Average Line Spacing',
    description:
      'Evaluates the average line spacing across the page. Appropriate line spacing improves readability and user experience.',
    solution: {
      title: 'Optimize Line Spacing',
      description:
        'Set line spacing between **1.4 and 1.6** for body text to ensure readability and a comfortable reading experience. Avoid line spacing that is too tight or too loose, as this can strain the user’s eyes or make content appear disjointed.',
      fixability: 'easy',
      additionalTips: [
        'Use CSS properties like `line-height` to adjust spacing for readability across different screen sizes and devices.',
        'Test line spacing on mobile, tablet, and desktop devices to ensure a comfortable reading experience on all screen sizes.',
        'Refer to accessibility guidelines to ensure your line spacing supports users with visual impairments or reading difficulties.',
      ],
      links: [
        {
          label: 'Accessibility Guidelines for Line Spacing',
          url: 'https://www.w3.org/WAI/WCAG21/quickref/?showtechniques=1#visual-presentation',
          variant: 'neutral',
        },
      ],
    },
  },
  [MetricId.META_TITLE_LENGTH]: {
    title: 'Meta Title Length',
    description:
      'Analyzes the length of the meta title tag. Optimal title length improves search engine optimization (SEO) and ensures titles are fully displayed in search results.',
    solution: {
      title: 'Optimize Meta Title Length',
      description:
        'Ensure meta title length is within **50-60 characters** to prevent truncation in search results and improve SEO. Keep titles clear and concise to enhance visibility and ranking potential.',
      fixability: 'easy',
      additionalTips: [
        'Place important keywords at the beginning of the title to improve relevance and ranking potential.',
        'Use a SERP preview tool to visualize how your title will appear in search results.',
        'Test different title formats to find the most engaging structure for your audience.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/title',
          variant: 'neutral',
        },
        {
          label: 'SERP Preview Tool',
          url: 'https://www.sistrix.com/serp-snippet-generator/',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Meta title length details:',
      columns: [
        { label: 'Meta Title', key: 'metaTitle', type: 'text' },
        { label: 'Length', key: 'length', type: 'unitless' },
      ],
    },
  },
  [MetricId.META_DESCRIPTION_LENGTH]: {
    title: 'Meta Description Length',
    description:
      'Analyzes the length of the meta description tag. Optimal description length improves SEO and ensures descriptions are fully displayed in search results.',
    links: [
      {
        label: 'Learn more',
        url: 'https://web.dev/meta-description/',
        variant: 'neutral',
      },
    ],
    solution: {
      title: 'Optimize Meta Description Length',
      description:
        'Ensure meta description length is within **150-160 characters** to prevent truncation in search results. Clear and concise descriptions with natural keyword inclusion improve click-through rates and SEO.',
      fixability: 'easy',
      additionalTips: [
        'Use action-oriented language to make the description engaging and encourage clicks.',
        'Place primary keywords near the beginning of the description for better relevance and ranking.',
        'Use a SERP preview tool to check how the description will appear in search results.',
      ],
      links: [
        {
          label: 'Best practices',
          url: 'https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta',
          variant: 'neutral',
        },
        {
          label: 'SERP Preview Tool',
          url: 'https://www.sistrix.com/serp-snippet-generator/',
          variant: 'neutral',
        },
      ],
    },
    tableConfig: {
      title: 'Meta description length details:',
      columns: [
        { label: 'Meta Description', key: 'metaDescription', type: 'text' },
        { label: 'Length', key: 'length', type: 'unitless' },
      ],
    },
  },
}

export type Metric<T extends MetricId> = typeof Metrics[T]

// These are the maximum values based on the score scale, e.g. a score of 0.7 means the score is MEH 🍌 (higher than the SOUR threshold but lower than the MEH threshold)
export const SCORE_THRESHOLDS = {
  SOUR: 0,
  MEH: 0.5,
  SWEET: 0.8, // Anything equal or above this is considered a PASS
}
