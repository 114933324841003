export const getVsCompetitorsScoreDifference = ({
  score,
  avgCompetitorsScore,
}: {
  score: number | null
  avgCompetitorsScore: number | null | undefined
}) => {
  if (score === null || avgCompetitorsScore === null || avgCompetitorsScore === undefined) {
    return null
  }

  const scoreDifference = Math.round((score - avgCompetitorsScore) * 100)

  return scoreDifference
}
