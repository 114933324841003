import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentPropsWithoutRef<'h2'>

export const HeadingText = ({ className = '', children, ...props }: Props) => {
  return (
    <h2
      className={twMerge('font-display text-base uppercase tracking-wide text-base-content lg:text-lg', className)}
      {...props}
    >
      {children}
    </h2>
  )
}
