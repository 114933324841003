import { SCORE_THRESHOLDS } from 'types'

export const getReportOverallScoreDescription = (score: number | null) => {
  if (score === null) return ''

  if (score >= SCORE_THRESHOLDS.SWEET) return 'Your page is sweet 🍊'

  if (score >= SCORE_THRESHOLDS.MEH) return 'Your page is meh 🍌'

  return 'Your page is sour 🍋'
}
