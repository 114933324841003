import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { SortableTableHeaderCell, Table, TableBody, TableCell, TableHead, TableRow } from 'components'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { MetricId } from 'types'
import { MetricCategoryId } from 'types/src/metricCategories'

import { trackAnalyticsEvent } from '@/analytics/api/mixpanel'
import { useReport } from '@/reports/hooks/useReport'

import { PageSection } from '../../../components/pageSection/PageSection'
import { useReportCategoryMetrics } from '../hooks/useReportCategoryMetrics'
import { ReportCategoryMetricsSortBy, useReportCategoryMetricsSorting } from '../hooks/useReportCategoryMetricsSorting'
import { useReportNavigateToMetricDetails } from '../hooks/useReportNavigateToMetricDetails'
import { ReportMetricImpact } from './ReportMetricImpact'
import { ReportMetricRating } from './ReportMetricRating'
import { ReportMetricScore } from './ReportMetricScore'
import { ReportMetricSolutionFixability } from './ReportMetricSolutionFixability'
import { ReportMetricTitle } from './ReportMetricTitle'
import { ReportMetricVsCompetitors } from './ReportMetricVsCompetitors'
import { ReportUpgradeButton } from './ReportUpgradeButton'

type Props = {
  metricCategoryId: MetricCategoryId
}

const COLUMNS: Array<{
  title: string
  sortBy?: ReportCategoryMetricsSortBy
}> = [
  { title: 'Metric', sortBy: 'metricTitle' },
  { title: 'Rating', sortBy: 'metricScoreRating' },
  { title: 'Score', sortBy: 'metricScore' },
  { title: 'Impact', sortBy: 'metricImpact' },
  { title: 'Fixability', sortBy: 'solutionFixability' },
  { title: 'Vs Competitors', sortBy: 'metricVsCompetitorsScore' },
  { title: '' },
]

export const ReportCategoryMetrics = ({ metricCategoryId }: Props) => {
  const tableRef = useRef<HTMLDivElement>(null)

  const [maxTableHeight, setMaxTableHeight] = useState<number | null>(null)

  const [sorting, setSorting] = useReportCategoryMetricsSorting(metricCategoryId)

  const { metrics, isLoading } = useReportCategoryMetrics(metricCategoryId)

  const { data } = useReport()

  const report = data?.data.report
  const reportId = report?.id || ''
  const isPaid = Boolean(report?.isPaid)

  const defaultAnalyticsData = useMemo(() => {
    return {
      report_id: reportId,
      metric_category_id: metricCategoryId,
      metric_id: null,
      is_paid: isPaid,
    }
  }, [reportId, metricCategoryId, isPaid])

  const navigateToMetricDetails = useReportNavigateToMetricDetails()

  // Set the max height of the table to the available space on the screen so that we get a sticky table header
  useEffect(() => {
    if (isLoading) {
      return
    }

    if (tableRef.current) {
      const boundingClientRect = tableRef.current.getBoundingClientRect()
      const isTablePartiallyVisible = boundingClientRect.bottom > window.innerHeight

      if (isTablePartiallyVisible) {
        const maxTableHeight = window.innerHeight - boundingClientRect.top

        setMaxTableHeight(maxTableHeight)
      }
    }
  }, [isLoading])

  const onTableHeaderClick = useCallback(
    (sortBy: ReportCategoryMetricsSortBy) => {
      if (sortBy === sorting.sortBy) {
        const newSortDirection = sorting.sortDirection === 'asc' ? 'desc' : 'asc'

        setSorting(sorting.sortBy, newSortDirection)

        trackAnalyticsEvent('metrics_table_sorted', {
          ...defaultAnalyticsData,
          sort_by: sortBy,
          sort_direction: newSortDirection,
        })
      } else {
        setSorting(sortBy, sorting.sortDirection)

        trackAnalyticsEvent('metrics_table_sorted', {
          ...defaultAnalyticsData,
          sort_by: sortBy,
          sort_direction: sorting.sortDirection,
        })
      }
    },
    [sorting.sortBy, sorting.sortDirection, setSorting, defaultAnalyticsData],
  )

  const onTableRowClick = useCallback(
    (metricId: MetricId) => {
      navigateToMetricDetails(metricId)
    },
    [navigateToMetricDetails],
  )

  const onTableRowKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, metricId: MetricId) => {
      if (event.key === 'Enter') {
        onTableRowClick(metricId)
      }
    },
    [onTableRowClick],
  )

  return (
    <PageSection className="-mt-12">
      <Table
        forwardRef={tableRef}
        containerClassName="h-full"
        containerStyle={{
          maxHeight: maxTableHeight ? `${maxTableHeight}px` : undefined,
        }}
      >
        <TableHead>
          <TableRow>
            {COLUMNS.map(column => {
              return (
                <SortableTableHeaderCell
                  key={column.title}
                  sortable={Boolean(column.sortBy)}
                  sorted={sorting.sortBy === column.sortBy}
                  sortDirection={sorting.sortDirection}
                  onClick={
                    column.sortBy ? () => onTableHeaderClick(column.sortBy as ReportCategoryMetricsSortBy) : undefined
                  }
                >
                  {column.title}
                </SortableTableHeaderCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading
            ? Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  {COLUMNS.map(column => (
                    <TableCell key={column.title}>
                      <div className="skeleton h-7 w-full" />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : metrics.map(reportMetric => {
                const isObfuscated = Boolean(reportMetric.isObfuscated)

                return (
                  <TableRow
                    key={reportMetric.id}
                    className="relative"
                    // Make it focusable when not obfuscated
                    tabIndex={reportMetric.isObfuscated ? -1 : 0}
                    hoverable={!reportMetric.isObfuscated}
                    onClick={() => !reportMetric.isObfuscated && onTableRowClick(reportMetric.id)}
                    onKeyDown={event => !reportMetric.isObfuscated && onTableRowKeyDown(event, reportMetric.id)}
                  >
                    <TableCell>
                      <ReportMetricTitle metricId={reportMetric.id} />
                    </TableCell>

                    <TableCell>
                      <ReportMetricRating score={reportMetric.score} isObfuscated={isObfuscated} />
                    </TableCell>

                    <TableCell>
                      <ReportMetricScore score={reportMetric.score} isObfuscated={isObfuscated} />
                    </TableCell>

                    <TableCell>
                      <ReportMetricImpact metricId={reportMetric.id} isObfuscated={isObfuscated} />
                    </TableCell>

                    <TableCell>
                      <ReportMetricSolutionFixability metricId={reportMetric.id} isObfuscated={isObfuscated} />
                    </TableCell>

                    <TableCell>
                      <ReportMetricVsCompetitors
                        score={reportMetric.score}
                        avgCompetitorsScore={reportMetric.avgCompetitorsScore}
                        isObfuscated={isObfuscated}
                      />
                    </TableCell>

                    {!isObfuscated && (
                      <TableCell>
                        <ArrowRightIcon className="h-4 w-4" />
                      </TableCell>
                    )}

                    {isObfuscated && (
                      <TableCell className="p-0">
                        <ReportUpgradeButton
                          analytics={{
                            name: 'category_metrics_upgrade',
                            reportId: reportId || null,
                            metricCategoryId: metricCategoryId || null,
                            metricId: null,
                            isPaid: isPaid || null,
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
        </TableBody>
      </Table>
    </PageSection>
  )
}
