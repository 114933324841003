import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { MetricId } from 'types'

import { identifyUser } from '@/analytics/api/mixpanel'
import { useReport } from '@/reports/hooks/useReport'
import { REPORT_ID_PARAM, routes, SUCCESS_QUERY_PARAM } from '@/router/routes'

import { ReportHeader } from './components/ReportHeader'
import { ReportNavbar } from './components/ReportNavbar'
import { ReportMetricDetailsDialog } from './dialogs/reportMetricDetailsDialog/ReportMetricDetailsDialog'
import { ReportNotFoundDialog } from './dialogs/reportNotFoundDialog/ReportNotFoundDialog'
import { ReportPaymentSuccessDialog } from './dialogs/reportPaymentSuccessDialog/ReportPaymentSuccessDialog'
import { useReportMetricIdSearchParam } from './hooks/useReportMetricIdSearchParam'

const useHandlePaymentSuccess = () => {
  const { refetch, isFetched } = useReport()
  const [searchParams, setSearchParams] = useSearchParams()
  const isSuccess = searchParams.get(SUCCESS_QUERY_PARAM)
  const [showPaymentSuccessDialog, setShowPaymentSuccessDialog] = useState(false)

  useEffect(() => {
    if (isFetched && isSuccess) {
      refetch()
      setShowPaymentSuccessDialog(true)

      // Clear the success query param
      searchParams.delete(SUCCESS_QUERY_PARAM)
      setSearchParams(searchParams)
    }
  }, [isSuccess, isFetched, refetch, searchParams, setSearchParams])

  return { showPaymentSuccessDialog, setShowPaymentSuccessDialog }
}

const hasNoReportTab = ({ pathname, reportId }: { pathname: string; reportId: string }) => {
  return !pathname.split(reportId)[1]
}

export const ReportLayout = () => {
  const { showPaymentSuccessDialog, setShowPaymentSuccessDialog } = useHandlePaymentSuccess()
  const metricId = useReportMetricIdSearchParam()

  const { data, isLoading } = useReport()
  const reportNotFound = !data && !isLoading

  const { reportId = '' } = useParams()
  const { pathname } = useLocation()

  // When the reportId changes, identify the user in mixpanel using the data.report.url
  useEffect(() => {
    if (reportId && data?.data?.report.url) {
      identifyUser(data.data.report.url)
    }
  }, [reportId, data?.data?.report?.url])

  if (reportId && hasNoReportTab({ pathname, reportId })) {
    return <Navigate to={routes.reportOverview.replace(REPORT_ID_PARAM, reportId)} />
  }

  return (
    <>
      <ReportHeader />

      <ReportNavbar />

      <Outlet />

      {metricId && <ReportMetricDetailsDialog open metricId={metricId as MetricId} />}

      {reportNotFound && <ReportNotFoundDialog open />}

      {showPaymentSuccessDialog && (
        <ReportPaymentSuccessDialog open onClose={() => setShowPaymentSuccessDialog(false)} />
      )}
    </>
  )
}
