import React, { ComponentProps, ForwardedRef } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'th'> & {
  forwardRef?: ForwardedRef<HTMLTableCellElement>
}

export const TableHeaderCell = ({ forwardRef, className = '', children, ...props }: Props) => {
  return (
    <th ref={forwardRef} className={twMerge('bg-base-200 text-sm font-medium text-base-content', className)} {...props}>
      {children}
    </th>
  )
}
