import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'
import { formatPercentage, getReportScoreClassNames } from 'utils'

import { OBFUSCATED_CLASS_NAMES } from '../dialogs/reportMetricDetailsDialog/constants'

const formatScore = (score?: number | null) => {
  if (score === null || score === undefined) {
    return 'N/A'
  }

  return formatPercentage(score)
}

type Props = ComponentProps<'div'> & {
  score?: number | null
  isObfuscated: boolean
}

export const ReportMetricScore = ({ className = '', score, isObfuscated = false, ...props }: Props) => {
  return (
    <div
      className={twMerge(
        'font-medium',
        getReportScoreClassNames(score),
        isObfuscated && OBFUSCATED_CLASS_NAMES,
        className,
      )}
      {...props}
    >
      {formatScore(score)}
    </div>
  )
}
