import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { MetricCategories, MetricCategoryId, Metrics } from 'types'

import { Link } from '@/components/link/Link'
import { ReportCategorySummary } from '@/pages/report/components/ReportCategorySummary'
import { useReport } from '@/reports/hooks/useReport'
import { METRIC_ID_QUERY_PARAM } from '@/router/routes'

import { ReportCategoryKeyHighlights } from '../../../components/ReportCategoryKeyHighlights'
import { ReportCategorySummaryCta } from '../../../components/ReportCategorySummaryCta'
import { ReportCategorySummaryTestimonials } from '../../../components/ReportCategorySummaryTestimonials'
import { ReportJuiceMeter } from '../../../components/ReportJuiceMeter'

type Props = {
  metricCategoryId: MetricCategoryId
}

export const ReportCategorySummaryTab = ({ metricCategoryId }: Props) => {
  const { pathname } = useLocation()

  const { data, isLoading } = useReport()

  const report = data?.data.report
  const isPaid = Boolean(report?.isPaid)

  const compositeReportMetrics = useMemo(
    () =>
      MetricCategories[metricCategoryId].metrics
        .filter(categoryMetric => {
          const metric = Metrics[categoryMetric.id]

          if (!metric) {
            return false
          }

          // NOTE: These shouldn't ever be hidden
          if (metric.hidden) {
            return false
          }

          return metric.isCompositeMetric
        })
        .map(categoryMetric => report?.metrics[categoryMetric.id])
        // NOTE: These shouldn't ever be obfuscated
        .filter(reportMetric => reportMetric && reportMetric.score !== null),
    [metricCategoryId, report?.metrics],
  )

  return (
    <>
      <ReportCategorySummary metricCategoryId={metricCategoryId}>
        {/* Show Juice Meter and Score for each of the composite metrics */}
        {compositeReportMetrics.length ? (
          <div className="my-8 flex flex-wrap items-center justify-center gap-8">
            {compositeReportMetrics.map(reportMetric => {
              if (!reportMetric) {
                return null
              }

              return (
                <div key={reportMetric.id} className="flex flex-col items-center gap-y-2">
                  <ReportJuiceMeter
                    score={reportMetric.score as number}
                    size="sm"
                    animate={false}
                    isLoading={isLoading}
                  />

                  <Link to={`${pathname}?${METRIC_ID_QUERY_PARAM}=${reportMetric.id}`}>
                    {Metrics[reportMetric.id].title}

                    <ArrowRightIcon className="h-4 w-4" />
                  </Link>
                </div>
              )
            })}
          </div>
        ) : null}
      </ReportCategorySummary>

      <ReportCategoryKeyHighlights metricCategoryId={metricCategoryId} />

      {!isPaid && (
        <>
          <ReportCategorySummaryTestimonials metricCategoryId={metricCategoryId} />

          <ReportCategorySummaryCta metricCategoryId={metricCategoryId} isLoading={isLoading} />
        </>
      )}
    </>
  )
}
