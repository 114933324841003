import { HugeText, TitleText } from 'components'
import React, { useEffect, useRef } from 'react'
import { useCountUp } from 'react-countup'
import { twMerge } from 'tailwind-merge'
import { getReportScoreClassNames } from 'utils'

import { JUICE_METER_ANIMATION_DELAY, JUICE_METER_ANIMATION_DURATION } from '../constants'

type Props = {
  score: number | null // if null, it's not applicable
  animate: boolean
  isLoading: boolean
  size?: 'sm' | 'md'
  isObfuscated?: boolean
}

export const ReportScore = ({ score, animate, isLoading, size = 'md', isObfuscated }: Props) => {
  const countUpRef = useRef<HTMLHeadingElement>(null)

  const scorePercentage = score ? Math.floor(score * 100) : 0

  const { reset, start } = useCountUp({
    ref: countUpRef,
    start: animate ? 0 : scorePercentage,
    end: scorePercentage,
    delay: animate ? JUICE_METER_ANIMATION_DELAY / 1000 : 0,
    duration: animate ? JUICE_METER_ANIMATION_DURATION / 1000 : 0,
    suffix: '%',
    enableReinitialize: true,
  })

  // Reset the countup when the score is loaded or changed
  useEffect(() => {
    if (!isLoading && score !== null) {
      reset()
      start()
    }
  }, [isLoading, score, reset, start])

  const obfuscatedClassNames = isObfuscated ? 'blur select-none' : ''
  const classNames = twMerge(obfuscatedClassNames, getReportScoreClassNames(score))
  const scoreText = score === null && 'N/A'

  if (size === 'sm') {
    if (isLoading) {
      return <div className="skeleton h-7 w-16" />
    }

    return (
      <TitleText className={classNames} forwardRef={score !== null ? countUpRef : undefined}>
        {scoreText}
      </TitleText>
    )
  }

  if (isLoading) {
    return <div className="skeleton h-12 w-24" />
  }

  return (
    <HugeText className={classNames} forwardRef={score !== null ? countUpRef : undefined}>
      {scoreText}
    </HugeText>
  )
}
