import { TitleText } from 'components'
import React, { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'div'> & {
  variant?: 'default' | 'primary' | 'neutral'
  title?: string
  contentClassName?: string
  isLoading?: boolean
}

export const PageSection = ({
  className = '',
  contentClassName = '',
  variant = 'default',
  title,
  children,
  isLoading,
  ...props
}: Props) => {
  const containerClasses = twMerge(
    'relative w-full px-4',
    variant === 'primary' && 'bg-gradient-to-r from-primary to-secondary py-16',
    variant === 'neutral' && 'bg-gradient-to-r from-base-200 to-base-300 py-16',
    className,
  )

  const contentClasses = twMerge(
    'flex flex-col items-center gap-y-8 rounded-xl bg-base-100 max-w-5xl mx-auto',
    variant === 'primary' && 'px-4 py-8 sm:px-8 border border-primary',
    variant === 'neutral' && 'px-4 py-8 sm:px-8 border border-base-300',
    contentClassName,
  )

  return (
    <div className={containerClasses} {...props}>
      <div className={contentClasses}>
        {isLoading ? (
          <div className="skeleton h-10 w-96" />
        ) : (
          title && <TitleText className="max-w-lg text-center">{title}</TitleText>
        )}

        {children}
      </div>
    </div>
  )
}
