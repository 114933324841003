import { z } from 'zod'

export const TestimonialDocumentSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  name: z.string(),
  url: z.string(),
  quote: z.string(),
  image: z.string(),
  approved: z.boolean(),
})

export type TestimonialDocument = z.infer<typeof TestimonialDocumentSchema>
