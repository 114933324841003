import { useMutation } from '@tanstack/react-query'

import { useReport } from '@/reports/hooks/useReport'

import { createCheckoutSession } from '../api/createCheckoutSession'

export const useCreateCheckoutSession = () => {
  const { data } = useReport()

  const url = data?.data.report?.url || ''
  const reportId = data?.data.report?.id || ''
  const priceId = data?.data.priceId || ''

  return useMutation({
    mutationFn: () => createCheckoutSession({ url, reportId, priceId }),
  })
}
