import { Button, Dialog, DialogActions, DialogHeader } from 'components'
import { config } from 'config'
import React, { ComponentProps, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { useLink } from 'utils'

import { routes } from '@/router/routes'

type Props = ComponentProps<typeof Dialog>

export const ReportNotFoundDialog = ({ className = '', onClose, ...props }: Props) => {
  const { reportId = '' } = useParams()

  const navigate = useNavigate()
  const link = useLink()

  const onContactSupportClick = useCallback(() => {
    link(`mailto:${config.emails.support}?subject=Report%20not%20found%20${reportId}`, '_blank')
  }, [link, reportId])

  const onGoHomeClick = useCallback(() => {
    navigate(routes.dashboard)
  }, [navigate])

  return (
    <Dialog className={twMerge('', className)} onClose={onClose} {...props}>
      <DialogHeader
        onClose={onClose}
        title="Report Not Found"
        description="Sorry gov'na! We were unable to find your report."
      />

      <DialogActions>
        <Button variant="neutral" onClick={onContactSupportClick}>
          Contact support
        </Button>

        <Button onClick={onGoHomeClick}>Go home</Button>
      </DialogActions>
    </Dialog>
  )
}
