import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import React, { ComponentProps, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { Button } from '../button/Button'

type AlertVariant = 'default' | 'info' | 'success' | 'warning' | 'error'

const mapVariantToAlertClassName: Record<AlertVariant, string> = {
  default: '',
  info: 'alert-info bg-base-200 text-info',
  success: 'alert-success bg-base-200 text-success',
  warning: 'alert-warning bg-base-200 text-warning',
  error: 'alert-error bg-base-200 text-error',
}

const mapVariantToIcon: Record<AlertVariant, typeof InformationCircleIcon> = {
  default: InformationCircleIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
  error: ExclamationCircleIcon,
}

type Props = {
  className?: string
  variant?: AlertVariant
  children?: ReactNode
  onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void
} & ComponentProps<'div'>

export const Alert = ({ className, variant = 'default', children, onClose, ...props }: Props) => {
  const Icon = mapVariantToIcon[variant]

  return (
    <div
      className={twMerge(
        'alert inline-flex max-w-lg gap-x-4 gap-y-2 px-4 py-2 text-left',
        mapVariantToAlertClassName[variant],
        className,
      )}
      role="alert"
      {...props}
    >
      <Icon className={twMerge(`h-6 w-6 shrink-0 stroke-inherit`)} />

      {children}

      {onClose && (
        <Button variant="ghost" size="xs" className="z-50 -mr-2" onClick={onClose}>
          <XMarkIcon className="h-5 w-5" />
        </Button>
      )}
    </div>
  )
}
