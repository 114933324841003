import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'
import { MetricCategoryId } from 'types'

import { ErrorBoundary } from '@/components/errorBoundary/ErrorBoundary'
import { MainLayout } from '@/components/mainLayout/MainLayout'
import { Dashboard } from '@/pages/dashboard/Dashboard'
import { Privacy } from '@/pages/privacy/Privacy'
import { ReportLayout } from '@/pages/report/ReportLayout'
import { ReportCategoryMetricsTab } from '@/pages/report/tabs/category/metrics/ReportCategoryMetricsTab'
import { ReportCategoryTab } from '@/pages/report/tabs/category/ReportCategoryTab'
import { ReportCategorySummaryTab } from '@/pages/report/tabs/category/summary/ReportCategorySummaryTab'
import { ReportDiagnosticsTab } from '@/pages/report/tabs/diagnostics/ReportDiagnosticsTab'
import { ReportOverviewTab } from '@/pages/report/tabs/overview/ReportOverviewTab'
import { Terms } from '@/pages/terms/Terms'

import { routes } from './routes'

const errorElement = <ErrorBoundary />

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<MainLayout />} errorElement={errorElement}>
        <Route path={routes.dashboard} element={<Dashboard />} errorElement={errorElement} />

        <Route path={routes.report} element={<ReportLayout />} errorElement={errorElement}>
          <Route path={routes.reportOverview} element={<ReportOverviewTab />} errorElement={errorElement} />

          <Route
            path={routes.reportPerformance}
            element={<ReportCategoryTab metricCategoryId={MetricCategoryId.PERFORMANCE} />}
            errorElement={errorElement}
          >
            <Route
              path={routes.reportPerformanceSummary}
              element={<ReportCategorySummaryTab metricCategoryId={MetricCategoryId.PERFORMANCE} />}
              errorElement={errorElement}
            />

            <Route
              path={routes.reportPerformanceMetrics}
              element={<ReportCategoryMetricsTab metricCategoryId={MetricCategoryId.PERFORMANCE} />}
              errorElement={errorElement}
            />
          </Route>

          <Route
            path={routes.reportConversion}
            element={<ReportCategoryTab metricCategoryId={MetricCategoryId.CONVERSION} />}
            errorElement={errorElement}
          >
            <Route
              path={routes.reportConversionSummary}
              element={<ReportCategorySummaryTab metricCategoryId={MetricCategoryId.CONVERSION} />}
              errorElement={errorElement}
            />

            <Route
              path={routes.reportConversionMetrics}
              element={<ReportCategoryMetricsTab metricCategoryId={MetricCategoryId.CONVERSION} />}
              errorElement={errorElement}
            />
          </Route>

          <Route
            path={routes.reportSeo}
            element={<ReportCategoryTab metricCategoryId={MetricCategoryId.SEO} />}
            errorElement={errorElement}
          >
            <Route
              path={routes.reportSeoSummary}
              element={<ReportCategorySummaryTab metricCategoryId={MetricCategoryId.SEO} />}
              errorElement={errorElement}
            />

            <Route
              path={routes.reportSeoMetrics}
              element={<ReportCategoryMetricsTab metricCategoryId={MetricCategoryId.SEO} />}
              errorElement={errorElement}
            />
          </Route>

          <Route
            path={routes.reportAccessibility}
            element={<ReportCategoryTab metricCategoryId={MetricCategoryId.ACCESSIBILITY} />}
            errorElement={errorElement}
          >
            <Route
              path={routes.reportAccessibilitySummary}
              element={<ReportCategorySummaryTab metricCategoryId={MetricCategoryId.ACCESSIBILITY} />}
              errorElement={errorElement}
            />

            <Route
              path={routes.reportAccessibilityMetrics}
              element={<ReportCategoryMetricsTab metricCategoryId={MetricCategoryId.ACCESSIBILITY} />}
              errorElement={errorElement}
            />
          </Route>

          <Route
            path={routes.reportBestPractices}
            element={<ReportCategoryTab metricCategoryId={MetricCategoryId.BEST_PRACTICES} />}
            errorElement={errorElement}
          >
            <Route
              path={routes.reportBestPracticesSummary}
              element={<ReportCategorySummaryTab metricCategoryId={MetricCategoryId.BEST_PRACTICES} />}
              errorElement={errorElement}
            />

            <Route
              path={routes.reportBestPracticesMetrics}
              element={<ReportCategoryMetricsTab metricCategoryId={MetricCategoryId.BEST_PRACTICES} />}
              errorElement={errorElement}
            />
          </Route>

          <Route path={routes.reportDiagnostics} element={<ReportDiagnosticsTab />} errorElement={errorElement} />
        </Route>

        <Route path={routes.terms} element={<Terms />} errorElement={errorElement} />

        <Route path={routes.privacy} element={<Privacy />} errorElement={errorElement} />

        <Route path="*" element={<Navigate to={routes.dashboard} />} />
      </Route>
    </>,
  ),
)

export const Router = () => {
  return <RouterProvider router={router} />
}
